import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";

const SelectCategory = ({ options, selectedOptions, setSelectedOptions }) => {
    const [newOption, setNewOption] = useState("");
    // const [loading, setLoading] = useState(false);

    // const handleCreateOption = async (inputValue) => {
    //     try {
    //         setLoading(true);
    //         const { data } = await createCategories({
    //             name: [inputValue.toLowerCase()],
    //         });
    //         console.log(data.category);

    //         const createdOption = {
    //             label: data.category[0].name,
    //             value: data.category[0].id,
    //             name: data.category[0].name,
    //         };

    //         ShowSuccessMessage("Category Created");
    //         setSelectedOptions([...selectedOptions, createdOption]);
    //         setLoading(false);
    //     } catch (error) {
    //         console.error("Error creating Category:", error);
    //         console.log(error);
    //         setLoading(false);
    //         if (error.response.data) {
    //             ShowErrorMessage(error.response.data.message);
    //         }
    //         ShowErrorMessage("Somthing Went Wrong");
    //     }
    // };

    const handleChange = (newValue, actionMeta) => {
        setSelectedOptions(newValue);
    };

    return (
        <div>
            <CreatableSelect
                isMulti
                value={selectedOptions}
                onChange={handleChange}
                // onCreateOption={handleCreateOption}
                options={options}
                inputValue={newOption}
                // isLoading={loading}
                // isDisabled={loading}
                onInputChange={(inputValue) => setNewOption(inputValue)}
                formatCreateLabel={(inputValue) =>
                    `Create Category: ${inputValue}`
                }
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? "grey" : "red",
                        backgroundColor: "#F5F5F5",
                        padding: 12,
                        border: 0,
                        borderRadius: 8,
                    }),
                    valueContainer: (baseStyles) => ({
                        ...baseStyles,
                        gap: 6,
                    }),
                    option: (baseStyles) => ({
                        ...baseStyles,
                        backgroundColor: "#FFECE5",

                        ":hover": {
                            backgroundColor: "#FFECE588",
                            cursor: "pointer",
                        },
                    }),
                    multiValue: (baseStyles) => ({
                        ...baseStyles,
                        backgroundColor: "#FFF",
                        paddingTop: 8,
                        paddingBottom: 8,
                        paddingRight: 8,
                        paddingLeft: 8,
                        borderRadius: 8,
                        display: "flex",
                        gap: 0,
                    }),
                    multiValueLabel: (styles) => ({
                        ...styles,
                        color: "rgba(7, 7, 7, 0.6)",
                        fontSize: 12,
                        fontWeight: 500,
                    }),
                    multiValueRemove: (styles) => ({
                        ...styles,
                        color: "#000",
                        // width: 16,
                        // height: 16,
                    }),
                }}
            />
        </div>
    );
};

export default SelectCategory;
