import React, { useEffect, useState } from "react";
import KitchenLayout from "../../../components/Layout/kitchenLayout";
import tableData, { orderData } from "../../../components/data";
import Head from "../../../components/header/head";
import { Monitor, ModalTable, DetailsTab } from "../Table-monitor";
import Modal from "../../../components/Organisms/Modal";
import {
    FulfulledIcon,
    SalesIcon,
    OrderIcon,
    HideIcon,
    ShowIcon,
} from "../../../icons";
import { Link } from "react-router-dom";
import { ShowErrorMessage, formatCurrency } from "../../../utils";
import {
    getOrderbyid,
    getOrders,
    updateOrderstatus,
} from "../../../service/request";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { selectUser } from "../../../redux-store/slices/user.slice";
function Dashboard() {
    const user = useSelector(selectUser);
    const data = tableData;
    const style = "list";
    const [showAll, setShowAll] = useState(true);
    const [showSales, setShowSales] = useState(true);
    const [showOrders, setShowOrders] = useState(true);
    const [showFulfilled, setShowFulfilled] = useState(true);
    const [show, setShow] = useState(false);
    const [order, setOrder] = useState();
    const [orderItem, setOrderItem] = useState({});

    function transformString(str) {
        if (str.length <= 5) {
            return `fsw${str}`;
        }
        const newStr = str.slice(5);
        return `fsw${newStr}`;
    }

    useEffect(
        () =>
            async function getOrder() {
                try {
                    const { data } = await getOrders();
                    setOrder(data);
                } catch (error) {
                    console.log(error);
                    if (error?.response?.data?.message) {
                        ShowErrorMessage(error?.response?.data?.message);
                    } else {
                        ShowErrorMessage(
                            "Error Occured Check Internet Connection"
                        );
                    }
                }
            },
        []
    );
    async function getOrderid(id) {
        try {
            const { data } = await getOrderbyid(id);
            setOrderItem(data.order);
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.message) {
                ShowErrorMessage(error?.response?.data?.message);
            } else {
                ShowErrorMessage("Error Occured Check Internet Connection");
            }
        }
    }

    return (
        <div className=" w-full">
            <Head
                pageTitle={"Foodswiipe Kitchen | Dashboard"}
                metaDescription={"Foodswiipe Kitchen"}
            />

            <KitchenLayout pageIntro={"Welcome"} name={user.firstName}>
                <Modal title={"Order Details"} show={show} setShow={setShow}>
                    <div className=" w-full flex flex-col mt-6 gap-8">
                        <div className="w-full flex flex-col gap-4">
                            <div className="w-full flex flex-col gap-4 p-4 rounded-2xl border border-solid border-[rgba(0, 0, 0, 0.10)] ">
                                <div className=" w-full flex justify-between items-center gap-4">
                                    {orderItem?.orderitems?.map((order) => {
                                        return (
                                            <DetailsTab
                                                title={"Table Number"}
                                                info={order.table.tableNumber}
                                            />
                                        );
                                    })}
                                    <DetailsTab
                                        title={"Payment Method"}
                                        info={"Card Payment"}
                                    />
                                    {orderItem?.orderitems?.map((order) => {
                                        return (
                                            <DetailsTab
                                                title={"Order Amount"}
                                                info={formatCurrency(
                                                    orderItem.total
                                                )}
                                            />
                                        );
                                    })}
                                </div>
                                <div className=" w-full flex justify-between items-center gap-4">
                                    {orderItem?.orderitems?.map((order) => {
                                        return (
                                            <DetailsTab
                                                title={"Time of Order"}
                                                info={dayjs(
                                                    order.createdAt
                                                ).format(
                                                    "DD MMM, YYYY HH:mm A"
                                                )}
                                            />
                                        );
                                    })}
                                    {orderItem?.orderitems?.map((order) => {
                                        return (
                                            <DetailsTab
                                                title={"Payment Status"}
                                                info={orderItem.status}
                                            />
                                        );
                                    })}
                                    {orderItem?.orderitems?.map((order) => {
                                        return (
                                            <DetailsTab
                                                title={"Order Reference"}
                                                info={`FSW-${order.id
                                                    .slice(0, 7)
                                                    .toUpperCase()}`}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="w-full p-4 flex justify-between items-center bg-[#FFF7F5] rounded-2xl border border-solid border-[#FFCDBC]">
                                <div className="flex items-center justify-center bg-[#FFF] gap-2 p-2 rounded-3xl border border-solid border-[rgba(0, 0, 0, 0.10)]">
                                    <div
                                        className={
                                            orderItem === "pending"
                                                ? "w-6 h-6 bg-[#D2DBF9]  rounded-full  text-[#204BE1]"
                                                : orderItem === "in-progress"
                                                ? "w-6 h-6 bg-[#FFF4E1]  rounded-full  text-[#E69602]"
                                                : orderItem === "fulfilled"
                                                ? "w-6 h-6 bg-[#D2F9D6] rounded-full text-[#20E120]"
                                                : orderItem === "cancelled"
                                                ? "w-6 h-6 bg-[#F9D2D2] rounded-full text-[#E12020]"
                                                : ""
                                        }
                                    ></div>
                                    <p className="font-light">
                                        {orderItem.status}
                                    </p>
                                </div>
                                <input
                                    type="text"
                                    placeholder="How log will this take?"
                                    className="text-[#cccccc] bg-[#f5f5f5] px-4 py-2 border border-solid border-[rgba(0, 0, 0, 0.10)] rounded-2xl font-light"
                                />
                                <button className="bg-[#F2650D] rounded-xl text-white font-medium flex items-center justify-center px-4 py-2">
                                    Update
                                </button>
                            </div>
                            <div className=" w-full flex flex-col gap-2 p-4 rounded-2xl bg-[#FFCDBC] ">
                                <p className="text-[#F2650D] font-medium">
                                    Customer Note
                                </p>
                                <p className="text-[#333333] font-light text-sm">
                                    Lorem ipsum dolor sit amet consectetur.
                                    Nulla ac nec vitae tellus tortor vestibulum
                                    morbi. Varius blandit in aliquam id nam nisl
                                    vestibulum lorem. Magna sit.
                                </p>
                            </div>
                        </div>
                        <div className=" bg-[#ffffff] w-full font-medium border-b border-solid border-[rgba(0, 0, 0, 0.10)] text-sm h-fit py-3 flex items-center">
                            <p className=" w-[15%]">Qty</p>
                            <p className=" w-[60%]">Description</p>
                            <p className=" w-[10%]">Price</p>
                            <p className="w-[15%] self-end">Amount</p>
                        </div>
                        {orderItem?.orderitems?.map((order) => {
                            return (
                                <ModalTable
                                    qty={order.quantity}
                                    desc={order.food.name}
                                    price={order.price}
                                    amount={order.quantity * order.price}
                                />
                            );
                        })}
                        <div className=" bg-[#ffffff] w-full font-medium text-sm h-fit py-3 flex justify-between items-center">
                            <p className=" w-[15%]">Total</p>
                            <p className="w-[15%] self-end">
                                {formatCurrency(orderItem.total)}
                            </p>
                        </div>
                    </div>
                </Modal>
                <div className=" flex flex-col gap-6 bg-[#f7f7f7] w-full">
                    <div className=" flex justify-between items-center w-full">
                        <p className="text-2xl font-medium">Sales</p>
                        <div onClick={() => setShowAll(!showAll)}>
                            {showAll ? (
                                <div className="flex gap-3">
                                    <p className="text-base">Hide</p>
                                    <HideIcon />
                                </div>
                            ) : (
                                <div className="flex gap-3">
                                    <p className="text-base">Show</p>
                                    <ShowIcon />
                                </div>
                            )}
                        </div>
                    </div>
                    {showAll ? (
                        <div className="flex mt-4 items-center gap-4 w-full text-slate-300">
                            <DashboardCard
                                title={"Total Sales"}
                                amount={"N500,000,000.00"}
                                show={showSales}
                                click={() => setShowSales(!showSales)}
                            >
                                <SalesIcon />
                            </DashboardCard>
                            <DashboardCard
                                title={"Pending Orders"}
                                amount={"N500,000,000.00"}
                                show={showOrders}
                                click={() => setShowOrders(!showOrders)}
                            >
                                <OrderIcon />
                            </DashboardCard>
                            <DashboardCard
                                title={"Fullfiled Order"}
                                amount={"N500,000,000.00"}
                                show={showFulfilled}
                                click={() => setShowFulfilled(!showFulfilled)}
                            >
                                <FulfulledIcon />
                            </DashboardCard>
                        </div>
                    ) : null}
                    <div className="flex w-full justify-between items-center bg-[#F2B99A] p-[24px] gap-[32px] rounded-[32px] mt-6">
                        <div className="flex justify-between gap-2 items-center">
                            <div className="flex justify-center items-center gap-2">
                                <div className="h-[44px] w-[44px] bg-white rounded-lg"></div>
                                <p className="">Total Table</p>
                            </div>
                            <p className="text-base font-semibold">100</p>
                        </div>
                        <div className="flex justify-center items-center gap-2">
                            <div className="flex justify-center items-center gap-2">
                                <p className="h-[44px] w-[44px] bg-white rounded-lg"></p>
                                <p className="">In-Use Table</p>
                            </div>
                            <p className="text-base font-semibold">100</p>
                        </div>
                        <div className="flex justify-between items-center gap-2">
                            <div className="flex justify-center items-center gap-2">
                                <p className="h-[44px] w-[44px] bg-white rounded-lg"></p>
                                <p className="">Available Table</p>
                            </div>
                            <p className="text-base font-semibold">100</p>
                        </div>
                    </div>
                    <div className="w-full flex flex-col gap-[2px] ">
                        <p className="font-medium text-2xl mt-[32px] mb-4">
                            Pending Orders
                        </p>
                        {style === "list" ? (
                            <div className=" bg-[#ffffff] w-full font-medium text-sm h-fit py-3 flex items-center px-6 gap-1 rounded-t-[32px]">
                                <p className=" w-[15%]">Table No</p>
                                <p className=" w-[20%]">Time Of Order</p>
                                <p className=" w-[20%]">Order Reference</p>
                                <p className=" w-[15%]">Amount</p>
                                <p className=" w-[10%]">Status</p>
                                <p className="w-[15%">Action</p>
                            </div>
                        ) : null}
                        {order?.orders?.slice(0, 10)?.map((detail, index) => {
                            return (
                                <Monitor
                                    tableNo={
                                        order.orders[index].orderitems[0].table
                                            .tableNumber
                                    }
                                    lastSeen={detail.lastSeen}
                                    orderRef={`FSW-${detail.id
                                        .slice(0, 7)
                                        .toUpperCase()}`}
                                    orderStatus={detail.status}
                                    timeOfOrder={dayjs(detail.createdAt).format(
                                        "DD MMM, YYYY HH:mmA"
                                    )}
                                    display={style}
                                    id={detail.id}
                                    expandFunction={() => {
                                        setShow(true);
                                        getOrderid(detail.id);
                                    }}
                                    amount={formatCurrency(detail.total)}
                                />
                            );
                        })}
                        <div className=" flex justify-center py-1 bg-white rounded-b-[32px]">
                            <Link to={"/kitchen/Orders"}>
                                <p className="text-[#F2650D] text-sm font-light">
                                    Show All Order
                                </p>
                            </Link>
                        </div>
                    </div>
                </div>
            </KitchenLayout>
        </div>
    );
}

export default Dashboard;

function HiddenData() {
    return (
        <div className=" text-slate-300 text-3xl w-full">
            <p> xxxxxx</p>
        </div>
    );
}

function DashboardCard({ title, amount, show, click, children }) {
    return (
        <div className=" bg-white border rounded-[32px] flex flex-col gap-6 w-1/3 p-6">
            <div className="w-full flex justify-between items-center ">
                <div className="flex gap-4 items-center">
                    <div className="bg-[#F2650D] flex items-center justify-center p-2 h-10 w-10 rounded-lg">
                        {children}
                    </div>
                    <p>{title}</p>
                </div>
                <div onClick={click}>
                    {show ? (
                        <img
                            src="../../../../images/eye-off-inactive.svg"
                            alt="container"
                        />
                    ) : (
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z"
                                stroke="#b3b3b3"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                                stroke="#b3b3b3"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    )}
                </div>
            </div>
            <div className=" w-full">
                {show ? (
                    <p className="text-3xl text-black">{amount}</p>
                ) : (
                    <HiddenData />
                )}
            </div>
        </div>
    );
}
