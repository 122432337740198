import React, { useState } from "react";
import Button from "../Common/Button";
import { deleteFood } from "../../service/request";
import { ShowSuccessMessage } from "../../utils";
import { ShowErrorMessage } from "../../utils";

function MenuCard({
    title,
    price,
    portion,
    image,
    id,
    setRefresh,
    setMenuItem,
    item,
    setEditModal,
}) {
    const [deleting, setDeleting] = useState(false);
    async function deleteCard() {
        try {
            setDeleting(true);
            const data = await deleteFood(id);
            console.log(data);
            setDeleting(false);
            setRefresh((prevState) => !prevState);
            ShowSuccessMessage("Food deleted Successfully");
        } catch (err) {
            setDeleting(false);
            ShowErrorMessage("Error Occured");
            console.log(err);
        }
    }
    return (
        <div className="border border-[#0000001A] bg-white rounded-[24px]">
            <img
                className="rounded-t-[24px] h-[222px] w-full"
                src={image}
                alt="menu"
            />
            <div className="p-6 flex flex-col gap-2">
                <p className="text-2xl capitalize self-start">{title}</p>
                <p className="flex justify-between font-light">
                    <span>{price}</span> <span className="font-medium">/ </span>
                    <span>{portion}</span>
                </p>

                <div className="flex gap-4 w-full">
                    <Button
                        onClick={() => deleteCard()}
                        value={deleting ? "Deleting..." : "Delete"}
                        className={
                            "w-1/2 flex text-center h-[42px] border border-[#F2650D] rounded-2xl px-4"
                        }
                        btnType={"ghost"}
                        disabled={deleting}
                    />

                    <Button
                        onClick={() => {
                            setMenuItem(item);
                            setEditModal((prev) => !prev);
                        }}
                        value={"Edit"}
                        className={
                            "w-1/2 flex text-center h-[42px] border border-[#F2650D] rounded-2xl px-4"
                        }
                        btnType={"secondary"}
                    />
                </div>
            </div>
        </div>
    );
}
export default MenuCard;
