import React from "react";
import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function Input({
    type,
    required,
    name,
    placeholder,
    onChange,
    value,
    onBlur,
    label,
    error,
    touched,
    disabled,
    id,
    requiredInput,
    ...props
}) {
    const [isPasswordShown, setIsPasswordShown] = useState(false);

    function handlePasswordVisibility() {
        setIsPasswordShown((prevState) => {
            return !prevState;
        });
    }

    return (
        <div className={`w-full`}>
            <div className="mb-1">
                <label className="text-[#000000]">
                    {label}
                    <span className="text-[#F2650D]">
                        {requiredInput && "*"}
                    </span>
                </label>
            </div>
            <div className={`flex flex-col justify-center item-center`}>
                <div className="relative">
                    <input
                        className={`font-light focus:outline-none border border-[#CCC] ${
                            error && "border border-red-400"
                        } bg-[#FFFFFF]] placeholder:text-[#CCC] placeholder:font-light ${
                            disabled && " bg-[#CCCCCC]"
                        } w-full p-4 rounded-lg focus:border-[#F2650D]`}
                        type={isPasswordShown ? "text" : type}
                        required={required}
                        name={name}
                        placeholder={placeholder}
                        onChange={onChange}
                        value={value}
                        onBlur={onBlur}
                        disabled={disabled}
                        id={id}
                        {...props}
                    />

                    {type === "password" && (
                        <div
                            onClick={handlePasswordVisibility}
                            className="absolute -translate-y-1/2 top-1/2
                        right-5 text-center"
                        >
                            {isPasswordShown ? <FaEye /> : <FaEyeSlash />}
                        </div>
                    )}
                </div>

                {error && touched ? (
                    <small className="w-full text-red-400 text-xs">
                        {error}
                    </small>
                ) : null}
            </div>
        </div>
    );
}

export default Input;
