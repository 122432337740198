import { createSlice } from "@reduxjs/toolkit";
import { userApi } from "../../service/rtk-user-query-service";

const initialState = {
    firstName: null,
    lastName: null,
    token: null,
    email: null,
    phone: null,
    isStaff: null,
    userName: null,
    address: null,
    country: null,
    timeZone: null,
    timeFormat: null,
    id: null,
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        updateUser: (state, action) => {
            return { ...state, ...action.payload };
        },
        logoutUser: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            userApi.endpoints.getUserProfile.matchFulfilled,
            (state, { payload }) => {
                return payload.user;
            }
        );
    },
});

// Action creators are generated for each case reducer function
export const { updateUser, logoutUser } = userSlice.actions;
export const selectUser = (state) => state.user;

export default userSlice.reducer;
