import React from "react";
import { ActivePasswordCheckIco } from "../../icons";
import Button from "../Common/Button";

function PlanContainer({
    title,
    caption,
    onClick,
    btnTitle,
    planactive,
    active,
}) {
    return (
        <div
            className={`w-full flex gap-4 p-6 rounded-3xl bg-[#FFCDBC] ${
                !active && "opacity-[0.30000001192092896]"
            }`}
        >
            <div
                className={`self-start p-1 rounded-lg ${
                    planactive && "bg-[#F2650D]"
                }`}
            >
                <div className={`h-6 w-6`}>
                    <ActivePasswordCheckIco color={"#fff"} />
                </div>
            </div>

            <div className="w-full">
                <p>{title}</p>
                <p className="mb-4 font-light text-xs">{caption}</p>
                <Button btnType="primary" value={btnTitle} onClick={onClick} />
            </div>
        </div>
    );
}

export default PlanContainer;
