import React from "react";
import { CancelIcon } from "../../icons";

function Modal({ children, show, setShow, title, width, text }) {
    if (!show) {
        return null;
    }
    return (
        <div className="z-40 fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full">
            <div className="relative flex items-center justify-center top-20 mx-auto w-full">
                <div
                    className={`p-8 bg-white rounded-[32px] ${
                        width ? width : "w-[632px]"
                    }`}
                >
                    <div className="flex justify-between items-center">
                        <p
                            className={`${
                                text ? text : "text-[#F2650D]"
                            } font-Kanit font-medium text-2xl`}
                        >
                            {title}
                        </p>
                        <div
                            onClick={() => setShow((prev) => !prev)}
                            role="button"
                            className="bg-[#FFCDBC] h-8 w-8 rounded-lg flex justify-center items-center cursor-pointer"
                        >
                            <CancelIcon color={"#F2650D"} />
                        </div>
                    </div>

                    {children}
                </div>
            </div>
        </div>
    );
}

export default Modal;
