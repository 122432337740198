import React, { useEffect, useState } from "react";
import KitchenLayout from "../../../components/Layout/kitchenLayout";
import { DownVector } from "../../../icons";
import tableData, { orderData } from "../../../components/data";
import Head from "../../../components/header/head";
import Modal from "../../../components/Organisms/Modal";
import { ShowErrorMessage, formatCurrency } from "../../../utils";
import {
    getOrderbyid,
    getOrders,
    updateOrderstatus,
} from "../../../service/request";

import dayjs from "dayjs";

function TableMonitor() {
    const [show, setShow] = useState(false);
    const data = tableData;
    const [display, setDisplay] = useState("list");
    const [order, setOrder] = useState();
    const [orderItem, setOrderItem] = useState({});
    const [activeItem, setActiveItem] = useState("");
    const [orderStatus, setOrderStatus] = useState("");

    function transformString(str) {
        if (str.length <= 5) {
            return `fsw${str}`;
        }
        const newStr = str.slice(5);
        return `fsw${newStr}`;
    }

    async function updateOrder(id, status) {
        try {
            const { data } = await updateOrderstatus({ status: status }, id);
            setOrderStatus(data.order.status);
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.message) {
                ShowErrorMessage(error?.response?.data?.message);
            } else {
                ShowErrorMessage("Error Occured Check Internet Connection");
            }
        }
    }

    useEffect(
        () =>
            async function getOrder() {
                try {
                    const { data } = await getOrders();
                    setOrder(data);
                } catch (error) {
                    console.log(error);
                    if (error?.response?.data?.message) {
                        ShowErrorMessage(error?.response?.data?.message);
                    } else {
                        ShowErrorMessage(
                            "Error Occured Check Internet Connection"
                        );
                    }
                }
            },
        []
    );

    async function getOrderid(id) {
        try {
            const { data } = await getOrderbyid(id);
            setOrderItem(data.order);
            setOrderStatus(data.order.status);
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.message) {
                ShowErrorMessage(error?.response?.data?.message);
            } else {
                ShowErrorMessage("Error Occured Check Internet Connection");
            }
        }
    }

    const activeStyle =
        "h-14 w-14 bg-[#FDF0EB] border border-solid border-[#F5853F] rounded-lg flex justify-center items-center";
    const inactiveStyle =
        "h-14 w-14 bg-[#FFFFFF] rounded-lg flex justify-center items-center cursor-pointer";
    return (
        <div className=" w-full">
            <Head
                pageTitle={"Foodswipe Kitchen | Table-Monitor"}
                metaDescription={"Foodswipe Kitchen | Table-Monitor"}
            />
            <KitchenLayout pageIntro={"Table Monitor"}>
                <Modal title={"Order Details"} show={show} setShow={setShow}>
                    <div className=" w-full flex flex-col mt-6 gap-8">
                        <div className="w-full flex flex-col gap-4">
                            <div className="w-full flex flex-col gap-4 p-4 rounded-2xl border border-solid border-[rgba(0, 0, 0, 0.10)] ">
                                <div className=" w-full flex justify-between items-center gap-4">
                                    {orderItem?.orderitems?.map((order) => {
                                        return (
                                            <DetailsTab
                                                title={"Table Number"}
                                                info={order.table.tableNumber}
                                            />
                                        );
                                    })}
                                    <DetailsTab
                                        title={"Payment Method"}
                                        info={"Card Payment"}
                                    />
                                    {orderItem?.orderitems?.map((order) => {
                                        return (
                                            <DetailsTab
                                                title={"Order Amount"}
                                                info={formatCurrency(
                                                    orderItem.total
                                                )}
                                            />
                                        );
                                    })}
                                </div>
                                <div className=" w-full flex justify-between items-center gap-4">
                                    {orderItem?.orderitems?.map((order) => {
                                        return (
                                            <DetailsTab
                                                title={"Time of Order"}
                                                info={dayjs(
                                                    order.createdAt
                                                ).format(
                                                    "DD MMM, YYYY HH:mm A"
                                                )}
                                            />
                                        );
                                    })}
                                    {orderItem?.orderitems?.map((order) => {
                                        return (
                                            <DetailsTab
                                                title={"Payment Status"}
                                                info={orderItem.status}
                                            />
                                        );
                                    })}
                                    {orderItem?.orderitems?.map((order) => {
                                        return (
                                            <DetailsTab
                                                title={"Order Reference"}
                                                info={`FSW-${order.id
                                                    .slice(0, 7)
                                                    .toUpperCase()}`}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="w-full p-4 flex justify-between items-center bg-[#ffcdbc] rounded-2xl">
                                <div className="flex items-center justify-center bg-[#FFFFFF] gap-2 p-2 rounded-3xl border border-solid border-[rgba(0, 0, 0, 0.10)]">
                                    <div
                                        className={
                                            orderItem === "pending"
                                                ? "w-6 h-6 bg-[#D2DBF9]  rounded-full  text-[#204BE1]"
                                                : orderItem === "in-progress"
                                                ? "w-6 h-6 bg-[#FFF4E1]  rounded-full  text-[#E69602]"
                                                : orderItem === "fulfilled"
                                                ? "w-6 h-6 bg-[#D2F9D6] rounded-full text-[#20E120]"
                                                : orderItem === "cancelled"
                                                ? "w-6 h-6 bg-[#F9D2D2] rounded-full text-[#E12020]"
                                                : ""
                                        }
                                    ></div>
                                    <p className="font-light">{orderStatus}</p>
                                </div>
                                <button
                                    onClick={() =>
                                        updateOrder(activeItem, "in-progress")
                                    }
                                    className="bg-[#FFECE5] rounded-xl text-[#F2650D] font-medium flex items-center justify-center p-4"
                                >
                                    Treat Order
                                </button>
                                <button
                                    onClick={() =>
                                        updateOrder(activeItem, "cancelled")
                                    }
                                    className="bg-[#FA360B] rounded-xl text-[#FFFFFF] font-medium flex items-center justify-center p-4"
                                >
                                    Cancel Order
                                </button>
                            </div>
                        </div>
                        <div className=" bg-[#ffffff] w-full font-medium border-b border-solid border-[rgba(0, 0, 0, 0.10)] text-sm h-fit py-3 flex items-center">
                            <p className=" w-[15%]">Qty</p>
                            <p className=" w-[60%]">Description</p>
                            <p className=" w-[10%]">Price</p>
                            <p className="w-[15%] self-end">Amount</p>
                        </div>
                        {orderItem?.orderitems?.map((order) => {
                            return (
                                <ModalTable
                                    qty={order.quantity}
                                    desc={order.food.name}
                                    price={order.price}
                                    amount={order.quantity * order.price}
                                />
                            );
                        })}
                        <div className=" bg-[#ffffff] w-full font-medium text-sm h-fit py-3 flex justify-between items-center">
                            <p className=" w-[15%]">Total</p>
                            <p className="w-[15%] self-end">
                                {formatCurrency(orderItem.total)}
                            </p>
                        </div>
                    </div>
                </Modal>
                <div className=" flex flex-col gap-6 bg-[#f7f7f7] w-full">
                    <div className=" flex justify-between items-center w-full">
                        <div className=" flex items-center gap-4">
                            <div
                                className={
                                    display === "list"
                                        ? activeStyle
                                        : inactiveStyle
                                }
                                onClick={() => setDisplay("list")}
                            >
                                <img
                                    src={
                                        display === "list"
                                            ? "../../../../images/list-active.svg"
                                            : "../../../../images/list-inactive.svg"
                                    }
                                    alt="list"
                                />
                            </div>
                            <div
                                className={
                                    display === "grid"
                                        ? activeStyle
                                        : inactiveStyle
                                }
                                onClick={() => setDisplay("grid")}
                            >
                                <img
                                    src={
                                        display === "grid"
                                            ? "../../../../images/grid-active-colored.svg"
                                            : "../../../../images/grid-active.svg"
                                    }
                                    alt="grid"
                                />
                            </div>
                        </div>
                        <div className=" flex gap-4 justify-center items-center">
                            <p>Filter</p>
                            <div className=" flex gap-1 bg-[#ffffff] rounded-lg p-4 w-44 h-14 justify-between items-center border border-solid border-[#F2f2f2]">
                                <p>All Orders</p>
                                <DownVector />
                            </div>
                            <div className=" flex gap-1 bg-[#ffffff] rounded-lg p-4 w-44 h-14 justify-between items-center border border-solid border-[#F2f2f2]">
                                <p>All Status</p>
                                <DownVector />
                            </div>
                        </div>
                    </div>
                    <div
                        className={
                            display === "grid"
                                ? "w-full flex flex-wrap gap-x-2 gap-y-6 items-center justify-between"
                                : display === "list"
                                ? "w-full flex flex-col justify-center items-center gap-[2px] "
                                : null
                        }
                    >
                        {display === "list" ? (
                            <div className=" bg-[#ffffff] w-full font-medium text-sm h-fit py-3 flex items-center px-6 gap-1 rounded-t-[32px]">
                                <p className=" w-[15%]">Table No</p>
                                <p className=" w-[20%]">Time Of Order</p>
                                <p className=" w-[20%]">Order Reference</p>
                                <p className=" w-[15%]">Amount</p>
                                <p className=" w-[10%]">Status</p>
                                <p className="w-[15%">Action</p>
                            </div>
                        ) : null}
                        {order?.orders
                            ?.filter(
                                (detail) =>
                                    detail.status === "pending" ||
                                    detail.status === "in-progress"
                            )
                            .map((detail, index) => {
                                return (
                                    <Monitor
                                        tableNo={
                                            order.orders[index].orderitems[0]
                                                .table.tableNumber
                                        }
                                        lastSeen={detail.lastSeen}
                                        orderRef={`FSW-${detail.id
                                            .slice(0, 7)
                                            .toUpperCase()}`}
                                        orderStatus={detail.status}
                                        timeOfOrder={dayjs(
                                            detail.createdAt
                                        ).format("DD MMM, YYYY HH:mmA")}
                                        id={detail.id}
                                        display={display}
                                        amount={formatCurrency(detail.total)}
                                        expandFunction={() => {
                                            setShow(true);
                                            setActiveItem(detail.id);
                                            getOrderid(detail.id);
                                        }}
                                    />
                                );
                            })}
                        {display === "list" ? (
                            <div className=" bg-[#ffffff] w-full h-fit py-3 flex items-center px-6 gap-1 rounded-b-[32px]">
                                <p className=" w-[15%]"></p>
                                <p className=" w-[20%]"></p>
                                <p className=" w-[20%]"></p>
                                <p className=" w-[15%]"></p>
                                <p className=" w-[10%]"></p>
                                <p className="w-[15%"></p>
                            </div>
                        ) : null}
                    </div>
                </div>
            </KitchenLayout>
        </div>
    );
}

export default TableMonitor;

export function Monitor({
    display,
    tableNo,
    lastSeen,
    timeOfOrder,
    orderRef,
    orderStatus,
    amount,
    expandFunction,
    id,
}) {
    return (
        <div
            key={id}
            className={
                display === "grid"
                    ? "lg:w-1/5 md:w-1/4 sm:w-1/3"
                    : display === "list"
                    ? "w-full"
                    : null
            }
        >
            {display === "grid" ? (
                <div className=" w-full h-36 bg-[#FFFFFF] border border-solid border-[rgba(17, 17, 17, 0.05)] p-6 flex flex-col rounded-[32px] justify-between items-center">
                    <div className=" w-full flex flex-col justify-center items-center">
                        <div className=" w-full flex justify-between items-center">
                            <p className=" w-3/4 text-[#111111]">{tableNo} </p>
                            <div
                                className={
                                    orderStatus === "pending"
                                        ? "w-fit h-6 bg-[#D2DBF9] font-normal text-xs rounded-xl px-2 py-1 flex items-center justify-center text-[#204BE1]"
                                        : orderStatus === "in-progress"
                                        ? "w-fit h-6 bg-[#FFF4E1] font-normal text-xs rounded-xl px-2 py-1 flex items-center justify-center text-[#E69602]"
                                        : orderStatus === "fulfilled"
                                        ? "w-6 h-6 bg-[#D2F9D6] rounded-full text-[#20E120]"
                                        : orderStatus === "cancelled"
                                        ? "w-6 h-6 bg-[#F9D2D2] rounded-full text-[#E12020]"
                                        : ""
                                }
                            ></div>
                        </div>
                        <p className=" w-full text-[#111111] opacity-50 text-xs font-light">
                            {lastSeen}
                        </p>
                    </div>
                    <div className=" w-full flex justify-between items-center">
                        <p className=" text-sm">Last Order</p>
                        <div
                            className=" flex items-center gap-3 cursor-pointer"
                            onClick={expandFunction}
                        >
                            <p className=" text-[#F2650D] text-sm">Expand</p>
                            <svg
                                width="8"
                                height="14"
                                viewBox="0 0 8 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1 13L7 7L1 1"
                                    stroke="#F2650D"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            ) : display === "list" ? (
                <div className=" bg-[#ffffff] w-full flex font-light text-sm items-center gap-1 px-6 h-fit py-3">
                    <p className=" w-[15%]">{tableNo}</p>
                    <p className=" w-[20%]">{timeOfOrder}</p>
                    <p className=" w-[20%]">{orderRef}</p>
                    <p className=" w-[15%]">{amount}</p>
                    <div className=" w-[10%]">
                        <p
                            className={
                                orderStatus === "pending"
                                    ? "w-fit h-6 bg-[#D2DBF9] font-normal text-xs rounded-xl px-2 py-1 flex items-center justify-center text-[#204BE1]"
                                    : orderStatus === "in-progress"
                                    ? "w-fit h-6 bg-[#FFF4E1] font-normal text-xs rounded-xl px-2 py-1 flex items-center justify-center text-[#E69602]"
                                    : orderStatus === "fulfilled"
                                    ? "w-6 h-6 bg-[#D2F9D6] rounded-full text-[#20E120]"
                                    : orderStatus === "cancelled"
                                    ? "w-6 h-6 bg-[#F9D2D2] rounded-full text-[#E12020]"
                                    : ""
                            }
                        >
                            {orderStatus}
                        </p>
                    </div>
                    <div
                        className="w-[15%] flex items-center gap-4 cursor-pointer"
                        onClick={expandFunction}
                    >
                        <p className=" font-light text-sm text-[#F2650D]">
                            View Order
                        </p>
                        <svg
                            width="8"
                            height="14"
                            viewBox="0 0 8 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1 13L7 7L1 1"
                                stroke="#F2650D"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                </div>
            ) : null}
        </div>
    );
}
export function DetailsTab({ title, info }) {
    return (
        <div className="flex flex-col ">
            <p className="text-[#B3B3B3] text-sm font-light">{title}</p>
            <p>{info}</p>
        </div>
    );
}

export function ModalTable({ qty, desc, price, amount }) {
    return (
        <div className=" bg-[#ffffff] w-full pb-2 border-b border-solid border-[rgba(0, 0, 0, 0.10)] font-light text-sm h-fit flex items-center">
            <p className=" w-[15%]">{qty}</p>
            <p className=" w-[60%]">{desc}</p>
            <p className=" w-[10%]">{price}</p>
            <p className="w-[15%] seld-end">{amount}</p>
        </div>
    );
}
