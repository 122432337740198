import { toast } from "react-toastify";

function ShowErrorMessage(message) {
    return toast.error(message);
}

function ShowSuccessMessage(message) {
    return toast.success(message);
}

const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-NG", {
        style: "currency",
        currency: "NGN",
    }).format(amount);
};

export { formatCurrency };
export { ShowErrorMessage, ShowSuccessMessage };

export function ConvertToFormData(values) {
    const formData = new FormData();
    for (const value in values) {
        if (values[value]) formData.append("" + value, values[value]);
    }
    return formData;
}
