import React from "react";
import SidebarTab from "./sidebarTab";
import { useLocation } from "react-router-dom";
import FoodSwipeIcon from "../../icons/FoodSwipeIcon";
import {
    DashboardIco,
    OrdersIco,
    SettingsIco,
    TableMonitorIco,
} from "../../icons";

function SidePanel() {
    const { pathname } = useLocation();
    return (
        <div className=" w-[16.444%] pt-16 pl-6 h-screen bg-[#FFFFFF] fixed flex flex-col">
            <div className=" mb-16  pl-6 ">
                <FoodSwipeIcon />
            </div>
            <div className=" w-full flex flex-col gap-4">
                <SidebarTab
                    path={"/kitchen/dashboard"}
                    location={pathname}
                    title={"Dashoard"}
                    activeIcon={"../../../images/grid.svg"}
                    inactiveIcon={<DashboardIco />}
                />
                <SidebarTab
                    path={"/kitchen/table-monitor"}
                    title={"Table Monitor"}
                    location={pathname}
                    activeIcon={"../../../images/grid.svg"}
                    inactiveIcon={<TableMonitorIco />}
                />
                <SidebarTab
                    path={"/kitchen/orders"}
                    title={"Orders"}
                    location={pathname}
                    activeIcon={"../../../images/grid.svg"}
                    inactiveIcon={<OrdersIco />}
                />
                <SidebarTab
                    path={"/kitchen/settings/account"}
                    title={"Settings"}
                    location={pathname}
                    activeIcon={"../../../images/grid.svg"}
                    inactiveIcon={<SettingsIco />}
                />
            </div>
        </div>
    );
}

export default SidePanel;
