import api from "./api";

api.interceptors.request.use(
    async (config) => {
        let token = localStorage.getItem("token");
        config.headers["Authorization"] = `Bearer ${token}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export function signupUser(data) {
    return api.post(`/auth/signup/`, data);
}
export function forgetPassword(data) {
    return api.post(`/auth/forgot-password`, data);
}
export function passwordReset(data, id, token) {
    return api.post(`/auth/reset-password/${id}/${token}`, data);
}

export function signinUser(data) {
    return api.post(`/auth/login/`, data);
}

export function createTable(data) {
    return api.post(`/table`, data);
}

export function createSupportTicket(data) {
    return api.post(`/ticket`, data);
}

export function getSupportTickets() {
    return api.get(`/ticket`);
}

export function getTables() {
    return api.get(`/table`);
}
export function getOrders() {
    return api.get(`/order`);
}

export function getOrderbyid(id) {
    return api.get(`/order/${id}`);
}
export function updateOrderstatus(data, id) {
    return api.put(`/order/${id}/status`, data);
}

export function createFood(data) {
    return api.post(`/food`, data);
}

export function updateFood(data, id) {
    return api.put(`/food/${id}`, data);
}

export function getFoods(businessId) {
    return api.get(`/food?businessId=${businessId}`);
}
export function deleteFood(id) {
    return api.delete(`/food/${id}`);
}
export function getCategories(businessId) {
    return api.get(`/category?businessId=${businessId}`);
}
export function createCategories(data) {
    return api.post(`/category`, data);
}
export function changePassword(data) {
    return api.put(`/user/change-password`, data);
}
export function updateUserProfile(imageData) {
    api.interceptors.request.use(
        async (config) => {
            config.headers["Content-Type"] = `multipart/form-data`;
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    return api.put(`/user/update-profile`, imageData);
}
export function updateBusinessProfile(imageData, businessId) {
    api.interceptors.request.use(
        async (config) => {
            config.headers["Content-Type"] = `multipart/form-data`;
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    return api.put(`/business/update-profile/${businessId}`, imageData);
}

export function getUserAvatar(userId) {
    return api.get(`/user/${userId}/`);
}
