import React from "react";
import Button from "../Common/Button";

function DownloadTab({ date, qty, range, tag }) {
    return (
        <div className="p-6 flex flex-col bg-white rounded-3xl w-1/3 gap-6">
            <div>
                <div className=" flex justify-between items-center">
                    <p className=" text-[#808080] text-sm font-light">
                        Generation Date
                    </p>
                    <p className=" px-2 py-1 flex justify-center items-center rounded-xl bg-[#C2FFD7] text-[#008C30]">
                        {tag}
                    </p>
                </div>
                <p className=" font-medium">{date}</p>
            </div>
            <div>
                <p className=" text-[#808080] text-sm font-light">Quantity</p>
                <p className=" font-medium">{qty}</p>
            </div>
            <div>
                {" "}
                <p className=" text-[#808080] text-sm font-light">
                    Number range
                </p>
                <p className=" font-medium">{range}</p>
            </div>
            <div className=" flex gap-2">
                <Button btnType="secondary" value={"View"} />
                <Button btnType="ghost" value={"Download"} />
            </div>
        </div>
    );
}

export default DownloadTab;
