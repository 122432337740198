import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/user.slice";
import businessReducer from "./slices/business.slice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { userApi } from "../service/rtk-user-query-service";
import { businessApi } from "../service/rtk-business-query-service";

const rootReducer = combineReducers({
    user: userReducer,
    business: businessReducer,
    [userApi.reducerPath]: [userApi.reducer],
});

const persistConfig = {
    key: "root",
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            userApi.middleware,
            businessApi.middleware
        ),
});

export const persistor = persistStore(store);
