import React, { useState } from "react";
import KitchenLayout from "../../../components/Layout/kitchenLayout";
import InternalPageNav from "../../../components/Organisms/InternalPageNav";
import Input from "../../../components/Common/Input";
import Button from "../../../components/Common/Button";
import PasswordChecker from "../../../components/Organisms/PasswordChecker";
import PlanContainer from "../../../components/Organisms/PlanContainer";
import InfoList from "../../../components/Organisms/InfoList";
import Head from "../../../components/header/head";
import * as Yup from "yup";
import { changePassword } from "../../../service/request";
import { Form, Formik, ErrorMessage } from "formik";
import { ShowSuccessMessage, ShowErrorMessage } from "../../../utils";

function Account() {
    const [UpperCase, setUppercase] = useState(false);
    const [LowerCase, setLowercase] = useState(false);
    const [Digit, setDigit] = useState(false);
    const [SpecialCharacter, setSpecialCharacter] = useState(false);

    function HandleValidation(value) {
        setUppercase(/[A-Z]/.test(value));
        setLowercase(/[a-z]/.test(value));
        setDigit(/\d/.test(value));
        setSpecialCharacter(/[*^?/#+=@&$%!]/.test(value));
    }

    const [loading, setLoading] = useState("");
    const ChangepasswordSchema = Yup.object().shape({
        currentPassword: Yup.string().required("Password is required"),
        newPassword: Yup.string().required("Password is required"),
        Confirmpassword: Yup.string().oneOf(
            [Yup.ref("newPassword"), null],
            "Passwords must match"
        ),
    });

    async function ChangePassword(values) {
        try {
            setLoading(true);
            const data = await changePassword({
                currentPassword: values.currentPassword,
                newPassword: values.newPassword,
            });
            ShowSuccessMessage(data.data.message);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
            if (
                error.response.data.message.includes(
                    "fails to match the 8-32characters, at least one uppercase and lowercase, one number and one special character pattern"
                )
            ) {
                ShowErrorMessage(
                    "Password should contain more than 8 character with 1 uppercase, lowercase and special character"
                );
            } else if (error?.response?.data?.message) {
                // toast.error(error.response.data.message);
                ShowErrorMessage(error?.response?.data?.message);
            } else {
                ShowErrorMessage("Error Occured Check Internet Connection");
            }
        }
    }

    return (
        <>
            <Head
                pageTitle={"Foodswipe Kitchen | Account"}
                metaDescription={"Foodswiipe Kitchen"}
            />
            <KitchenLayout pageIntro={"Settings"}>
                <InternalPageNav
                    data={[
                        { link: "table-generation", title: "Table Generation" },
                        { link: "menu-management", title: "Menu Management" },
                        { link: "account", title: "Account" },
                        { link: "profile", title: "Profile" },
                        { link: "personalisation", title: "Personalisation" },
                        { link: "support", title: "Support" },
                    ]}
                />

                <section className="mt-8 flex justify-between items-center mb-12">
                    <div className="w-[45%]">
                        <p className="text-2xl mb-4">Change Password</p>
                        <Formik
                            initialValues={{
                                currentPassword: "",
                                newPassword: "",
                                Confirmpassword: "",
                            }}
                            validationSchema={ChangepasswordSchema}
                            onSubmit={(values, { resetForm }) => {
                                // same shape as initial values
                                resetForm({ values: "" });
                                ChangePassword(values);
                            }}
                        >
                            {({
                                errors,
                                touched,
                                values,
                                handleChange,
                                handleSubmit,
                                setFieldValue,
                                handleBlur,
                            }) => (
                                <Form
                                    onSubmit={handleSubmit}
                                    className="flex gap-4 flex-col"
                                >
                                    <div>
                                        <Input
                                            placeholder={"Input password"}
                                            label={"Old Password"}
                                            requiredInput={true}
                                            value={values.currentPassword}
                                            name="currentPassword"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            type={"password"}
                                        />
                                        <div className="text-red-500">
                                            <ErrorMessage name="currentPassword" />
                                        </div>
                                    </div>
                                    <div>
                                        <Input
                                            placeholder={"Input password"}
                                            label={"New Password"}
                                            value={values.newPassword}
                                            name="newPassword"
                                            onChange={(e) => {
                                                setFieldValue(
                                                    "newPassword",
                                                    e.target.value
                                                );
                                                HandleValidation(
                                                    e.target.value
                                                );
                                            }}
                                            onBlur={handleBlur}
                                            type={"password"}
                                        />
                                        <div className="text-red-500">
                                            <ErrorMessage name="newPassword" />
                                        </div>
                                    </div>
                                    <div>
                                        <Input
                                            placeholder={"Input password"}
                                            label={"Confirm Password"}
                                            value={values.Confirmpassword}
                                            name="Confirmpassword"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            type={"password"}
                                        />
                                        <div className="text-red-500">
                                            <ErrorMessage name="Confirmpassword" />
                                        </div>
                                    </div>
                                    <Button
                                        type="submit"
                                        btnType="primary"
                                        value={loading ? "Changing" : "Change"}
                                    />
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <div className="w-[28.83%] p-4 bg-white rounded-3xl">
                        <div>
                            <p className="font-medium mb-2">💡Password Hint!</p>
                            <p className="font-light text-sm font-Kanit mb-4">
                                Password must contain the following characters;
                            </p>
                            <div className="flex justify-between gap-2 mb-2">
                                <PasswordChecker
                                    title={"Capital Letters"}
                                    condition={UpperCase ? true : false}
                                />
                                <PasswordChecker
                                    title={"Small Letters"}
                                    condition={LowerCase ? true : false}
                                />
                            </div>
                            <div className="flex justify-between gap-2">
                                <PasswordChecker
                                    title={"Numbers"}
                                    condition={Digit ? true : false}
                                />
                                <PasswordChecker
                                    title={"Special Characters"}
                                    condition={SpecialCharacter ? true : false}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="w-full mb-12">
                    <p className="text-2xl mb-4">Subscription Plan</p>
                    <div className="flex gap-6 w-full">
                        <PlanContainer
                            title={"Free Plan"}
                            caption={"Free till Dec 2023"}
                            planactive={true}
                            btnTitle={"Cancel"}
                            active={true}
                        />
                        <PlanContainer
                            title={"Quarterly Plan"}
                            caption={"Coming Soon"}
                            planactive={true}
                            btnTitle={"Upgrade"}
                            active={false}
                        />
                        <PlanContainer
                            title={"Free Plan"}
                            caption={"Comming Soon"}
                            planactive={true}
                            btnTitle={"Upgrade"}
                            active={false}
                        />
                    </div>
                </section>
                <section className="w-full">
                    <p className="text-2xl mb-4">Subscription Plan</p>
                    <div className="w-[80%] flex flex-col gap-2">
                        <div className="flex">
                            <div className="w-1/2">
                                <InfoList
                                    title={"Registration Date"}
                                    caption={"2 Jan 2023"}
                                />
                            </div>
                            <div className="w-1/2">
                                <InfoList
                                    title={"Renewal Date"}
                                    caption={"2 Jan 2024"}
                                />
                            </div>
                        </div>
                        <div className="flex">
                            <div className="w-1/2">
                                <InfoList
                                    title={"Subscription Amount"}
                                    caption={"N100,000"}
                                />
                            </div>
                            <div className="w-1/2">
                                <InfoList
                                    title={"Payment Method"}
                                    caption={"Card Payment"}
                                />
                            </div>
                        </div>
                        <div className="flex">
                            <div className="w-1/2">
                                <InfoList
                                    title={"Card Holder Name"}
                                    caption={"John Doe"}
                                />
                            </div>
                            <div className="w-1/2">
                                <InfoList
                                    title={"Card Number"}
                                    caption={"xxxx. 4735"}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </KitchenLayout>
        </>
    );
}

export default Account;
