import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Dashboard from "../pages/Kitchen/Dashboard";
import TableMonitor from "../pages/Kitchen/Table-monitor";
import Order from "../pages/Kitchen/Order";
import Account from "../pages/Kitchen/Settings/Account";
import SignIn from "../pages/Authentication/SignIn";
import SignUp from "../pages/Authentication/SignUp";
import Recovery from "../pages/Authentication/Recovery";
import Profile from "../pages/Kitchen/Settings/Profile";
import TableGeneration from "../pages/Kitchen/Settings/TableGeneration";
import MenuManagement from "../pages/Kitchen/Settings/MenuManagement";
import Personalisation from "../pages/Kitchen/Settings/Personalisation";
import Support from "../pages/Kitchen/Settings/Support";
import TableDownload from "../pages/Kitchen/Settings/TableDownload";
import ResetPassword from "../pages/Authentication/ResetPassword";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            { index: true, element: <SignIn /> },
            {
                path: "signup",
                element: <SignUp />,
            },
            {
                path: "recovery",
                element: <Recovery />,
            },
            {
                path: "signin",
                element: <SignIn />,
            },
            {
                path: "/auth/reset-password/:id/:token",
                element: <ResetPassword />,
            },

            { path: "kitchen/dashboard", element: <Dashboard /> },
            { path: "authentication/recovery", element: <Recovery /> },
            {
                path: "authentication/auth/reset-password/:id/:token",
                element: <ResetPassword />,
            },
            { path: "authentication/SignUp", element: <SignUp /> },
            { path: "authentication/SignIn", element: <SignIn /> },
            {
                path: "kitchen/table-monitor",
                element: <TableMonitor />,
            },
            {
                path: "kitchen/orders",
                element: <Order />,
            },
            {
                path: "kitchen/settings",
                element: <Account />,
                // children: {}
            },
            {
                path: "kitchen/settings/account",
                element: <Account />,
            },
            {
                path: "kitchen/settings/profile",
                element: <Profile />,
            },
            {
                path: "kitchen/settings/table-generation",
                element: <TableGeneration />,
            },
            {
                path: "kitchen/settings/table-download",
                element: <TableDownload />,
            },
            {
                path: "kitchen/settings/menu-management",
                element: <MenuManagement />,
            },
            {
                path: "kitchen/settings/personalisation",
                element: <Personalisation />,
            },
            {
                path: "kitchen/settings/support",
                element: <Support />,
            },
        ],
    },
]);
