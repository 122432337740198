import React, { useState, useRef } from "react";
import KitchenLayout from "../../../components/Layout/kitchenLayout";
import InternalPageNav from "../../../components/Organisms/InternalPageNav";
import InfoList from "../../../components/Organisms/InfoList";
import Head from "../../../components/header/head";
import Button from "../../../components/Common/Button";
import Input from "../../../components/Common/Input";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, updateUser } from "../../../redux-store/slices/user.slice";
import {
    updateBusinessProfile,
    updateUserProfile,
} from "../../../service/request";
import { ShowErrorMessage, ShowSuccessMessage } from "../../../utils";
import * as Yup from "yup";
import { Formik, ErrorMessage, Form } from "formik";
import { ConvertToFormData } from "../../../utils";
import {
    selectBusiness,
    updateBusiness,
} from "../../../redux-store/slices/business.slice";

function Profile() {
    const [editProfile, setEditProfile] = useState(false);

    const currentUser = useSelector(selectUser);
    const currentBusiness = useSelector(selectBusiness);

    return (
        <>
            <Head
                pageTitle={"Foodswipe Kitchen | Profile"}
                metaDescription={"Foodswiipe Kitchen"}
            />
            <KitchenLayout pageIntro={"Settings"}>
                {editProfile ? (
                    <EditProfile
                        formData={{
                            firstName: currentUser.firstName,
                            lastName: currentUser.lastName,
                            email: currentUser.email,
                            timeFormat: currentUser.timeFormat,
                            timeZone: currentUser.timeZone,
                            country: currentUser.country,
                            address: currentUser.address,
                            phone: currentUser.phone,
                            profileAvatarUrl: currentUser.profileAvatarUrl,
                        }}
                        businessData={{
                            businessName: currentBusiness.businessName,
                            menuUrl: currentBusiness.menuUrl,
                            businessPhone: currentBusiness.businessPhone,
                            businessEmail: currentBusiness.businessEmail,
                            businessAddress: currentBusiness.businessAddress,
                            businessAddressCity:
                                currentBusiness.businessAddressCity,
                            businessAddressState:
                                currentBusiness.businessAddressState,
                            businessAddressCountry:
                                currentBusiness.businessAddressCountry,
                            businessLogoUrl: currentBusiness.businessLogoUrl,
                            currency: currentBusiness.currency,
                        }}
                        setEditProfile={setEditProfile}
                    />
                ) : (
                    <>
                        <div className="flex items-center">
                            <div className="w-[90%]">
                                <InternalPageNav
                                    data={[
                                        {
                                            link: "table-generation",
                                            title: "Table Generation",
                                        },
                                        {
                                            link: "menu-management",
                                            title: "Menu Management",
                                        },
                                        { link: "account", title: "Account" },
                                        { link: "profile", title: "Profile" },
                                        {
                                            link: "personalisation",
                                            title: "Personalisation",
                                        },
                                        { link: "support", title: "Support" },
                                    ]}
                                />
                            </div>
                            <div className="w-[20%]">
                                <Button
                                    btnType="primary"
                                    value={"Edit profile"}
                                    onClick={() =>
                                        setEditProfile((prevState) => {
                                            return !prevState;
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <section className="mt-8 flex justify-between items-center mb-12">
                            <div className="w-[60%]">
                                <p className="text-2xl mb-4">
                                    Personal Information
                                </p>
                                <div className="w-full flex flex-col gap-4">
                                    <div className="flex">
                                        <div className="w-1/2">
                                            <InfoList
                                                title={"First Name"}
                                                caption={currentUser.firstName}
                                            />
                                        </div>
                                        <div className="w-1/2">
                                            <InfoList
                                                title={"Last Name"}
                                                caption={currentUser.lastName}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="w-1/2">
                                            <InfoList
                                                title={"Phone Number"}
                                                caption={currentUser.phone}
                                            />
                                        </div>
                                        <div className="w-1/2">
                                            <InfoList
                                                title={"Email"}
                                                caption={currentUser.email}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex">
                                        <div className="w-full">
                                            <InfoList
                                                title={"Address"}
                                                caption={currentUser.address}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="w-1/2">
                                            <InfoList
                                                title={"Timezone"}
                                                caption={currentUser.timeZone}
                                            />
                                        </div>
                                        <div className="w-1/2">
                                            <InfoList
                                                title={"Time Format"}
                                                caption={currentUser.timeFormat}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="w-1/2">
                                            <InfoList
                                                title={"Location"}
                                                caption={currentUser.country}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-[20%] rounded-3xl">
                                <div>
                                    <img
                                        className="w-[172px] h-[196px] rounded-2xl"
                                        src={currentUser.profileAvatarUrl}
                                        alt="user profile avatar"
                                    />
                                </div>
                            </div>
                        </section>
                        <section className="mt-8 flex justify-between items-center mb-12">
                            <div className="w-[60%]">
                                <p className="text-2xl mb-4">
                                    Business Information
                                </p>
                                <div className="w-full flex flex-col gap-4">
                                    <div className="flex">
                                        <div className="w-1/2">
                                            <InfoList
                                                title={"Business Name"}
                                                caption={
                                                    currentBusiness.businessName
                                                }
                                            />
                                        </div>
                                        <div className="w-1/2">
                                            <InfoList
                                                title={"Menu URL"}
                                                caption={
                                                    currentBusiness.menuUrl
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="w-1/2">
                                            <InfoList
                                                title={"Business Phone"}
                                                caption={
                                                    currentBusiness.businessPhone
                                                }
                                            />
                                        </div>
                                        <div className="w-1/2">
                                            <InfoList
                                                title={"Business Email"}
                                                caption={
                                                    currentBusiness.businessEmail
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className="flex">
                                        <div className="w-full">
                                            <InfoList
                                                title={"Business Address"}
                                                caption={
                                                    currentBusiness.businessAddress
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="w-1/2">
                                            <InfoList
                                                title={"City"}
                                                caption={
                                                    currentBusiness.businessAddressCity
                                                }
                                            />
                                        </div>
                                        <div className="w-1/2">
                                            <InfoList
                                                title={"State"}
                                                caption={
                                                    currentBusiness.businessAddressState
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="w-1/2">
                                            <InfoList
                                                title={"Location"}
                                                caption={
                                                    currentBusiness.businessAddressCountry
                                                }
                                            />
                                        </div>
                                        <div className="w-1/2">
                                            <InfoList
                                                title={"Currency"}
                                                caption={
                                                    currentBusiness.currency
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-[20%] rounded-3xl">
                                <div>
                                    <img
                                        className="w-[172px] rounded-3xl h-[172px]"
                                        src={currentBusiness.businessLogoUrl}
                                        alt="user business logo"
                                    />
                                </div>
                            </div>
                        </section>
                    </>
                )}
            </KitchenLayout>
        </>
    );
}

export default Profile;

function EditProfile({ setEditProfile, formData, businessData }) {
    const [oldProfileAvatarUrl, setOldProfileAvatarUrl] = useState(null);
    const [oldBusinessLogoUrl, setOldBusinessLogoUrl] = useState(null);
    const dispatch = useDispatch();
    const profileImageRef = useRef(null);
    const businessLogoRef = useRef(null);
    const [submitting, setSubmitting] = useState(false);
    const [updating, setUpdating] = useState(false);
    const business = useSelector((state) => state.business);

    const updateProfile = async (values) => {
        try {
            setSubmitting(true);
            delete values.email;
            delete values.profileAvatarUrl;
            const response = await updateUserProfile(ConvertToFormData(values));
            dispatch(updateUser(values));
            ShowSuccessMessage(response.data.message);
            setEditProfile(false);
            setSubmitting(false);
        } catch (error) {
            setSubmitting(false);
            if (oldProfileAvatarUrl) {
                dispatch(
                    updateUser({
                        profileAvatarUrl: oldProfileAvatarUrl,
                    })
                );
            }
            if (error.response) {
                return ShowErrorMessage(
                    "Update Failed " + error.response?.data?.message
                );
            }
            return ShowErrorMessage(
                "Update failed due to internet connection error"
            );
        }
    };

    const updatebusiness = async (values) => {
        try {
            setUpdating(true);
            delete values.businessLogoUrl;
            const response = await updateBusinessProfile(
                ConvertToFormData(values),
                business.id
            );
            dispatch(updateBusiness(values));
            ShowSuccessMessage(response.data.message);
            setEditProfile(false);
            setUpdating(false);
        } catch (error) {
            setUpdating(false);
            if (oldBusinessLogoUrl) {
                dispatch(
                    updateBusiness({
                        businessLogoUrl: oldBusinessLogoUrl,
                    })
                );
            }
            if (error.response) {
                return ShowErrorMessage(
                    "Update Failed " + error.response?.data?.message
                );
            }
            return ShowErrorMessage(
                "Update failed due to internet connection error"
            );
        }
    };

    function handleSubmit(values) {
        updateProfile(values);
    }

    function handleBusinessSubmit(values) {
        updatebusiness(values);
        console.log(values);
    }
    const profileValidationSchema = Yup.object({
        firstName: Yup.string()
            .max(20, "Must be 20 character or less")
            .min(2, "Character is less")
            .required("Field is required"),
        lastName: Yup.string()
            .max(20, "Must be 20 character or less")
            .min(2, "Character is less")
            .required("Field is required"),
        phone: Yup.string()
            // .matches(/^[0-9]{12}/, "Phone number is invalid")
            .required("Phone Number is required"),
        email: Yup.string()
            .email("Invalid email address")
            .required("Email is required"),
        address: Yup.string().required("Address is required"),
        timeZone: Yup.string().required("Timezone is required"),
        timeFormat: Yup.string().required("Time format is required"),
        country: Yup.string().required("Location is required"),
    });
    const businessValidationSchema = Yup.object({
        businessName: Yup.string()
            .max(20, "Must be 20 character or less")
            .min(2, "Character is less")
            .required("Field is required"),
        menuUrl: Yup.string().required("Field is required"),
        businessPhone: Yup.string()
            // .matches(/^[0-9]{12}/, "Phone number is invalid")
            .required("Phone Number is required"),
        businessEmail: Yup.string()
            .email("Invalid email address")
            .required("Email is required"),
        businessAddress: Yup.string().required("Address is required"),
        businessAddressCity: Yup.string().required("Business City is required"),
        businessAddressState: Yup.string().required(
            "Business State is required"
        ),
        businessAddressCountry: Yup.string().required("Country is required"),
        currency: Yup.string().required("currency is required"),
    });

    async function handleAvatarChange(e, setFieldValue) {
        const selectedFile = e.target.files[0];

        if (selectedFile) {
            if (!selectedFile.type.startsWith("image/")) {
                ShowErrorMessage("File should be PNG or JPEG");
                return;
            }
            const fileMaxSize = 8 * 1024 * 1024;
            if (selectedFile.size > fileMaxSize) {
                ShowErrorMessage("File Should not exceed 8MB");
                return;
            }
            const fileUrl = URL.createObjectURL(selectedFile);
            setOldProfileAvatarUrl(formData.profileAvatarUrl);
            console.log(formData.profileAvatarUrl);
            dispatch(
                updateUser({
                    profileAvatarUrl: fileUrl,
                })
            );
            setFieldValue("image", selectedFile);
        } else {
            return;
        }
    }

    async function handleBussinessAvatarChange(e, setFieldValue) {
        const selectedFile = e.target.files[0];

        if (selectedFile) {
            if (!selectedFile.type.startsWith("image/")) {
                ShowErrorMessage("File should be PNG or JPEG");
                return;
            }
            const fileMaxSize = 8 * 1024 * 1024;
            if (selectedFile.size > fileMaxSize) {
                ShowErrorMessage("File Should not exceed 8MB");
                return;
            }
            const fileUrl = URL.createObjectURL(selectedFile);
            setOldBusinessLogoUrl(businessData.businessLogoUrl);
            console.log(businessData.businessLogoUrl);
            dispatch(
                updateBusiness({
                    businessLogoUrl: fileUrl,
                })
            );
            setFieldValue("image", selectedFile);
        } else {
            return;
        }
    }

    return (
        <>
            <Formik
                initialValues={formData}
                validationSchema={profileValidationSchema}
                onSubmit={handleSubmit}
                validateOnBlur={true}
                validateOnChange={true}
            >
                {({
                    values,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    handleBlur,
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <section className="mt-8 flex justify-between items-center mb-12">
                            <div className="w-[60%]">
                                <p className="text-2xl mb-4">
                                    Personal Information
                                </p>

                                <div className="w-full flex flex-col gap-4">
                                    <div className="flex gap-6">
                                        <div className="w-1/2">
                                            <Input
                                                value={values.firstName}
                                                label={"First Name"}
                                                placeholder={"First Name"}
                                                onChange={handleChange}
                                                name={"firstName"}
                                                onBlur={handleBlur}
                                            />

                                            <div className="text-red-500">
                                                <ErrorMessage name="firstName" />
                                            </div>
                                        </div>
                                        <div className="w-1/2">
                                            <Input
                                                value={values.lastName}
                                                placeholder={"Last Name"}
                                                label={"Last Name"}
                                                onChange={handleChange}
                                                name={"lastName"}
                                                onBlur={handleBlur}
                                            />

                                            <div className="text-red-500">
                                                <ErrorMessage name="lastName" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex gap-6">
                                        <div className="w-1/2">
                                            <Input
                                                value={values.phone}
                                                placeholder={
                                                    "Enter Phone Number"
                                                }
                                                label={"Phone Number"}
                                                onChange={handleChange}
                                                name={"phone"}
                                                onBlur={handleBlur}
                                            />
                                            <div className="text-red-500">
                                                <ErrorMessage name="phone" />
                                            </div>
                                        </div>
                                        <div className="w-1/2">
                                            <Input
                                                value={values.email}
                                                placeholder={"Enter Email"}
                                                label={"Email"}
                                                type={"email"}
                                                onChange={handleChange}
                                                disabled
                                                name={"email"}
                                                onBlur={handleBlur}
                                            />

                                            <div className="text-red-500">
                                                <ErrorMessage name="email" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex gap-6">
                                        <div className="w-full">
                                            <Input
                                                value={values.address}
                                                placeholder={"Enter Address"}
                                                label={"Address"}
                                                onChange={handleChange}
                                                name={"address"}
                                                onBlur={handleBlur}
                                            />

                                            <div className="text-red-500">
                                                <ErrorMessage name="address" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex gap-6">
                                        <div className="w-1/2">
                                            <Input
                                                value={values.timeZone}
                                                placeholder={"Timezone"}
                                                label={"Timezone"}
                                                onChange={handleChange}
                                                name={"timeZone"}
                                                onBlur={handleBlur}
                                            />
                                        </div>
                                        <div className="w-1/2">
                                            <Input
                                                value={values.timeFormat}
                                                placeholder={"12 Hours"}
                                                label={"Time Format"}
                                                onChange={handleChange}
                                                name={"timeFormat"}
                                                onBlur={handleBlur}
                                            />

                                            <div className="text-red-500">
                                                <ErrorMessage name="timeFormat" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="w-full">
                                            <Input
                                                value={values.country}
                                                placeholder={"Enter Country"}
                                                label={"Country"}
                                                onChange={handleChange}
                                                name={"country"}
                                                onBlur={handleBlur}
                                            />

                                            <div className="text-red-500">
                                                <ErrorMessage name="country" />
                                            </div>
                                        </div>
                                    </div>
                                    <Button
                                        btnType="primary"
                                        type="submit"
                                        value={
                                            !submitting
                                                ? "Update"
                                                : "Updating..."
                                        }
                                    />
                                </div>
                            </div>
                            <div
                                onClick={() => profileImageRef.current.click()}
                                className="w-[20%] rounded-3xl"
                            >
                                <div>
                                    <img
                                        className="w-[172px] h-[196px] rounded-2xl"
                                        src={
                                            formData?.profileAvatarUrl ||
                                            "/images/Photo.png"
                                        }
                                        alt="user"
                                    />
                                </div>
                            </div>
                            <input
                                ref={profileImageRef}
                                type="file"
                                id="fileUpload"
                                accept="image/*"
                                onChange={(e) =>
                                    handleAvatarChange(e, setFieldValue)
                                }
                                className="absolute hidden w-full top-1"
                            />
                        </section>
                    </Form>
                )}
            </Formik>
            <Formik
                initialValues={businessData}
                validationSchema={businessValidationSchema}
                onSubmit={handleBusinessSubmit}
                validateOnBlur={true}
                validateOnChange={true}
            >
                {({
                    values,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    handleBlur,
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <section className="mt-8 flex justify-between items-center mb-12">
                            <div className="w-[60%]">
                                <p className="text-2xl mb-4">
                                    Business Information
                                </p>
                                <div className="w-full flex flex-col gap-4">
                                    <div className="flex gap-6">
                                        <div className="w-2/3">
                                            <Input
                                                type={"text"}
                                                name={"businessname"}
                                                value={values.businessName}
                                                label={"Business Name"}
                                                placeholder={"Nancy Kitchen"}
                                                onChange={handleChange}
                                                disabled
                                                onBlur={handleBlur}
                                            />
                                            <div className="text-red-500">
                                                <ErrorMessage name="businessname" />
                                            </div>
                                        </div>
                                        <div className="w-2/3">
                                            <Input
                                                label={"Menu URL"}
                                                placeholder={
                                                    "https://foodswiipe.com/nancykitchen"
                                                }
                                                value={values.menuUrl}
                                                name={"menuUrl"}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <div className="text-red-500">
                                                <ErrorMessage name="menuUrl" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex gap-6">
                                        <div className="w-1/2">
                                            <Input
                                                name={"businessPhone"}
                                                label={"Business Phone"}
                                                placeholder={"0812 345 6789"}
                                                value={values.businessPhone}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <div className="text-red-500">
                                                <ErrorMessage name="businessPhone" />
                                            </div>
                                        </div>
                                        <div className="w-1/2">
                                            <Input
                                                label={"Business Email"}
                                                placeholder={
                                                    "nancykitchen@example.com"
                                                }
                                                value={values.businessEmail}
                                                name={"businessEmail"}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <div className="text-red-500">
                                                <ErrorMessage name="businessEmail" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex gap-6">
                                        <div className="w-full">
                                            <Input
                                                label={"Business Address"}
                                                placeholder={
                                                    "147, Broad Street Avenue, Freedom Road, Victoria Garden City, Lagos"
                                                }
                                                value={values.businessAddress}
                                                name={"businessAddress"}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <div className="text-red-500">
                                                <ErrorMessage name="buinesssaddress" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex gap-6">
                                        <div className="w-1/2">
                                            <Input
                                                label={"City"}
                                                placeholder={"Etiosa"}
                                                value={
                                                    values.businessAddressCity
                                                }
                                                name={"businessAddressCity"}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <div className="text-red-500">
                                                <ErrorMessage name="businessAddressCity" />
                                            </div>
                                        </div>
                                        <div className="w-1/2">
                                            <Input
                                                label={"State"}
                                                placeholder={"Lagos State"}
                                                value={
                                                    values.businessAddressState
                                                }
                                                name={"businessAddressState"}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <div className="text-red-500">
                                                <ErrorMessage name="businessAddressState" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex gap-6">
                                        <div className="w-1/2">
                                            <Input
                                                label={"Country"}
                                                placeholder={"Nigeria"}
                                                value={
                                                    values.businessAddressCountry
                                                }
                                                name={"businessAddressCountry"}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <div className="text-red-500">
                                                <ErrorMessage name="businessAddressCountry" />
                                            </div>
                                        </div>
                                        <div className="w-1/2">
                                            <Input
                                                name={"currency"}
                                                label={"Currency"}
                                                placeholder={"Naira"}
                                                value={values.currency}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <div className="text-red-500">
                                                <ErrorMessage name="currency" />
                                            </div>
                                        </div>
                                    </div>
                                    <Button
                                        type="submit"
                                        btnType="primary"
                                        value={
                                            updating ? "Updating..." : "Update"
                                        }
                                    />
                                </div>
                                {/* <div className="w-[560px] h-[30px] mt-6"> */}

                                {/* </div> */}
                            </div>
                            <div
                                onClick={() => businessLogoRef.current.click()}
                                className="w-[20%] rounded-3xl"
                            >
                                <div>
                                    <img
                                        className="w-[172px] h-[172px] rounded-2xl"
                                        src={
                                            businessData?.businessLogoUrl ||
                                            "/images/biz-Logo.png"
                                        }
                                        alt="user business logo"
                                    />
                                </div>
                            </div>
                            <input
                                ref={businessLogoRef}
                                type="file"
                                id="fileUpload"
                                accept="image/*"
                                onChange={(e) =>
                                    handleBussinessAvatarChange(
                                        e,
                                        setFieldValue
                                    )
                                }
                                className="absolute hidden w-full top-1"
                            />
                        </section>
                    </Form>
                )}
            </Formik>
        </>
    );
}
