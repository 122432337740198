import React from "react";

function FoodIcon() {
    return (
        <>
            <svg
                width="110"
                height="54"
                viewBox="0 0 110 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g id="Union">
                    <path
                        d="M108.913 9.04359C109.376 9.54592 109.344 10.3285 108.842 10.7915L100.656 18.3365C100.153 18.7995 99.3709 18.7676 98.9079 18.2653C98.4449 17.7629 98.4768 16.9804 98.9791 16.5174L104.528 11.403C103.4 11.5852 102.062 11.881 100.613 12.3591C96.4548 13.7316 91.4037 16.598 87.7616 22.6105C87.4077 23.1948 86.647 23.3815 86.0627 23.0276C85.4784 22.6736 85.2917 21.913 85.6456 21.3287C89.6624 14.6978 95.2612 11.5204 99.8379 10.0098C101.968 9.3067 103.881 8.96281 105.32 8.7958L99.5488 2.53435C99.0858 2.03202 99.1177 1.24947 99.62 0.786469C100.122 0.32347 100.905 0.355352 101.368 0.85768L108.913 9.04359Z"
                        fill="#F5853F"
                    />
                    <path
                        d="M40.3451 7.21537C40.5727 6.82527 40.6865 6.41891 40.6865 5.99631C40.6865 5.24862 40.4589 4.87477 40.0038 4.87477C39.3049 4.87477 38.8091 5.58995 38.5165 7.02032L38.1508 8.92205H40.3695L40.2232 10.0192H37.9314L35.7371 21.3565L30.8121 21.8441L33.1527 10.0192H31.4216L31.641 8.92205H33.3477L33.8597 6.99594C34.1523 5.80938 34.9244 4.93979 36.1759 4.38715C37.0862 3.99705 38.3052 3.802 39.8331 3.802C41.361 3.802 42.4663 3.99705 43.149 4.38715C43.8316 4.77725 44.173 5.23236 44.173 5.7525C44.173 6.27263 43.9698 6.69524 43.5634 7.02032C43.1571 7.3454 42.7264 7.50794 42.2712 7.50794C41.3773 7.50794 40.7352 7.41042 40.3451 7.21537Z"
                        fill="#F5853F"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M45.2707 21.8441C41.6948 21.8441 39.9068 20.1618 39.9068 16.7972C39.9068 14.4078 40.5651 12.4411 41.8817 10.8969C43.2796 9.25526 45.1894 8.43443 47.6113 8.43443C49.3667 8.43443 50.6915 8.84078 51.5854 9.65349C52.4794 10.4662 52.9264 11.7259 52.9264 13.4326C52.9264 16.0332 52.2275 18.0894 50.8296 19.601C49.4643 21.0964 47.6113 21.8441 45.2707 21.8441ZM45.9046 11.2383C45.7096 11.6771 45.5308 12.2298 45.3682 12.8962C45.2219 13.5463 45.0513 14.3916 44.8562 15.4318C44.6612 16.4721 44.5636 17.6343 44.5636 18.9183C44.5636 19.3409 44.6287 19.6904 44.7587 19.9667C44.905 20.243 45.165 20.3812 45.5389 20.3812C45.9127 20.3812 46.2134 20.2918 46.441 20.113C46.6848 19.9342 46.8961 19.6335 47.0749 19.2109C47.4 18.4632 47.6926 17.3986 47.9526 16.017C48.2127 14.6191 48.3509 13.6195 48.3671 13.0181C48.3996 12.4167 48.4159 11.8966 48.4159 11.4577C48.4159 11.0026 48.3509 10.6287 48.2208 10.3362C48.0908 10.0436 47.8389 9.8973 47.465 9.8973C47.1074 9.8973 46.8067 10.0111 46.5629 10.2386C46.3191 10.4662 46.0997 10.7994 45.9046 11.2383Z"
                        fill="#F5853F"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M54.3117 16.7972C54.3117 20.1618 56.0997 21.8441 59.6756 21.8441C62.0162 21.8441 63.8692 21.0964 65.2345 19.601C66.6324 18.0894 67.3313 16.0332 67.3313 13.4326C67.3313 11.7259 66.8843 10.4662 65.9903 9.65349C65.0964 8.84078 63.7716 8.43443 62.0162 8.43443C59.5943 8.43443 57.6845 9.25526 56.2866 10.8969C54.97 12.4411 54.3117 14.4078 54.3117 16.7972ZM59.7731 12.8962C59.9357 12.2298 60.1145 11.6771 60.3095 11.2383C60.5046 10.7994 60.724 10.4662 60.9678 10.2386C61.2116 10.0111 61.5123 9.8973 61.8699 9.8973C62.2438 9.8973 62.4957 10.0436 62.6257 10.3362C62.7558 10.6287 62.8208 11.0026 62.8208 11.4577C62.8208 11.8966 62.8045 12.4167 62.772 13.0181C62.7558 13.6195 62.6176 14.6191 62.3575 16.017C62.0975 17.3986 61.8049 18.4632 61.4798 19.2109C61.301 19.6335 61.0897 19.9342 60.8459 20.113C60.6183 20.2918 60.3176 20.3812 59.9438 20.3812C59.57 20.3812 59.3099 20.243 59.1636 19.9667C59.0336 19.6904 58.9685 19.3409 58.9685 18.9183C58.9685 17.6343 59.0661 16.4721 59.2611 15.4318C59.4562 14.3916 59.6268 13.5463 59.7731 12.8962Z"
                        fill="#F5853F"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M76.4455 8.43443C77.0306 8.43443 77.5508 8.49944 78.0059 8.62948C78.0221 8.56446 78.0465 8.44255 78.079 8.26376L78.2009 7.50794C78.2659 7.18286 78.3472 6.75213 78.4447 6.21574L78.8348 4.28962L83.6867 3.802L80.9804 17.9431C80.9641 18.0406 80.956 18.1707 80.956 18.3332V18.7233C80.956 19.0809 81.0454 19.3978 81.2242 19.6742C81.403 19.9342 81.6387 20.0643 81.9313 20.0643C81.6062 20.8282 80.9479 21.3727 79.9564 21.6978C79.615 21.7953 79.2006 21.8441 78.7129 21.8441C78.2416 21.8441 77.7864 21.7141 77.3476 21.454C76.9087 21.2102 76.6243 20.877 76.4942 20.4544C76.2342 20.877 75.8441 21.2102 75.324 21.454C74.8038 21.7141 74.1618 21.8441 73.3978 21.8441C72.6501 21.8441 71.9593 21.7466 71.3254 21.5515C70.7078 21.3565 70.2039 21.0476 69.8138 20.625C69.0986 19.7961 68.741 18.4388 68.741 16.5534C68.741 14.1152 69.4562 12.1566 70.8866 10.6775C72.3169 9.18212 74.1699 8.43443 76.4455 8.43443ZM76.2748 9.8973C75.6734 9.8973 75.2102 10.2224 74.8851 10.8725C74.56 11.5227 74.2268 12.6524 73.8855 14.2615C73.5604 15.8707 73.3978 17.3986 73.3978 18.8452C73.3978 19.8367 73.6904 20.3324 74.2756 20.3324C74.7957 20.3324 75.2346 20.0724 75.5921 19.5522C75.966 19.0321 76.2261 18.3413 76.3723 17.4798L77.6889 10.3362C77.3476 10.0436 76.8762 9.8973 76.2748 9.8973Z"
                        fill="#F5853F"
                    />
                    <path
                        d="M68.9715 42.2537C70.5806 42.2537 71.6371 41.6442 72.141 40.4252C71.4746 40.3927 71.1414 39.9132 71.1414 38.9867C71.1414 38.5641 71.2795 37.7514 71.5559 36.5485L73.0431 28.8441L68.0937 29.3317L67.1916 34.0617C66.5902 36.6298 66.2895 38.5722 66.2895 39.8888C66.2895 40.3927 66.509 40.8965 66.9478 41.4004C67.4679 41.9693 68.1425 42.2537 68.9715 42.2537Z"
                        fill="#F5853F"
                    />
                    <path
                        d="M69.3128 24.4799C68.7927 24.8212 68.5326 25.3088 68.5326 25.9427C68.5326 26.5766 68.7683 27.0561 69.2396 27.3812C69.7273 27.7063 70.3531 27.8688 71.117 27.8688C71.8809 27.8688 72.5149 27.7063 73.0187 27.3812C73.5389 27.0561 73.7989 26.5766 73.7989 25.9427C73.7989 25.3088 73.5633 24.8212 73.0919 24.4799C72.6368 24.1385 72.0272 23.9679 71.2633 23.9679C70.4993 23.9679 69.8492 24.1385 69.3128 24.4799Z"
                        fill="#F5853F"
                    />
                    <path
                        d="M39.7264 30.502C39.9702 30.0144 40.0921 29.543 40.0921 29.0879C40.0921 28.6328 40.0677 28.2833 40.019 28.0395C39.9702 27.7794 39.889 27.5519 39.7752 27.3568C39.5314 26.9342 39.1656 26.7229 38.678 26.7229C38.0766 26.7229 37.5321 26.9424 37.0445 27.3812C36.5243 27.8363 36.2643 28.4215 36.2643 29.1367C36.2643 29.5918 36.4187 29.9981 36.7275 30.3557C37.0526 30.6971 37.459 31.0303 37.9466 31.3554C38.4342 31.6804 38.9543 32.0055 39.507 32.3306C40.0596 32.6557 40.5798 33.0133 41.0674 33.4034C42.1889 34.2973 42.7497 35.3457 42.7497 36.5485C42.7497 37.3613 42.5302 38.1171 42.0914 38.816C41.6688 39.4987 41.0999 40.0919 40.3847 40.5958C38.8243 41.7011 37.012 42.2537 34.9477 42.2537C33.2735 42.2537 32.0057 41.9856 31.1442 41.4492C30.2828 40.8965 29.852 40.2057 29.852 39.3768C29.852 37.8976 30.429 36.9712 31.5831 36.5973C31.9082 36.4835 32.3145 36.4266 32.8022 36.4266C33.306 36.4266 33.8424 36.5323 34.4113 36.7436C34.1512 37.41 34.0212 38.0439 34.0212 38.6453C34.0212 39.9457 34.4845 40.5958 35.4109 40.5958C36.0123 40.5958 36.5569 40.3764 37.0445 39.9375C37.5484 39.4987 37.8003 39.0354 37.8003 38.5478C37.8003 38.0439 37.6378 37.6132 37.3127 37.2556C37.0038 36.898 36.6137 36.5811 36.1424 36.3047C35.671 36.0122 35.159 35.7277 34.6064 35.4514C34.07 35.1751 33.5661 34.8419 33.0947 34.4518C31.9894 33.5578 31.4368 32.3956 31.4368 30.9653C31.4368 30.0388 31.6644 29.2179 32.1195 28.5028C32.5746 27.7713 33.1679 27.1618 33.8993 26.6742C35.3622 25.6827 36.9876 25.1869 38.7755 25.1869C40.5798 25.1869 41.9126 25.4551 42.7741 25.9915C43.6518 26.5279 44.0906 27.2512 44.0906 28.1614C44.0906 28.9579 43.7818 29.608 43.1642 30.1119C42.6278 30.5345 42.0345 30.7458 41.3843 30.7458C40.7342 30.7458 40.1815 30.6646 39.7264 30.502Z"
                        fill="#F5853F"
                    />
                    <path
                        d="M62.7872 37.6945C61.7144 39.1736 60.6579 40.3032 59.6177 41.0834C58.5936 41.8636 57.5452 42.2537 56.4725 42.2537C55.416 42.2537 54.7008 42.1481 54.3269 41.9368V36.5729C53.4004 39.0436 52.1082 40.7584 50.4503 41.7174C49.8489 42.075 49.2638 42.2537 48.6949 42.2537C47.6059 42.2537 46.8257 42.1481 46.3543 41.9368C46.2405 38.2146 46.1348 35.8415 46.0373 34.8175C45.9398 33.7772 45.8504 32.9401 45.7691 32.3062C45.6066 31.0384 45.249 30.1444 44.6964 29.6243C45.3953 29.1042 46.2324 28.8441 47.2076 28.8441C49.0931 28.8441 50.1659 30.0144 50.4259 32.355C50.491 32.9076 50.5235 33.4765 50.5235 34.0617C50.5235 34.9394 50.4259 36.9061 50.2309 39.9619C50.621 39.7831 51.0192 39.3768 51.4256 38.7429C51.8482 38.0927 52.2302 37.3287 52.5715 36.451C53.3354 34.4193 53.7174 32.5257 53.7174 30.7702C53.7174 30.4939 53.6768 30.2094 53.5955 29.9169C53.5305 29.6243 53.4573 29.4374 53.3761 29.3561C53.945 29.0148 54.6601 28.8441 55.5216 28.8441C56.3993 28.8441 57.0414 29.1448 57.4477 29.7462C57.8703 30.3313 58.1385 31.1684 58.2523 32.2575C58.3823 33.6391 58.4474 34.7443 58.4474 35.5733C58.4474 36.7273 58.3905 38.1577 58.2767 39.8644C59.0894 39.2792 59.829 38.0277 60.4954 36.1097C61.1618 34.1917 61.495 32.55 61.495 31.1847C61.495 30.5833 61.4462 30.1038 61.3487 29.7462C61.8851 29.1448 62.6165 28.8441 63.543 28.8441C64.1119 28.8441 64.5995 28.9985 65.0059 29.3073C65.4285 29.6162 65.6398 30.0632 65.6398 30.6483C65.6398 31.2172 65.5504 31.8105 65.3716 32.4281C65.2091 33.0458 64.9896 33.6716 64.7133 34.3055C64.1769 35.5733 63.5349 36.703 62.7872 37.6945Z"
                        fill="#F5853F"
                    />
                    <path
                        d="M80.0697 40.4252C79.5658 41.6442 78.5093 42.2537 76.9001 42.2537C76.0712 42.2537 75.3966 41.9693 74.8765 41.4004C74.4376 40.8965 74.2182 40.3927 74.2182 39.8888C74.2182 38.5722 74.5189 36.6298 75.1203 34.0617L76.0224 29.3317L80.9718 28.8441L79.4845 36.5485C79.2082 37.7514 79.07 38.5641 79.07 38.9867C79.07 39.9132 79.4032 40.3927 80.0697 40.4252Z"
                        fill="#F5853F"
                    />
                    <path
                        d="M76.4613 25.9427C76.4613 25.3088 76.7213 24.8212 77.2414 24.4799C77.7778 24.1385 78.428 23.9679 79.1919 23.9679C79.9559 23.9679 80.5654 24.1385 81.0205 24.4799C81.4919 24.8212 81.7276 25.3088 81.7276 25.9427C81.7276 26.5766 81.4675 27.0561 80.9474 27.3812C80.4435 27.7063 79.8096 27.8688 79.0457 27.8688C78.2817 27.8688 77.6559 27.7063 77.1683 27.3812C76.6969 27.0561 76.4613 26.5766 76.4613 25.9427Z"
                        fill="#F5853F"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M91.0703 41.693C92.0131 41.3191 92.8258 40.7909 93.5085 40.1082C94.1911 39.4093 94.7194 38.5559 95.0932 37.5482C95.4671 36.5404 95.654 35.3295 95.654 33.9154C95.654 32.5013 95.2802 31.331 94.5325 30.4045C93.6873 29.3805 92.4682 28.8685 90.8753 28.8685C89.4612 28.8685 88.4128 29.4455 87.7301 30.5995L88.0471 28.9172L84.0729 29.3317L80.7083 46.6424L85.6577 46.1547L86.4135 42.0099C86.7386 42.1725 87.3644 42.2537 88.2909 42.2537C89.2174 42.2537 90.1439 42.0668 91.0703 41.693ZM87.852 40.8396C87.4294 40.8396 87.0474 40.6934 86.7061 40.4008L87.9983 33.2571C88.3234 31.3228 88.9817 30.3557 89.9732 30.3557C90.2983 30.3557 90.5421 30.4776 90.7046 30.7214C90.8834 30.9653 90.9728 31.4691 90.9728 32.2331C90.9728 32.997 90.9159 33.7691 90.8022 34.5493C90.4121 37.215 89.9732 38.9867 89.4856 39.8644C89.3068 40.1895 89.0955 40.4333 88.8517 40.5958C88.6078 40.7584 88.2746 40.8396 87.852 40.8396Z"
                        fill="#F5853F"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M106.998 37.6457C107.404 37.922 107.607 38.369 107.607 38.9867C107.607 39.5881 107.453 40.0838 107.144 40.4739C106.835 40.864 106.429 41.1891 105.925 41.4492C104.885 41.9856 103.804 42.2537 102.682 42.2537C101.561 42.2537 100.667 42.1318 100 41.888C99.35 41.6442 98.8055 41.2948 98.3666 40.8396C97.5051 39.9782 97.0744 38.7591 97.0744 37.1825C97.0744 34.7281 97.7408 32.7532 99.0737 31.2578C100.504 29.6487 102.463 28.8441 104.95 28.8441C106.494 28.8441 107.648 29.1692 108.412 29.8193C108.981 30.307 109.265 30.949 109.265 31.7455C109.265 34.6062 106.794 36.0365 101.853 36.0365C101.788 36.4591 101.756 36.8493 101.756 37.2068C101.756 37.9545 101.918 38.4747 102.243 38.7672C102.585 39.0436 103.064 39.1817 103.682 39.1817C104.299 39.1817 104.933 39.0436 105.583 38.7672C106.25 38.4747 106.721 38.1008 106.998 37.6457ZM102.024 34.9882C103.178 34.9882 104.088 34.6306 104.754 33.9154C105.421 33.2327 105.754 32.3469 105.754 31.2578C105.754 30.884 105.681 30.5995 105.535 30.4045C105.405 30.1932 105.201 30.0875 104.925 30.0875C104.649 30.0875 104.389 30.1444 104.145 30.2582C103.917 30.3557 103.682 30.5833 103.438 30.9409C102.836 31.7536 102.365 33.1027 102.024 34.9882Z"
                        fill="#F5853F"
                    />
                    <path
                        d="M65.6084 47.9387H64.9763V46.917H66.9938V51.662H65.6084V47.9387Z"
                        fill="#F5853F"
                    />
                    <path
                        d="M65.5651 46.2503C65.6921 46.383 65.8855 46.4494 66.1452 46.4494C66.405 46.4494 66.5955 46.383 66.7167 46.2503C66.8437 46.1117 66.9072 45.9386 66.9072 45.7308C66.9072 45.5172 66.8437 45.344 66.7167 45.2112C66.5955 45.0727 66.405 45.0034 66.1452 45.0034C65.8855 45.0034 65.6921 45.0727 65.5651 45.2112C65.4439 45.344 65.3833 45.5172 65.3833 45.7308C65.3833 45.9386 65.4439 46.1117 65.5651 46.2503Z"
                        fill="#F5853F"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M30.5134 45.2892V51.7053H33.137C33.6334 51.7053 34.049 51.636 34.3838 51.4975C34.7186 51.3532 34.9726 51.1482 35.1458 50.8827C35.319 50.6114 35.4055 50.2881 35.4055 49.9129V49.7917C35.4055 49.3876 35.3074 49.0673 35.1112 48.8306C34.9618 48.6505 34.7708 48.5089 34.5379 48.4057C34.6538 48.3267 34.7554 48.2345 34.8427 48.1292C35.0448 47.8926 35.1458 47.578 35.1458 47.1854V47.0555C35.1458 46.4898 34.9495 46.054 34.557 45.7481C34.1702 45.4421 33.6132 45.2892 32.8859 45.2892H30.5134ZM31.8988 50.597H33.2062C33.4718 50.597 33.6709 50.5277 33.8037 50.3892C33.9364 50.2506 34.0028 50.0544 34.0028 49.8004C34.0028 49.5464 33.9364 49.353 33.8037 49.2202C33.6709 49.0817 33.4718 49.0124 33.2062 49.0124H31.8988V50.597ZM32.9724 47.9387H31.8988V46.3975H32.9724C33.2438 46.3975 33.44 46.4668 33.5612 46.6053C33.6825 46.7438 33.7431 46.9343 33.7431 47.1768C33.7431 47.4077 33.6825 47.5924 33.5612 47.7309C33.44 47.8695 33.2438 47.9387 32.9724 47.9387Z"
                        fill="#F5853F"
                    />
                    <path
                        d="M36.3786 53.5409V52.3634H37.3657C37.5158 52.3634 37.6428 52.3432 37.7467 52.3027C37.8506 52.2681 37.9343 52.2075 37.9978 52.1209C38.0613 52.0343 38.1133 51.916 38.1537 51.7659L38.2038 51.5581H37.2965L35.8418 46.917H37.2359L38.2915 50.4757H38.4647L39.3226 46.917H40.6387L39.314 52.043C39.2216 52.4124 39.0917 52.7068 38.9243 52.9262C38.7627 53.1455 38.5433 53.3014 38.2662 53.3937C37.9949 53.4919 37.6486 53.5409 37.2272 53.5409H36.3786Z"
                        fill="#F5853F"
                    />
                    <path
                        d="M42.6846 46.917L44.1046 51.662H46.3039L47.5335 46.917H46.2L45.3279 50.5104H45.1231L44.1133 46.917H42.6846Z"
                        fill="#F5853F"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M50.4167 51.8265C50.0126 51.8265 49.6547 51.7572 49.343 51.6187C49.0371 51.4802 48.7802 51.2954 48.5724 51.0645C48.3703 50.8279 48.2145 50.5652 48.1048 50.2766C48.0009 49.9822 47.949 49.682 47.949 49.3761V49.2029C47.949 48.8854 48.0009 48.5824 48.1048 48.2937C48.2145 47.9993 48.3703 47.7367 48.5724 47.5058C48.7744 47.2749 49.0255 47.0931 49.3257 46.9603C49.6316 46.8218 49.978 46.7525 50.3647 46.7525C50.8727 46.7525 51.2999 46.8679 51.6462 47.0988C51.9984 47.324 52.2668 47.6213 52.4515 47.9907C52.6362 48.3544 52.7286 48.7527 52.7286 49.1856V49.6532H49.2628C49.2828 49.7938 49.3153 49.9237 49.3603 50.0428C49.4411 50.2506 49.5681 50.4123 49.7413 50.5277C49.9145 50.6432 50.1396 50.7009 50.4167 50.7009C50.6707 50.7009 50.8785 50.6518 51.0401 50.5537C51.2018 50.4555 51.3114 50.3343 51.3692 50.19H52.642C52.5727 50.5075 52.4371 50.7903 52.235 51.0386C52.033 51.2868 51.779 51.4802 51.4731 51.6187C51.1671 51.7572 50.815 51.8265 50.4167 51.8265ZM49.369 48.5275C49.3269 48.6285 49.2951 48.7411 49.2734 48.8652H51.4129C51.3929 48.7322 51.361 48.6109 51.3172 48.5016C51.2364 48.2995 51.1152 48.1465 50.9535 48.0426C50.7977 47.933 50.6014 47.8781 50.3647 47.8781C50.1223 47.8781 49.9174 47.933 49.75 48.0426C49.5826 48.1523 49.4556 48.3139 49.369 48.5275Z"
                        fill="#F5853F"
                    />
                    <path
                        d="M53.6466 46.917V51.662H55.032V48.8999C55.032 48.6343 55.1099 48.4207 55.2658 48.2591C55.4274 48.0917 55.6381 48.008 55.8979 48.008C56.1519 48.008 56.3539 48.0888 56.504 48.2505C56.6541 48.4121 56.7291 48.6199 56.7291 48.8739V51.662H58.1145V48.9518C58.1145 48.2187 57.9673 47.6732 57.6729 47.3153C57.3843 46.9516 56.9629 46.7698 56.4087 46.7698H56.3481C55.9845 46.7698 55.6756 46.8506 55.4216 47.0122C55.1677 47.1739 54.9772 47.4163 54.8502 47.7396C54.8083 47.8512 54.7737 47.9725 54.7463 48.1034V46.917H53.6466Z"
                        fill="#F5853F"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M61.252 51.8179C60.9172 51.8179 60.6112 51.7572 60.3341 51.636C60.0628 51.5148 59.8262 51.3474 59.6241 51.1338C59.4221 50.9145 59.2662 50.6576 59.1565 50.3632C59.0526 50.0688 59.0007 49.7513 59.0007 49.4107V49.2116C59.0007 48.871 59.0498 48.5535 59.1479 48.2591C59.2518 47.9647 59.399 47.7078 59.5895 47.4885C59.7857 47.2634 60.0195 47.0902 60.2908 46.969C60.5621 46.842 60.8652 46.7785 61.2 46.7785C61.581 46.7785 61.91 46.8622 62.1871 47.0296C62.4072 47.1579 62.589 47.3373 62.7326 47.5676V45.3411H64.1267V51.662H63.027V50.6479C62.9984 50.7173 62.9667 50.7841 62.9318 50.8481C62.7644 51.1656 62.5363 51.408 62.2477 51.5754C61.9591 51.737 61.6272 51.8179 61.252 51.8179ZM61.5983 50.6576C61.8119 50.6576 62.0053 50.6114 62.1784 50.519C62.3574 50.4209 62.4988 50.2795 62.6027 50.0948C62.7124 49.91 62.7672 49.6878 62.7672 49.428V49.1077C62.7672 48.8537 62.7124 48.6401 62.6027 48.4669C62.493 48.2937 62.3487 48.161 62.1698 48.0686C61.9908 47.9763 61.7975 47.9301 61.5896 47.9301C61.3587 47.9301 61.1509 47.9907 60.9662 48.1119C60.7873 48.2274 60.6458 48.389 60.5419 48.5968C60.438 48.7988 60.3861 49.0355 60.3861 49.3068C60.3861 49.5839 60.438 49.8235 60.5419 50.0255C60.6458 50.2275 60.7902 50.3834 60.9749 50.4931C61.1596 50.6027 61.3674 50.6576 61.5983 50.6576Z"
                        fill="#F5853F"
                    />
                    <path
                        d="M67.8682 47.9387H68.5003V51.662H69.8857V46.917H67.8682V47.9387Z"
                        fill="#F5853F"
                    />
                    <path
                        d="M69.0371 46.4494C68.7774 46.4494 68.584 46.383 68.457 46.2503C68.3358 46.1117 68.2751 45.9386 68.2751 45.7308C68.2751 45.5172 68.3358 45.344 68.457 45.2112C68.584 45.0727 68.7774 45.0034 69.0371 45.0034C69.2969 45.0034 69.4874 45.0727 69.6086 45.2112C69.7356 45.344 69.7991 45.5172 69.7991 45.7308C69.7991 45.9386 69.7356 46.1117 69.6086 46.2503C69.4874 46.383 69.2969 46.4494 69.0371 46.4494Z"
                        fill="#F5853F"
                    />
                    <path
                        d="M72.1628 51.5494C72.4572 51.6649 72.8439 51.7226 73.3231 51.7226H73.9898V50.5537H73.2884C73.0691 50.5537 72.8988 50.496 72.7775 50.3805C72.6621 50.2593 72.6044 50.0861 72.6044 49.861V47.9301H73.9898V46.917H72.6044V45.6355H71.3142V46.917H70.5956V47.9301H71.3142V49.809C71.3142 50.2881 71.3806 50.6691 71.5134 50.952C71.6519 51.2291 71.8684 51.4282 72.1628 51.5494Z"
                        fill="#F5853F"
                    />
                    <path
                        d="M8.96927 7.66201L1.09728 15.534C0.614219 16.0171 0.614219 16.8003 1.09728 17.2833L8.96927 25.1553C9.45233 25.6384 10.2355 25.6384 10.7186 25.1553C11.2017 24.6722 11.2017 23.889 10.7186 23.406L5.06103 17.7484C9.57559 18.0698 13.5193 19.197 17.5311 21.8535C22.1148 24.8886 25.6107 30.7185 25.6107 36.2023C25.6107 36.8854 26.1645 37.4392 26.8477 37.4392C27.5309 37.4392 28.0847 36.8854 28.0847 36.2023C28.0847 29.7864 24.0827 23.2246 18.8969 19.7907C14.3415 16.7743 9.85866 15.5658 4.87323 15.2567L10.7186 9.41134C11.2017 8.92828 11.2017 8.14508 10.7186 7.66201C10.2355 7.17895 9.45233 7.17895 8.96927 7.66201Z"
                        fill="#F5853F"
                    />
                </g>
            </svg>
        </>
    );
}

export default FoodIcon;
