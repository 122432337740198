import React, { useState, useEffect } from "react";
import InternalPageNav from "../../../components/Organisms/InternalPageNav";
import KitchenLayout from "../../../components/Layout/kitchenLayout";
import Button from "../../../components/Common/Button";
import Modal from "../../../components/Organisms/Modal";
import Input from "../../../components/Common/Input";
import TagsContainer from "../../../components/Organisms/TagsContainer";
import Head from "../../../components/header/head";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { ShowErrorMessage, ShowSuccessMessage } from "../../../utils";
import { Formik, Form } from "formik";
import { createTable, getTables } from "../../../service/request";
import SkeletonLoader from "../../../components/Organisms/SkeletonLoader";
import { useSelector } from "react-redux";

function TableGeneration() {
    const [modal, setModal] = useState(false);
    const [tables, setTables] = useState([]);
    const [tableList, setTableLists] = useState();
    const [submitting, setSubmitting] = useState();
    const business = useSelector((state) => state.business);

    const CreateTableSchema = Yup.object().shape({
        quantity: Yup.number().max(50).required("Required"),
        initials: Yup.string().required("Required"),
    });

    useEffect(() => {
        async function getAllTables() {
            try {
                const { data } = await getTables();
                setTableLists(data);
            } catch (error) {
                console.log(error);
            }
        }
        getAllTables();
    }, [tables]);

    async function CreateTable(values) {
        try {
            setSubmitting(true);
            const data = await createTable({
                quantity: values.quantity,
                initials: values.initials,
            });
            setTables(data);
            ShowSuccessMessage(data?.data?.message);
            setModal(false);
            setSubmitting(false);
        } catch (error) {
            console.log(error);
            setSubmitting(false);
            if (error?.response?.data?.message) {
                ShowErrorMessage(error?.response?.data?.message);
            } else {
                ShowErrorMessage("Error Occured Check Internet Connection");
            }
        }
    }

    return (
        <>
            <Head
                pageTitle={"Foodswipe Kitchen | Table Generation"}
                metaDescription={"Foodswiipe Kitchen"}
            />
            <div>
                <Modal
                    width={"w-[512px]"}
                    title={"Customise Table"}
                    show={modal}
                    setShow={setModal}
                >
                    <Formik
                        initialValues={{
                            quantity: "",
                            initials: "",
                        }}
                        validateSchema={CreateTableSchema}
                        onSubmit={(values) => CreateTable(values)}
                    >
                        {({
                            errors,
                            touched,
                            values,
                            handleChange,
                            handleSubmit,
                            handleBlur,
                        }) => (
                            <Form
                                className="flex flex-col gap-4 mt-12"
                                onSubmit={handleSubmit}
                            >
                                <Input
                                    type="number"
                                    max={50}
                                    name={"quantity"}
                                    label={"Number of Tables"}
                                    placeholder={"Enter number"}
                                    value={values.quantity}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <Input
                                    label={"Company Initials (Optional)"}
                                    placeholder={"Enter initials"}
                                    name={"initials"}
                                    value={values.initials}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <div className="mt-6 w-full">
                                    <button
                                        type="submit"
                                        className="w-full h-[56px] bg-[#F2650D] text-[#ffffff] rounded-2xl"
                                    >
                                        {submitting
                                            ? "Generating..."
                                            : "Generate table number"}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal>
                <KitchenLayout pageIntro={"Settings"}>
                    <div className="flex items-center">
                        <div className="w-[80%]">
                            <InternalPageNav
                                data={[
                                    {
                                        link: "table-generation",
                                        title: "Table Generation",
                                    },
                                    {
                                        link: "menu-management",
                                        title: "Menu Management",
                                    },
                                    { link: "account", title: "Account" },
                                    { link: "profile", title: "Profile" },
                                    {
                                        link: "personalisation",
                                        title: "Personalisation",
                                    },
                                    { link: "support", title: "Support" },
                                ]}
                            />
                        </div>
                        <div className="w-[20%] flex gap-2 items-center">
                            <div className=" w-1/2">
                                <Button
                                    btnType="primary"
                                    value={"Generate"}
                                    onClick={() =>
                                        setModal((prevState) => !prevState)
                                    }
                                />
                            </div>
                            <Link
                                to={"/kitchen/settings/table-download"}
                                className=" w-1/2"
                            >
                                <Button
                                    btnType="secondary"
                                    value={"Download"}
                                />
                            </Link>
                        </div>
                    </div>

                    <section className="mt-8 flex mb-12 w-full">
                        {tableList?.tables?.length === 0 && (
                            <div className="w-full h-[594px] bg-white flex rounded-[32px] justify-center items-center">
                                <div className="flex flex-col items-center">
                                    <img
                                        alt="empty-state"
                                        src="/images/Tickets.png"
                                    />

                                    <p className="text-[#808080] font-light mb-1">
                                        No table has been created
                                    </p>
                                    <button
                                        onClick={() => setModal(true)}
                                        className="text-[#F2650D] font-semibold cursor-pointer"
                                    >
                                        Generate Tables
                                    </button>
                                </div>
                            </div>
                        )}
                        {/* <div className="w-full flex gap-6"></div> */}
                        {!tableList && (
                            <div className="flex gap-4  w-full justify-between flex-wrap">
                                <SkeletonLoader width={"30%"} height={305} />
                                <SkeletonLoader width={"30%"} height={305} />
                                <SkeletonLoader width={"30%"} height={305} />
                            </div>
                        )}
                        {/* <div > */}
                        {tableList?.tables?.length > 0 && (
                            <div className="w-full grid grid-cols-2 md:grid-cols-3 gap-6 text-center">
                                {tableList?.tables?.map((tableData) => (
                                    <TagsContainer
                                        id={tableData.id}
                                        resturantName={business.businessName}
                                        tableNumber={tableData.tableNumber}
                                    />
                                ))}
                            </div>
                        )}
                        {/* </div> */}
                    </section>
                </KitchenLayout>
            </div>
        </>
    );
}

export default TableGeneration;
