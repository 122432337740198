import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const getTokenFromStorage = () => {
    return localStorage.getItem("token");
};

export const userApi = createApi({
    reducerPath: "userApi",
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_SERVER_URL }),
    endpoints: (builder) => ({
        getUserProfile: builder.query({
            query: () => ({
                url: `user/get-profile`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${getTokenFromStorage()}`,
                },
            }),
        }),
    }),
});

export const { useGetUserProfileQuery } = userApi;
