import React from "react";

function InfoList({ title, caption }) {
    return (
        <div className="">
            <p className="font-normal text-sm text-[#B3B3B3]">{title}</p>
            <p className="font-light text-base text-[#000]">{caption}</p>
        </div>
    );
}

export default InfoList;
