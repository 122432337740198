import React from "react";
import { Link, useHref } from "react-router-dom";

function InternalPageNav({ data }) {
    return (
        <nav className="h-14">
            {data.map((item) => (
                <NavItem key={item.title} link={item.link} title={item.title} />
            ))}
        </nav>
    );
}

export default InternalPageNav;

function NavItem({ title, link }) {
    const router = useHref();
    // console.log(router);
    return (
        <Link
            className={`font-Kanit font-light p-4 w-auto border-b h-14 ${
                router.includes(link)
                    ? "text-[#F2650D] border-[#F2650D]"
                    : "text-[#808080] border-[#808080] "
            }`}
            to={`/kitchen/settings/${link}`}
        >
            {title}
        </Link>
    );
}
