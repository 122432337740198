import React, { useEffect, useState } from "react";
import KitchenLayout from "../../../components/Layout/kitchenLayout";
import { ChevronLeft, ChevronRight, DownVector } from "../../../icons";
import tableData, { orderData } from "../../../components/data";
import { Monitor, ModalTable, DetailsTab } from "../Table-monitor";
import Head from "../../../components/header/head";
import Modal from "../../../components/Organisms/Modal";
import { ShowErrorMessage, formatCurrency } from "../../../utils";
import { getOrderbyid, getOrders } from "../../../service/request";
import dayjs from "dayjs";
import usePagination from "../../../hooks/pagination.jsx";
import Select from "../../../components/Common/Select";

function Order() {
    const data = tableData;
    const style = "list";
    const [show, setShow] = useState(false);
    const [order, setOrder] = useState([]);
    const [orderItem, setOrderItem] = useState({});

    function transformString(str) {
        if (str.length <= 5) {
            return `fsw${str}`;
        }
        const newStr = str.slice(5);
        return `fsw${newStr}`;
    }

    useEffect(() => {
        async function getOrder() {
            try {
                const { data } = await getOrders();
                setOrder(data);
                console.log(data);
            } catch (error) {
                console.log(error);
                if (error?.response?.data?.message) {
                    ShowErrorMessage(error?.response?.data?.message);
                } else {
                    ShowErrorMessage("Error Occured Check Internet Connection");
                }
            }
        }
        getOrder();
    }, []);

    const {
        currentPage,
        setCurrentpage,
        itemsPerpage,
        currentItems,
        totalPages,
        setItemsPerPage,
        handlePageChange,
        handleNextPage,
        handlePrevPage,
    } = usePagination(order?.orders);

    {
        const handleItemsPerPageChange = (e) => {
            setItemsPerPage(Number(e.target.value));
            setCurrentpage(1);
        };
    }

    const renderPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    className={
                        currentPage === i
                            ? "border rounded-lg h-8 w-8 flex items-center justify-center bg-[#F2650D] text-white"
                            : "rounded-lg h-8 w-8 flex items-center justify-center text-black"
                    }
                >
                    {i}
                </button>
            );
        }
        return pageNumbers;
    };

    async function getOrderid(id) {
        try {
            const { data } = await getOrderbyid(id);
            setOrderItem(data.order);
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.message) {
                ShowErrorMessage(error?.response?.data?.message);
            } else {
                ShowErrorMessage("Error Occured Check Internet Connection");
            }
        }
    }

    return (
        <div className="w-full">
            <Head
                pageTitle={"Foodswiipe Kitchen | Order"}
                metaDescription={"Foodswiipe Kitchen | Order"}
            />
            <KitchenLayout pageIntro={"Orders"}>
                <Modal title={"Order Details"} show={show} setShow={setShow}>
                    <div className=" w-full flex flex-col mt-6 gap-8">
                        <div className="w-full flex flex-col gap-4">
                            <div className="w-full flex flex-col gap-4 p-4 rounded-2xl border border-solid border-[rgba(0, 0, 0, 0.10)] ">
                                <div className=" w-full flex justify-between items-center gap-4">
                                    {orderItem?.orderitems?.map((order) => {
                                        return (
                                            <DetailsTab
                                                title={"Table Number"}
                                                info={order.table.tableNumber}
                                            />
                                        );
                                    })}
                                    <DetailsTab
                                        title={"Payment Method"}
                                        info={"Card Payment"}
                                    />

                                    {orderItem?.orderitems?.map((order) => {
                                        return (
                                            <DetailsTab
                                                title={"Order Amount"}
                                                info={formatCurrency(
                                                    orderItem.total
                                                )}
                                            />
                                        );
                                    })}
                                </div>

                                <div className=" w-full flex justify-between items-center gap-4">
                                    {orderItem?.orderitems?.map((order) => {
                                        return (
                                            <DetailsTab
                                                title={"Time of Order"}
                                                info={dayjs(
                                                    order.createdAt
                                                ).format(
                                                    "DD MMM, YYYY HH:mm A"
                                                )}
                                            />
                                        );
                                    })}
                                    {orderItem?.orderitems?.map((order) => {
                                        return (
                                            <DetailsTab
                                                title={"Payment Status"}
                                                info={orderItem.status}
                                            />
                                        );
                                    })}
                                    {orderItem?.orderitems?.map((order) => {
                                        return (
                                            <DetailsTab
                                                title={"Order Reference"}
                                                info={`FSW-${order.id
                                                    .slice(0, 7)
                                                    .toUpperCase()}`}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="w-full p-4 flex justify-between items-center bg-[#FFF7F5] rounded-2xl border border-solid border-[#FFCDBC]">
                                <div className="flex items-center justify-center bg-[#FFF] gap-2 p-2 rounded-3xl border border-solid border-[rgba(0, 0, 0, 0.10)]">
                                    <div
                                        className={
                                            orderItem === "pending"
                                                ? "w-6 h-6 bg-[#D2DBF9]  rounded-full  text-[#204BE1]"
                                                : orderItem === "in-progress"
                                                ? "w-6 h-6 bg-[#FFF4E1]  rounded-full  text-[#E69602]"
                                                : orderItem === "fulfilled"
                                                ? "w-6 h-6 bg-[#D2F9D6] rounded-full text-[#20E120]"
                                                : orderItem === "cancelled"
                                                ? "w-6 h-6 bg-[#F9D2D2] rounded-full text-[#E12020]"
                                                : ""
                                        }
                                    ></div>
                                    <p className="font-light">
                                        {orderItem.status}
                                    </p>
                                </div>
                                <input
                                    type="text"
                                    placeholder="How log will this take?"
                                    className="text-[#cccccc] bg-[#f5f5f5] px-4 py-2 border border-solid border-[rgba(0, 0, 0, 0.10)] rounded-2xl font-light"
                                />
                                <button className="bg-[#F2650D] rounded-xl text-white font-medium flex items-center justify-center px-4 py-2">
                                    Update
                                </button>
                            </div>
                            <div className=" w-full flex flex-col gap-2 p-4 rounded-2xl bg-[#FFCDBC] ">
                                <p className="text-[#F2650D] font-medium">
                                    Customer Note
                                </p>
                                <p className="text-[#333333] font-light text-sm">
                                    Lorem ipsum dolor sit amet consectetur.
                                    Nulla ac nec vitae tellus tortor vestibulum
                                    morbi. Varius blandit in aliquam id nam nisl
                                    vestibulum lorem. Magna sit.
                                </p>
                            </div>
                        </div>
                        <div className=" bg-[#ffffff] w-full font-medium border-b border-solid border-[rgba(0, 0, 0, 0.10)] text-sm h-fit py-3 flex items-center">
                            <p className=" w-[15%]">Qty</p>
                            <p className=" w-[60%]">Description</p>
                            <p className=" w-[10%]">Price</p>
                            <p className="w-[15%] self-end">Amount</p>
                        </div>
                        {orderItem?.orderitems?.map((order) => {
                            return (
                                <ModalTable
                                    qty={order.quantity}
                                    desc={order.food.name}
                                    price={order.price}
                                    amount={order.quantity * order.price}
                                />
                            );
                        })}
                        <div className=" bg-[#ffffff] w-full font-medium text-sm h-fit py-3 flex justify-between items-center">
                            <p className=" w-[15%]">Total</p>
                            <p className="w-[15%] self-end">
                                {formatCurrency(orderItem.total)}
                            </p>
                        </div>
                    </div>
                </Modal>
                <div className=" flex flex-col gap-6 bg-[#f7f7f7] w-full">
                    <div className=" flex justify-between items-center w-full gap-4">
                        <div></div>
                        <div className=" flex gap-4 justify-between items-center">
                            <p>Filter</p>
                            <div className=" flex gap-1 bg-[#ffffff] rounded-lg p-4 w-44 h-14 justify-between items-center border border-solid border-[#F2f2f2]">
                                <p>All Orders</p>
                                <DownVector />
                            </div>
                            <div className=" flex gap-1 bg-[#ffffff] rounded-lg p-4 w-44 h-14 justify-between items-center border border-solid border-[#F2f2f2]">
                                <p>All Status</p>
                                <DownVector />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full flex flex-col justify-center items-center gap-[2px]">
                    <div className=" bg-[#ffffff] w-full font-medium mt-6 text-sm h-fit py-3 flex items-center px-6 gap-1 rounded-t-[32px]">
                        <p className=" w-[15%]">Table No</p>
                        <p className=" w-[20%]">Time Of Order</p>
                        <p className=" w-[20%]">Order Reference</p>
                        <p className=" w-[15%]">Amount</p>
                        <p className=" w-[10%]">Status</p>
                        <p className="w-[15%">Action</p>
                    </div>
                    {currentItems?.map((detail, index) => {
                        return (
                            <Monitor
                                tableNo={
                                    order.orders[index].orderitems[0].table
                                        .tableNumber
                                }
                                lastSeen={detail.lastSeen}
                                orderRef={`FSW-${detail.id
                                    .slice(0, 7)
                                    .toUpperCase()}`}
                                orderStatus={detail.status}
                                timeOfOrder={dayjs(detail.createdAt).format(
                                    "DD MMM, YYYY HH:mmA"
                                )}
                                display={style}
                                id={detail.id}
                                expandFunction={() => {
                                    setShow(true);
                                    getOrderid(detail.id);
                                }}
                                amount={formatCurrency(detail.total)}
                            />
                        );
                    })}
                    <div className=" bg-[#ffffff] w-full flex justify-between items-center p-[36px] rounded-b-[32px] mb-[6px">
                        <div className="flex justify-center items-center gap-2">
                            <button
                                onClick={() => setCurrentpage(currentPage - 1)}
                                disabled={currentPage === 1}
                                className="bg-[#FFECE5] rounded-lg h-8 w-8 flex items-center justify-center"
                            >
                                <ChevronLeft />
                            </button>

                            {renderPageNumbers()}
                            <button
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages}
                                className="bg-[#FFECE5] rounded-lg h-8 w-8 flex items-center justify-center"
                            >
                                <ChevronRight />
                            </button>
                        </div>

                        <div className="flex justify-center gap-1 items-center">
                            <p className="text-xs">No of Entries</p>
                            <Select
                                name={"page"}
                                value={itemsPerpage}
                                onChange={(e) =>
                                    setItemsPerPage(Number(e.target.value))
                                }
                                className="text-xs bg-white flex gap-2 justify-center items-center border rounded-lg border-[#F2650D] p-2 bg-[position:calc(100%_-_5px)_center]
                                "
                            >
                                <option value={10} selected className="text-xs">
                                    10 per page
                                </option>
                                <option value={20}>20 per page</option>
                                <option value={50}>50 per page</option>
                                <option value={100}>100 per page</option>
                            </Select>
                        </div>
                    </div>
                </div>
            </KitchenLayout>
        </div>
    );
}

export default Order;

export function Orders({
    style,
    tableNo,
    lastSeen,
    timeOfOrder,
    orderRef,
    orderStatus,
    amount,
}) {
    return (
        <div
            className={
                style === "grid" ? "w-1/4" : style === "list" ? "w-full" : null
            }
        >
            {style === "grid" ? (
                <div className=" w-full h-36 bg-[#FFFFFF] border border-solid border-[rgba(17, 17, 17, 0.05)] p-6 flex flex-col rounded-[32px] justify-between items-center">
                    <div className=" w-full flex flex-col justify-center items-center">
                        <div className=" w-full flex justify-between items-center">
                            <p className=" w-3/4 text-[#111111]">{tableNo} </p>
                            <div
                                className={
                                    orderStatus === "fulfilled"
                                        ? "bg-[#39CE05] h-6 w-6 rounded-full border border-solid border-[#237805]"
                                        : orderStatus === "cancelled"
                                        ? " bg-[#FA360B] h-6 w-6 rounded-full border border-solid border-red-700"
                                        : orderStatus === "pending"
                                        ? "w-fit h-6 bg-[#D2DBF9] font-normal text-xs rounded-xl px-2 py-1 flex items-center justify-center text-[#204BE1]"
                                        : orderStatus === "in-progress"
                                        ? "w-fit h-6 bg-[#FFF4E1] font-normal text-xs rounded-xl px-2 py-1 flex items-center justify-center text-[#E69602]"
                                        : ""
                                }
                            ></div>
                        </div>
                        <p className=" w-full text-[#111111] opacity-50 text-xs font-light">
                            {lastSeen}{" "}
                        </p>
                    </div>
                    <div className=" w-full flex justify-between items-center">
                        <p className=" text-sm">Last Order</p>
                        <div className=" flex items-center gap-3">
                            <p className=" text-[#F2650D] text-sm">Expand</p>
                            <svg
                                width="8"
                                height="14"
                                viewBox="0 0 8 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1 13L7 7L1 1"
                                    stroke="#F2650D"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            ) : style === "list" ? (
                <div className=" bg-[#ffffff] w-full flex font-light text-sm items-center gap-1 px-6 h-fit py-3">
                    <p className=" w-[15%]">{tableNo}</p>
                    <p className=" w-[20%]">{timeOfOrder}</p>
                    <p className=" w-[20%]">{orderRef}</p>
                    <p className=" w-[15%]">{amount}</p>
                    <div className=" w-[10%]">
                        <p
                            className={
                                orderStatus === "fulfilled"
                                    ? "w-fit h-6 bg-[#C7FDB4] font-normal text-xs rounded-xl px-2 py-1 flex items-center justify-center text-[#2B9F02]"
                                    : orderStatus === "cancelled"
                                    ? "w-fit h-6 bg-[#FA360B] font-normal text-xs rounded-xl px-2 py-1 flex items-center justify-center text-red-700 "
                                    : orderStatus === "pending"
                                    ? "w-fit h-6 bg-[#D2DBF9] font-normal text-xs rounded-xl px-2 py-1 flex items-center justify-center text-[#204BE1]"
                                    : orderStatus === "in-progress"
                                    ? "w-fit h-6 bg-[#FFF4E1] font-normal text-xs rounded-xl px-2 py-1 flex items-center justify-center text-[#E69602]"
                                    : ""
                            }
                        >
                            Label
                        </p>
                    </div>
                    <div className="w-[15%] flex items-center gap-4">
                        <p className=" font-light text-sm text-[#F2650D]">
                            View Order
                        </p>
                        <svg
                            width="8"
                            height="14"
                            viewBox="0 0 8 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1 13L7 7L1 1"
                                stroke="#F2650D"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                </div>
            ) : null}
        </div>
    );
}
