import NavBar from "./navbar";
import SidePanel from "./sidePanel";

function KitchenLayout({ pageIntro, name, children }) {
    return (
        <div className="w-full flex bg-[#F7F7F7] h-full">
            <SidePanel />
            <div className=" w-full bg-[#F7F7F7] min-h-screen h-full pt-[53px] pl-[3.41%] pr-10 xl:pr-[10.77%] gap-16 flex flex-col ml-[16.4%]">
                <NavBar pageIntro={pageIntro} name={name} />
                <div className=" bg-[#F7F7F7] h-full mb-32">{children}</div>
            </div>
        </div>
    );
}

export default KitchenLayout;
