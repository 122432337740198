import { useState } from "react";

const usePagination = (data, itemsPerPageDefault = 10) => {
    const [currentPage, setCurrentpage] = useState(1);
    const [itemsPerpage, setItemsPerPage] = useState(itemsPerPageDefault);

    const indexOfLastItem = currentPage * itemsPerpage;
    const indexOfFirstItem = indexOfLastItem - itemsPerpage;
    const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(data?.length / itemsPerpage);

    const handlePageChange = (pageNumber) => {
        setCurrentpage(pageNumber);
    };
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentpage(currentPage + 1);
        }
    };
    const handlePrevPage = () => {
        if (currentPage > totalPages) {
            setCurrentpage(currentPage - 1);
        }
    };

    return {
        currentPage,
        setCurrentpage,
        itemsPerpage,
        currentItems,
        totalPages,
        setItemsPerPage,
        handlePageChange,
        handleNextPage,
        handlePrevPage,
    };
};

export default usePagination;
