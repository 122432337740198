import { createSlice } from "@reduxjs/toolkit";
import { businessApi } from "../../service/rtk-business-query-service";

const initialState = {
    id: null,
    businessName: null,
    menuUrl: null,
    businessPhone: null,
    businessEmail: null,
    businessAddress: null,
    businessAddressCity: null,
    businessAddressState: null,
    businessAddressCountry: null,
    businessLogoUrl: null,
    currency: null,
};

export const businessSlice = createSlice({
    name: "business",
    initialState,
    reducers: {
        updateBusiness: (state, action) => {
            return { ...state, ...action.payload };
        },
        logoutBusiness: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            businessApi.endpoints.getBusinessProfile.matchFulfilled,
            (state, { payload }) => {
                return payload.user;
            }
        );
    },
});

export const { updateBusiness, logoutBusiness } = businessSlice.actions;
export const selectBusiness = (state) => state.business;

// export const businessReducer = businessSlice.reducer;
export default businessSlice.reducer;
