import React from "react";
import ContentLoader from "react-content-loader";

function SkeletonLoader({ height, width }) {
    return (
        <ContentLoader
            style={{
                borderRadius: 20,
                boxShadow: "3px 5px 10px -10px rgba(0,0,0,0.65)",
                // borderWidth: 1,
                width: width,
                border: "1px solid #f4f4f4",
            }}
            speed={2}
            width={width}
            height={height}
            // viewBox="0 0 400 160"
            backgroundColor="#fcfcfc"
            foregroundColor="#ecebeb"
        >
            <rect width={"100%"} height={height} />
        </ContentLoader>
    );
}

export default SkeletonLoader;
