import "./App.css";
import React from "react";
import { Outlet } from "react-router-dom";
import { ScrollRestoration } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
    return (
        <div className=" font-Kanit">
            <ScrollRestoration />
            <Outlet />
            <ToastContainer />
        </div>
    );
}

export default App;
