import React, { useState, useEffect } from "react";
import KitchenLayout from "../../../components/Layout/kitchenLayout";
import Button from "../../../components/Common/Button";
import InternalPageNav from "../../../components/Organisms/InternalPageNav";
import Modal from "../../../components/Organisms/Modal";
import MenuCard from "../../../components/Organisms/MenuCard";
import Head from "../../../components/header/head";
import { ShowErrorMessage } from "../../../utils";
import { getCategories, getFoods } from "../../../service/request";
import SkeletonLoader from "../../../components/Organisms/SkeletonLoader";
import AddMenu from "../../../components/Organisms/Modals/Menu-Managment/AddMenu";
import EditMenu from "../../../components/Organisms/Modals/Menu-Managment/EditMenu";
import { useSelector } from "react-redux";

function MenuManagement() {
    const [modal, setModal] = useState(false);
    const [editmodal, setEditModal] = useState(false);
    const [display, setDisplay] = useState("list");
    const [categories, setCategories] = useState([]);
    const [food, setFood] = useState();
    const [refresh, setRefresh] = useState(false);
    const [menuItem, setMenuItem] = useState([]);
    const business = useSelector((state) => state.business);

    const activeStyle =
        "h-14 w-14 bg-[#FDF0EB] border border-solid border-[#F5853F] rounded-lg flex justify-center items-center";
    const inactiveStyle =
        "h-14 w-14 bg-[#FFFFFF] rounded-lg flex justify-center items-center cursor-pointer";

    useEffect(
        () =>
            async function getAllCategories() {
                try {
                    const { data } = await getCategories(business.id);
                    const mappedData = data.data.map((item) => {
                        return {
                            value: item.id,
                            label: item.name,
                            name: item.name,
                        };
                    });

                    setCategories(mappedData);
                } catch (error) {
                    console.log(error);
                }
            },
        [refresh, business.id]
    );

    useEffect(() => {
        async function getFood() {
            try {
                const { data } = await getFoods(business.id);
                setFood(data.data);
            } catch (error) {
                console.log(error);

                if (error?.response?.data?.message) {
                    ShowErrorMessage(error?.response?.data?.message);
                } else {
                    ShowErrorMessage("Error Occured Check Internet Connection");
                }
            }
        }
        getFood();
    }, [refresh, business.id]);

    return (
        <>
            <Head
                pageTitle={"Foodswipe Kitchen | Menu Managment"}
                metaDescription={"Foodswiipe Kitchen"}
            />
            <Modal
                width={"w-[512px]"}
                title={"Food Upload"}
                show={modal}
                setShow={setModal}
            >
                <AddMenu
                    setRefresh={setRefresh}
                    setModal={setModal}
                    setCategories={setCategories}
                    categories={categories}
                />
            </Modal>
            <Modal
                width={"w-[512px]"}
                title={"Edit Food"}
                show={editmodal}
                setShow={setEditModal}
            >
                <EditMenu
                    setRefresh={setRefresh}
                    setModal={setEditModal}
                    setCategories={setCategories}
                    categories={categories}
                    menuItem={menuItem}
                />
            </Modal>
            <KitchenLayout pageIntro={"Generator"}>
                <div className="flex items-end justify-between">
                    <div className="w-[90%]">
                        <InternalPageNav
                            data={[
                                {
                                    link: "table-generation",
                                    title: "Table Generation",
                                },
                                {
                                    link: "menu-management",
                                    title: "Menu Management",
                                },
                                { link: "account", title: "Account" },
                                { link: "profile", title: "Profile" },
                                {
                                    link: "personalisation",
                                    title: "Personalisation",
                                },
                                { link: "support", title: "Support" },
                            ]}
                        />
                    </div>
                    <div className="w-[20%]">
                        <Button
                            btnType="primary"
                            value={"Add Food"}
                            onClick={() => setModal((prevState) => !prevState)}
                        />
                    </div>
                </div>

                <section className="mt-8 w-full flex flex-col mb-12">
                    <div className=" flex items-center gap-4 mb-8">
                        <div
                            className={
                                display === "list" ? activeStyle : inactiveStyle
                            }
                            onClick={() => setDisplay("list")}
                        >
                            <img
                                src={
                                    display === "list"
                                        ? "../../../../images/list-active.svg"
                                        : "../../../../images/list-inactive.svg"
                                }
                                alt="list"
                            />
                        </div>
                        <div
                            className={
                                display === "grid" ? activeStyle : inactiveStyle
                            }
                            onClick={() => setDisplay("grid")}
                        >
                            <img
                                src={
                                    display === "grid"
                                        ? "../../../../images/grid-active-colored.svg"
                                        : "../../../../images/grid-active.svg"
                                }
                                alt="grid"
                            />
                        </div>
                    </div>
                    <div className="w-full flex gap-6">
                        {food?.length === 0 && (
                            <EmptyState
                                handleAddfood={() =>
                                    setModal((prevState) => !prevState)
                                }
                            />
                        )}
                        {!food && (
                            <div className="flex gap-4 w-full justify-between flex-wrap">
                                <SkeletonLoader width={"30%"} height={376} />
                                <SkeletonLoader width={"30%"} height={376} />
                                <SkeletonLoader width={"30%"} height={376} />
                            </div>
                        )}
                    </div>
                    <div className="w-full grid grid-cols-2 md:grid-cols-3 gap-6 text-center">
                        {food?.length > 0 &&
                            food?.map((singleFood) => (
                                <MenuCard
                                    item={singleFood}
                                    setMenuItem={setMenuItem}
                                    setRefresh={setRefresh}
                                    setEditModal={setEditModal}
                                    id={singleFood?.id}
                                    title={singleFood?.name}
                                    image={singleFood?.imageUrl}
                                    price={singleFood?.price}
                                    portion={singleFood?.portion}
                                />
                            ))}
                    </div>
                </section>
            </KitchenLayout>
        </>
    );
}

export default MenuManagement;

function EmptyState({ handleAddfood }) {
    return (
        <div className="flex bg-white rounded-3xl flex-col items-center justify-center w-[320px] h-[376px]">
            <img alt="empty-state" src="/images/Tickets.png" />

            <p className="text-[#808080] font-light mb-1">No food added yet</p>
            <button
                onClick={handleAddfood}
                className="text-[#F2650D] font-semibold cursor-pointer"
            >
                Add Food
            </button>
        </div>
    );
}
