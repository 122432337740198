import React from "react";

function FoodSwipeIcon() {
    return (
        <svg
            width="110"
            height="54"
            viewBox="0 0 110 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="Union">
                <path
                    d="M108.913 9.04353C109.376 9.54586 109.344 10.3284 108.842 10.7914L100.656 18.3364C100.153 18.7994 99.3709 18.7675 98.9079 18.2652C98.4449 17.7629 98.4768 16.9803 98.9791 16.5173L104.528 11.4029C103.4 11.5852 102.062 11.8809 100.613 12.3591C96.4548 13.7315 91.4037 16.598 87.7616 22.6104C87.4077 23.1947 86.647 23.3815 86.0627 23.0275C85.4784 22.6736 85.2917 21.913 85.6456 21.3287C89.6624 14.6977 95.2612 11.5203 99.8379 10.0098C101.968 9.30664 103.881 8.96275 105.32 8.79574L99.5488 2.53429C99.0858 2.03196 99.1177 1.24941 99.62 0.786408C100.122 0.323409 100.905 0.355291 101.368 0.857619L108.913 9.04353Z"
                    fill="#F5853F"
                />
                <path
                    d="M40.3451 7.21531C40.5727 6.82521 40.6865 6.41885 40.6865 5.99625C40.6865 5.24856 40.4589 4.87471 40.0038 4.87471C39.3049 4.87471 38.8091 5.58989 38.5165 7.02026L38.1508 8.92199H40.3695L40.2232 10.0191H37.9314L35.7371 21.3564L30.8121 21.844L33.1527 10.0191H31.4216L31.641 8.92199H33.3477L33.8597 6.99588C34.1523 5.80932 34.9244 4.93973 36.1759 4.38709C37.0862 3.99699 38.3052 3.80194 39.8331 3.80194C41.361 3.80194 42.4663 3.99699 43.149 4.38709C43.8316 4.77719 44.173 5.2323 44.173 5.75243C44.173 6.27257 43.9698 6.69517 43.5634 7.02026C43.1571 7.34534 42.7264 7.50788 42.2712 7.50788C41.3773 7.50788 40.7352 7.41036 40.3451 7.21531Z"
                    fill="#F5853F"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M45.2707 21.844C41.6948 21.844 39.9068 20.1617 39.9068 16.7971C39.9068 14.4078 40.5651 12.441 41.8817 10.8969C43.2796 9.2552 45.1894 8.43437 47.6113 8.43437C49.3667 8.43437 50.6915 8.84072 51.5854 9.65343C52.4794 10.4661 52.9264 11.7258 52.9264 13.4325C52.9264 16.0332 52.2275 18.0893 50.8296 19.601C49.4643 21.0963 47.6113 21.844 45.2707 21.844ZM45.9046 11.2382C45.7096 11.6771 45.5308 12.2297 45.3682 12.8961C45.2219 13.5463 45.0513 14.3915 44.8562 15.4318C44.6612 16.472 44.5636 17.6342 44.5636 18.9183C44.5636 19.3409 44.6287 19.6903 44.7587 19.9667C44.905 20.243 45.165 20.3812 45.5389 20.3812C45.9127 20.3812 46.2134 20.2918 46.441 20.113C46.6848 19.9342 46.8961 19.6335 47.0749 19.2109C47.4 18.4632 47.6926 17.3985 47.9526 16.0169C48.2127 14.6191 48.3509 13.6194 48.3671 13.018C48.3996 12.4166 48.4159 11.8965 48.4159 11.4576C48.4159 11.0025 48.3509 10.6287 48.2208 10.3361C48.0908 10.0435 47.8389 9.89724 47.465 9.89724C47.1074 9.89724 46.8067 10.011 46.5629 10.2386C46.3191 10.4661 46.0997 10.7993 45.9046 11.2382Z"
                    fill="#F5853F"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M54.3117 16.7971C54.3117 20.1617 56.0997 21.844 59.6756 21.844C62.0162 21.844 63.8692 21.0963 65.2345 19.601C66.6324 18.0893 67.3313 16.0332 67.3313 13.4325C67.3313 11.7258 66.8843 10.4661 65.9903 9.65343C65.0964 8.84072 63.7716 8.43437 62.0162 8.43437C59.5943 8.43437 57.6845 9.2552 56.2866 10.8969C54.97 12.441 54.3117 14.4078 54.3117 16.7971ZM59.7731 12.8961C59.9357 12.2297 60.1145 11.6771 60.3095 11.2382C60.5046 10.7993 60.724 10.4661 60.9678 10.2386C61.2116 10.011 61.5123 9.89724 61.8699 9.89724C62.2438 9.89724 62.4957 10.0435 62.6257 10.3361C62.7558 10.6287 62.8208 11.0025 62.8208 11.4576C62.8208 11.8965 62.8045 12.4166 62.772 13.018C62.7558 13.6194 62.6176 14.6191 62.3575 16.0169C62.0975 17.3985 61.8049 18.4632 61.4798 19.2109C61.301 19.6335 61.0897 19.9342 60.8459 20.113C60.6183 20.2918 60.3176 20.3812 59.9438 20.3812C59.57 20.3812 59.3099 20.243 59.1636 19.9667C59.0336 19.6903 58.9685 19.3409 58.9685 18.9183C58.9685 17.6342 59.0661 16.472 59.2611 15.4318C59.4562 14.3915 59.6268 13.5463 59.7731 12.8961Z"
                    fill="#F5853F"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M76.4455 8.43437C77.0306 8.43437 77.5508 8.49938 78.0059 8.62942C78.0221 8.5644 78.0465 8.44249 78.079 8.2637L78.2009 7.50788C78.2659 7.1828 78.3472 6.75206 78.4447 6.21568L78.8348 4.28956L83.6867 3.80194L80.9804 17.943C80.9641 18.0406 80.956 18.1706 80.956 18.3331V18.7232C80.956 19.0808 81.0454 19.3978 81.2242 19.6741C81.403 19.9342 81.6387 20.0642 81.9313 20.0642C81.6062 20.8281 80.9479 21.3727 79.9564 21.6977C79.615 21.7953 79.2006 21.844 78.7129 21.844C78.2416 21.844 77.7864 21.714 77.3476 21.4539C76.9087 21.2101 76.6243 20.8769 76.4942 20.4543C76.2342 20.8769 75.8441 21.2101 75.324 21.4539C74.8038 21.714 74.1618 21.844 73.3978 21.844C72.6501 21.844 71.9593 21.7465 71.3254 21.5514C70.7078 21.3564 70.2039 21.0476 69.8138 20.625C69.0986 19.796 68.741 18.4388 68.741 16.5533C68.741 14.1152 69.4562 12.1566 70.8866 10.6774C72.3169 9.18206 74.1699 8.43437 76.4455 8.43437ZM76.2748 9.89724C75.6734 9.89724 75.2102 10.2223 74.8851 10.8725C74.56 11.5227 74.2268 12.6523 73.8855 14.2615C73.5604 15.8706 73.3978 17.3985 73.3978 18.8451C73.3978 19.8366 73.6904 20.3324 74.2756 20.3324C74.7957 20.3324 75.2346 20.0723 75.5921 19.5522C75.966 19.0321 76.2261 18.3413 76.3723 17.4798L77.6889 10.3361C77.3476 10.0435 76.8762 9.89724 76.2748 9.89724Z"
                    fill="#F5853F"
                />
                <path
                    d="M68.9715 42.2537C70.5806 42.2537 71.6371 41.6442 72.141 40.4251C71.4746 40.3926 71.1414 39.9131 71.1414 38.9866C71.1414 38.564 71.2795 37.7513 71.5559 36.5485L73.0431 28.844L68.0937 29.3317L67.1916 34.0616C66.5902 36.6298 66.2895 38.5721 66.2895 39.8887C66.2895 40.3926 66.509 40.8965 66.9478 41.4003C67.4679 41.9692 68.1425 42.2537 68.9715 42.2537Z"
                    fill="#F5853F"
                />
                <path
                    d="M69.3128 24.4798C68.7927 24.8211 68.5326 25.3088 68.5326 25.9427C68.5326 26.5766 68.7683 27.0561 69.2396 27.3812C69.7273 27.7062 70.3531 27.8688 71.117 27.8688C71.8809 27.8688 72.5149 27.7062 73.0187 27.3812C73.5389 27.0561 73.7989 26.5766 73.7989 25.9427C73.7989 25.3088 73.5633 24.8211 73.0919 24.4798C72.6368 24.1385 72.0272 23.9678 71.2633 23.9678C70.4993 23.9678 69.8492 24.1385 69.3128 24.4798Z"
                    fill="#F5853F"
                />
                <path
                    d="M39.7264 30.502C39.9702 30.0143 40.0921 29.543 40.0921 29.0878C40.0921 28.6327 40.0677 28.2833 40.019 28.0395C39.9702 27.7794 39.889 27.5518 39.7752 27.3568C39.5314 26.9342 39.1656 26.7229 38.678 26.7229C38.0766 26.7229 37.5321 26.9423 37.0445 27.3812C36.5243 27.8363 36.2643 28.4214 36.2643 29.1366C36.2643 29.5917 36.4187 29.9981 36.7275 30.3557C37.0526 30.697 37.459 31.0302 37.9466 31.3553C38.4342 31.6804 38.9543 32.0055 39.507 32.3305C40.0596 32.6556 40.5798 33.0132 41.0674 33.4033C42.1889 34.2973 42.7497 35.3457 42.7497 36.5485C42.7497 37.3612 42.5302 38.117 42.0914 38.8159C41.6688 39.4986 41.0999 40.0919 40.3847 40.5958C38.8243 41.701 37.012 42.2537 34.9477 42.2537C33.2735 42.2537 32.0057 41.9855 31.1442 41.4491C30.2828 40.8965 29.852 40.2057 29.852 39.3767C29.852 37.8976 30.429 36.9711 31.5831 36.5972C31.9082 36.4835 32.3145 36.4266 32.8022 36.4266C33.306 36.4266 33.8424 36.5322 34.4113 36.7435C34.1512 37.41 34.0212 38.0439 34.0212 38.6453C34.0212 39.9456 34.4845 40.5958 35.4109 40.5958C36.0123 40.5958 36.5569 40.3763 37.0445 39.9375C37.5484 39.4986 37.8003 39.0354 37.8003 38.5477C37.8003 38.0439 37.6378 37.6131 37.3127 37.2555C37.0038 36.8979 36.6137 36.581 36.1424 36.3047C35.671 36.0121 35.159 35.7277 34.6064 35.4513C34.07 35.175 33.5661 34.8418 33.0947 34.4517C31.9894 33.5577 31.4368 32.3956 31.4368 30.9652C31.4368 30.0387 31.6644 29.2179 32.1195 28.5027C32.5746 27.7713 33.1679 27.1617 33.8993 26.6741C35.3622 25.6826 36.9876 25.1869 38.7755 25.1869C40.5798 25.1869 41.9126 25.455 42.7741 25.9914C43.6518 26.5278 44.0906 27.2511 44.0906 28.1614C44.0906 28.9578 43.7818 29.608 43.1642 30.1119C42.6278 30.5345 42.0345 30.7458 41.3843 30.7458C40.7342 30.7458 40.1815 30.6645 39.7264 30.502Z"
                    fill="#F5853F"
                />
                <path
                    d="M62.7872 37.6944C61.7144 39.1735 60.6579 40.3032 59.6177 41.0834C58.5936 41.8636 57.5452 42.2537 56.4725 42.2537C55.416 42.2537 54.7008 42.148 54.3269 41.9367V36.5729C53.4004 39.0435 52.1082 40.7583 50.4503 41.7173C49.8489 42.0749 49.2638 42.2537 48.6949 42.2537C47.6059 42.2537 46.8257 42.148 46.3543 41.9367C46.2405 38.2145 46.1348 35.8414 46.0373 34.8174C45.9398 33.7772 45.8504 32.9401 45.7691 32.3062C45.6066 31.0383 45.249 30.1444 44.6964 29.6242C45.3953 29.1041 46.2324 28.844 47.2076 28.844C49.0931 28.844 50.1659 30.0143 50.4259 32.3549C50.491 32.9076 50.5235 33.4765 50.5235 34.0616C50.5235 34.9393 50.4259 36.9061 50.2309 39.9619C50.621 39.7831 51.0192 39.3767 51.4256 38.7428C51.8482 38.0926 52.2302 37.3287 52.5715 36.451C53.3354 34.4192 53.7174 32.5256 53.7174 30.7701C53.7174 30.4938 53.6768 30.2094 53.5955 29.9168C53.5305 29.6242 53.4573 29.4373 53.3761 29.356C53.945 29.0147 54.6601 28.844 55.5216 28.844C56.3993 28.844 57.0414 29.1447 57.4477 29.7461C57.8703 30.3313 58.1385 31.1684 58.2523 32.2574C58.3823 33.639 58.4474 34.7443 58.4474 35.5732C58.4474 36.7273 58.3905 38.1576 58.2767 39.8643C59.0894 39.2792 59.829 38.0276 60.4954 36.1096C61.1618 34.1916 61.495 32.55 61.495 31.1846C61.495 30.5832 61.4462 30.1037 61.3487 29.7461C61.8851 29.1447 62.6165 28.844 63.543 28.844C64.1119 28.844 64.5995 28.9984 65.0059 29.3073C65.4285 29.6161 65.6398 30.0631 65.6398 30.6482C65.6398 31.2171 65.5504 31.8104 65.3716 32.4281C65.2091 33.0457 64.9896 33.6715 64.7133 34.3054C64.1769 35.5732 63.5349 36.7029 62.7872 37.6944Z"
                    fill="#F5853F"
                />
                <path
                    d="M80.0697 40.4251C79.5658 41.6442 78.5093 42.2537 76.9001 42.2537C76.0712 42.2537 75.3966 41.9692 74.8765 41.4003C74.4376 40.8965 74.2182 40.3926 74.2182 39.8887C74.2182 38.5721 74.5189 36.6298 75.1203 34.0616L76.0224 29.3317L80.9718 28.844L79.4845 36.5485C79.2082 37.7513 79.07 38.564 79.07 38.9866C79.07 39.9131 79.4032 40.3926 80.0697 40.4251Z"
                    fill="#F5853F"
                />
                <path
                    d="M76.4613 25.9427C76.4613 25.3088 76.7213 24.8211 77.2414 24.4798C77.7778 24.1385 78.428 23.9678 79.1919 23.9678C79.9559 23.9678 80.5654 24.1385 81.0205 24.4798C81.4919 24.8211 81.7276 25.3088 81.7276 25.9427C81.7276 26.5766 81.4675 27.0561 80.9474 27.3812C80.4435 27.7062 79.8096 27.8688 79.0457 27.8688C78.2817 27.8688 77.6559 27.7062 77.1683 27.3812C76.6969 27.0561 76.4613 26.5766 76.4613 25.9427Z"
                    fill="#F5853F"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M91.0703 41.6929C92.0131 41.3191 92.8258 40.7908 93.5085 40.1081C94.1911 39.4092 94.7194 38.5559 95.0932 37.5481C95.4671 36.5404 95.654 35.3294 95.654 33.9153C95.654 32.5012 95.2802 31.3309 94.5325 30.4044C93.6873 29.3804 92.4682 28.8684 90.8753 28.8684C89.4612 28.8684 88.4128 29.4454 87.7301 30.5995L88.0471 28.9172L84.0729 29.3317L80.7083 46.6423L85.6577 46.1547L86.4135 42.0099C86.7386 42.1724 87.3644 42.2537 88.2909 42.2537C89.2174 42.2537 90.1439 42.0668 91.0703 41.6929ZM87.852 40.8396C87.4294 40.8396 87.0474 40.6933 86.7061 40.4007L87.9983 33.257C88.3234 31.3228 88.9817 30.3557 89.9732 30.3557C90.2983 30.3557 90.5421 30.4776 90.7046 30.7214C90.8834 30.9652 90.9728 31.4691 90.9728 32.233C90.9728 32.997 90.9159 33.769 90.8022 34.5492C90.4121 37.2149 89.9732 38.9866 89.4856 39.8643C89.3068 40.1894 89.0955 40.4332 88.8517 40.5958C88.6078 40.7583 88.2746 40.8396 87.852 40.8396Z"
                    fill="#F5853F"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M106.998 37.6456C107.404 37.922 107.607 38.3689 107.607 38.9866C107.607 39.588 107.453 40.0838 107.144 40.4739C106.835 40.864 106.429 41.189 105.925 41.4491C104.885 41.9855 103.804 42.2537 102.682 42.2537C101.561 42.2537 100.667 42.1318 100 41.888C99.35 41.6442 98.8055 41.2947 98.3666 40.8396C97.5051 39.9781 97.0744 38.759 97.0744 37.1824C97.0744 34.728 97.7408 32.7531 99.0737 31.2578C100.504 29.6486 102.463 28.844 104.95 28.844C106.494 28.844 107.648 29.1691 108.412 29.8193C108.981 30.3069 109.265 30.9489 109.265 31.7454C109.265 34.6061 106.794 36.0365 101.853 36.0365C101.788 36.4591 101.756 36.8492 101.756 37.2068C101.756 37.9545 101.918 38.4746 102.243 38.7672C102.585 39.0435 103.064 39.1817 103.682 39.1817C104.299 39.1817 104.933 39.0435 105.583 38.7672C106.25 38.4746 106.721 38.1008 106.998 37.6456ZM102.024 34.9881C103.178 34.9881 104.088 34.6305 104.754 33.9153C105.421 33.2326 105.754 32.3468 105.754 31.2578C105.754 30.8839 105.681 30.5995 105.535 30.4044C105.405 30.1931 105.201 30.0875 104.925 30.0875C104.649 30.0875 104.389 30.1444 104.145 30.2581C103.917 30.3557 103.682 30.5832 103.438 30.9408C102.836 31.7535 102.365 33.1026 102.024 34.9881Z"
                    fill="#F5853F"
                />
                <path
                    d="M65.6084 47.9387H64.9763V46.9169H66.9938V51.6619H65.6084V47.9387Z"
                    fill="#F5853F"
                />
                <path
                    d="M65.5651 46.2502C65.6921 46.383 65.8855 46.4494 66.1452 46.4494C66.405 46.4494 66.5955 46.383 66.7167 46.2502C66.8437 46.1117 66.9072 45.9385 66.9072 45.7307C66.9072 45.5171 66.8437 45.3439 66.7167 45.2112C66.5955 45.0726 66.405 45.0034 66.1452 45.0034C65.8855 45.0034 65.6921 45.0726 65.5651 45.2112C65.4439 45.3439 65.3833 45.5171 65.3833 45.7307C65.3833 45.9385 65.4439 46.1117 65.5651 46.2502Z"
                    fill="#F5853F"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M30.5134 45.2891V51.7052H33.137C33.6334 51.7052 34.049 51.636 34.3838 51.4974C34.7186 51.3531 34.9726 51.1482 35.1458 50.8826C35.319 50.6113 35.4055 50.2881 35.4055 49.9129V49.7916C35.4055 49.3876 35.3074 49.0672 35.1112 48.8305C34.9618 48.6505 34.7708 48.5088 34.5379 48.4056C34.6538 48.3266 34.7554 48.2345 34.8427 48.1292C35.0448 47.8925 35.1458 47.5779 35.1458 47.1854V47.0555C35.1458 46.4898 34.9495 46.054 34.557 45.748C34.1702 45.4421 33.6132 45.2891 32.8859 45.2891H30.5134ZM31.8988 50.5969H33.2062C33.4718 50.5969 33.6709 50.5276 33.8037 50.3891C33.9364 50.2506 34.0028 50.0543 34.0028 49.8003C34.0028 49.5463 33.9364 49.3529 33.8037 49.2202C33.6709 49.0816 33.4718 49.0124 33.2062 49.0124H31.8988V50.5969ZM32.9724 47.9387H31.8988V46.3974H32.9724C33.2438 46.3974 33.44 46.4667 33.5612 46.6052C33.6825 46.7438 33.7431 46.9343 33.7431 47.1767C33.7431 47.4076 33.6825 47.5923 33.5612 47.7309C33.44 47.8694 33.2438 47.9387 32.9724 47.9387Z"
                    fill="#F5853F"
                />
                <path
                    d="M36.3786 53.5409V52.3633H37.3657C37.5158 52.3633 37.6428 52.3431 37.7467 52.3027C37.8506 52.268 37.9343 52.2074 37.9978 52.1208C38.0613 52.0343 38.1133 51.9159 38.1537 51.7658L38.2038 51.558H37.2965L35.8418 46.9169H37.2359L38.2915 50.4757H38.4647L39.3226 46.9169H40.6387L39.314 52.0429C39.2216 52.4124 39.0917 52.7068 38.9243 52.9261C38.7627 53.1455 38.5433 53.3013 38.2662 53.3937C37.9949 53.4918 37.6486 53.5409 37.2272 53.5409H36.3786Z"
                    fill="#F5853F"
                />
                <path
                    d="M42.6846 46.9169L44.1046 51.6619H46.3039L47.5335 46.9169H46.2L45.3279 50.5103H45.1231L44.1133 46.9169H42.6846Z"
                    fill="#F5853F"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M50.4167 51.8265C50.0126 51.8265 49.6547 51.7572 49.343 51.6186C49.0371 51.4801 48.7802 51.2954 48.5724 51.0645C48.3703 50.8278 48.2145 50.5652 48.1048 50.2765C48.0009 49.9821 47.949 49.682 47.949 49.376V49.2029C47.949 48.8854 48.0009 48.5823 48.1048 48.2937C48.2145 47.9993 48.3703 47.7366 48.5724 47.5057C48.7744 47.2748 49.0255 47.093 49.3257 46.9602C49.6316 46.8217 49.978 46.7524 50.3647 46.7524C50.8727 46.7524 51.2999 46.8679 51.6462 47.0988C51.9984 47.3239 52.2668 47.6212 52.4515 47.9906C52.6362 48.3543 52.7286 48.7526 52.7286 49.1855V49.6531H49.2628C49.2828 49.7938 49.3153 49.9236 49.3603 50.0427C49.4411 50.2506 49.5681 50.4122 49.7413 50.5276C49.9145 50.6431 50.1396 50.7008 50.4167 50.7008C50.6707 50.7008 50.8785 50.6517 51.0401 50.5536C51.2018 50.4555 51.3114 50.3343 51.3692 50.1899H52.642C52.5727 50.5074 52.4371 50.7903 52.235 51.0385C52.033 51.2867 51.779 51.4801 51.4731 51.6186C51.1671 51.7572 50.815 51.8265 50.4167 51.8265ZM49.369 48.5275C49.3269 48.6284 49.2951 48.741 49.2734 48.8652H51.4129C51.3929 48.7321 51.361 48.6109 51.3172 48.5015C51.2364 48.2995 51.1152 48.1465 50.9535 48.0426C50.7977 47.9329 50.6014 47.8781 50.3647 47.8781C50.1223 47.8781 49.9174 47.9329 49.75 48.0426C49.5826 48.1523 49.4556 48.3139 49.369 48.5275Z"
                    fill="#F5853F"
                />
                <path
                    d="M53.6466 46.9169V51.6619H55.032V48.8998C55.032 48.6343 55.1099 48.4207 55.2658 48.259C55.4274 48.0916 55.6381 48.0079 55.8979 48.0079C56.1519 48.0079 56.3539 48.0888 56.504 48.2504C56.6541 48.412 56.7291 48.6198 56.7291 48.8738V51.6619H58.1145V48.9517C58.1145 48.2186 57.9673 47.6731 57.6729 47.3152C57.3843 46.9516 56.9629 46.7697 56.4087 46.7697H56.3481C55.9845 46.7697 55.6756 46.8506 55.4216 47.0122C55.1677 47.1738 54.9772 47.4163 54.8502 47.7395C54.8083 47.8512 54.7737 47.9724 54.7463 48.1033V46.9169H53.6466Z"
                    fill="#F5853F"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M61.252 51.8178C60.9172 51.8178 60.6112 51.7572 60.3341 51.636C60.0628 51.5147 59.8262 51.3473 59.6241 51.1338C59.4221 50.9144 59.2662 50.6575 59.1565 50.3631C59.0526 50.0687 59.0007 49.7512 59.0007 49.4107V49.2115C59.0007 48.8709 59.0498 48.5534 59.1479 48.259C59.2518 47.9647 59.399 47.7078 59.5895 47.4884C59.7857 47.2633 60.0195 47.0901 60.2908 46.9689C60.5621 46.8419 60.8652 46.7784 61.2 46.7784C61.581 46.7784 61.91 46.8621 62.1871 47.0295C62.4072 47.1579 62.589 47.3372 62.7326 47.5676V45.3411H64.1267V51.6619H63.027V50.6479C62.9984 50.7173 62.9667 50.784 62.9318 50.848C62.7644 51.1655 62.5363 51.4079 62.2477 51.5753C61.9591 51.737 61.6272 51.8178 61.252 51.8178ZM61.5983 50.6575C61.8119 50.6575 62.0053 50.6113 62.1784 50.519C62.3574 50.4208 62.4988 50.2794 62.6027 50.0947C62.7124 49.91 62.7672 49.6877 62.7672 49.428V49.1076C62.7672 48.8536 62.7124 48.64 62.6027 48.4669C62.493 48.2937 62.3487 48.1609 62.1698 48.0686C61.9908 47.9762 61.7975 47.93 61.5896 47.93C61.3587 47.93 61.1509 47.9906 60.9662 48.1119C60.7873 48.2273 60.6458 48.3889 60.5419 48.5967C60.438 48.7988 60.3861 49.0354 60.3861 49.3068C60.3861 49.5838 60.438 49.8234 60.5419 50.0254C60.6458 50.2275 60.7902 50.3833 60.9749 50.493C61.1596 50.6027 61.3674 50.6575 61.5983 50.6575Z"
                    fill="#F5853F"
                />
                <path
                    d="M67.8682 47.9387H68.5003V51.6619H69.8857V46.9169H67.8682V47.9387Z"
                    fill="#F5853F"
                />
                <path
                    d="M69.0371 46.4494C68.7774 46.4494 68.584 46.383 68.457 46.2502C68.3358 46.1117 68.2751 45.9385 68.2751 45.7307C68.2751 45.5171 68.3358 45.3439 68.457 45.2112C68.584 45.0726 68.7774 45.0034 69.0371 45.0034C69.2969 45.0034 69.4874 45.0726 69.6086 45.2112C69.7356 45.3439 69.7991 45.5171 69.7991 45.7307C69.7991 45.9385 69.7356 46.1117 69.6086 46.2502C69.4874 46.383 69.2969 46.4494 69.0371 46.4494Z"
                    fill="#F5853F"
                />
                <path
                    d="M72.1628 51.5494C72.4572 51.6648 72.8439 51.7225 73.3231 51.7225H73.9898V50.5536H73.2884C73.0691 50.5536 72.8988 50.4959 72.7775 50.3804C72.6621 50.2592 72.6044 50.086 72.6044 49.8609V47.93H73.9898V46.9169H72.6044V45.6354H71.3142V46.9169H70.5956V47.93H71.3142V49.809C71.3142 50.2881 71.3806 50.6691 71.5134 50.9519C71.6519 51.229 71.8684 51.4282 72.1628 51.5494Z"
                    fill="#F5853F"
                />
                <path
                    d="M8.96927 7.66195L1.09728 15.5339C0.614219 16.017 0.614219 16.8002 1.09728 17.2833L8.96927 25.1552C9.45233 25.6383 10.2355 25.6383 10.7186 25.1552C11.2017 24.6722 11.2017 23.889 10.7186 23.4059L5.06103 17.7484C9.57559 18.0697 13.5193 19.197 17.5311 21.8534C22.1148 24.8886 25.6107 30.7185 25.6107 36.2022C25.6107 36.8854 26.1645 37.4392 26.8477 37.4392C27.5309 37.4392 28.0847 36.8854 28.0847 36.2022C28.0847 29.7863 24.0827 23.2245 18.8969 19.7907C14.3415 16.7742 9.85866 15.5657 4.87323 15.2566L10.7186 9.41128C11.2017 8.92822 11.2017 8.14502 10.7186 7.66195C10.2355 7.17889 9.45233 7.17889 8.96927 7.66195Z"
                    fill="#F5853F"
                />
            </g>
        </svg>
    );
}

export default FoodSwipeIcon;
