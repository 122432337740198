import React from "react";
import InternalPageNav from "../../../components/Organisms/InternalPageNav";
import KitchenLayout from "../../../components/Layout/kitchenLayout";
import Select from "../../../components/Common/Select";
import CheckBox from "../../../components/Common/CheckBox";
import Head from "../../../components/header/head";

export default function Personalisation() {
    return (
        <>
            <Head
                pageTitle={"Foodswipe Kitchen | Personalisation"}
                metaDescription={"Foodswiipe Kitchen"}
            />
            <KitchenLayout pageIntro={"Settings"}>
                <div className="flex items-center justify-between">
                    <div className="w-[90%]">
                        <InternalPageNav
                            data={[
                                {
                                    link: "table-generation",
                                    title: "Table Generation",
                                },
                                {
                                    link: "menu-management",
                                    title: "Menu Management",
                                },
                                { link: "account", title: "Account" },
                                { link: "profile", title: "Profile" },
                                {
                                    link: "personalisation",
                                    title: "Personalisation",
                                },
                                { link: "support", title: "Support" },
                            ]}
                        />
                    </div>
                </div>

                <section className="mt-8 w-full flex flex-col mb-12">
                    <p className="text-2xl mb-4">Business Information</p>
                    <p className="text-base mb-4">POS System</p>
                    <div className="flex gap-6 mb-6">
                        <div className="rounded-2xl bg-[#FEF0E7] flex p-6 items-center justify-center w-[30%] lg:w-[234px]">
                            <p>QuickBook</p>
                        </div>
                        <div className="rounded-2xl bg-[#FEF0E7] flex p-6 items-center justify-center w-[30%] lg:w-[234px]">
                            <p>QuickBook</p>
                        </div>
                    </div>
                    <p className="text-base mb-4">POS System</p>
                    <div className="flex gap-6">
                        <div className="rounded-2xl bg-[#FEF0E7] flex p-6 items-center justify-center w-[30%] lg:w-[234px]">
                            <p>EMS</p>
                        </div>
                        <div className="rounded-2xl bg-[#FEF0E7] flex p-6 items-center justify-center w-[30%] lg:w-[234px]">
                            <p>Deliiver</p>
                        </div>
                        <div className="rounded-2xl bg-[#FEF0E7] flex p-6 items-center justify-center w-[30%] lg:w-[234px]">
                            <p>Konga Logistics</p>
                        </div>
                    </div>
                </section>
                <section className="mt-8 w-full flex flex-col mb-12">
                    <p className="text-2xl mb-4">Translation</p>
                    <div className="w-[45%]">
                        <Select label={"Language"}>
                            <option disabled selected>
                                English
                            </option>
                        </Select>
                    </div>
                </section>
                <section className="w-[80%] mb-12">
                    <p className="text-2xl mb-4">Notification</p>
                    <p className="font-light mb-4">
                        Feed Order and inventory from Vendiit
                    </p>
                    <div className="flex flex-col gap-[9px]">
                        <label className="flex justify-between">
                            <div>
                                <p className="text-xl leading-8 font-normal text-[#F2650D]">
                                    Order Alert
                                </p>
                                <p className="text-xs leading-6 font-light">
                                    You want to know when a customer places and
                                    order with your menu app.
                                </p>
                            </div>
                            <CheckBox />
                        </label>
                        <label className="flex justify-between">
                            <div>
                                <p className="text-xl font-normal text-[#F2650D]">
                                    System Update
                                </p>
                                <p className="text-xs font-light">
                                    You want to know when we ship any new update
                                    to FoodSwiipe.
                                </p>
                            </div>
                            <CheckBox />
                        </label>
                    </div>
                </section>
                <section className="w-[65%] mb-12">
                    <p className="text-2xl mb-4">Notification</p>
                    <div className="w-full mb-6 flex gap-6">
                        <div className="flex gap-4 w-1/2">
                            <CheckBox />
                            <p className="text-base font-normal text-[#000]">
                                Email
                            </p>
                        </div>
                        <div className="flex gap-4 w-1/2">
                            <CheckBox />
                            <p className="text-base font-normal text-[#000]">
                                SMS
                            </p>
                        </div>
                    </div>
                    <div className="w-full flex gap-6">
                        <div className="flex gap-4 w-1/2">
                            <CheckBox />
                            <p className="text-base font-normal text-[#000]">
                                In-App Notification
                            </p>
                        </div>
                        <div className="flex gap-4 w-1/2">
                            <CheckBox />
                            <p className="text-base font-normal text-[#000]">
                                Push Notification
                            </p>
                        </div>
                    </div>
                </section>
                <section className="w-[65%]">
                    <p className="text-2xl mb-4">API Access and Keys</p>
                    <div className="flex flex-col gap-6">
                        <div>
                            <p className="mb-2 font-light">
                                Feed Order and inventory from Vendiit
                            </p>
                            <div className="flex gap-4 items-center">
                                <p className="p-3 w-[80%] text-center font-light rounded-lg border border-[#0000001A]">
                                    a6373nhdhjbfjbjkdc84463hd
                                </p>
                                <p className="text-[#F2650D] cursor-pointer p-3 rounded-lg">
                                    Reset API
                                </p>
                            </div>
                        </div>
                        <div>
                            <p className="mb-2 font-light">
                                Synchronise kitchen inventory to Vendiit
                            </p>
                            <div className="flex gap-4 items-center">
                                <p className="p-3 w-[80%] text-center font-light rounded-lg border border-[#0000001A]">
                                    a6373nhdhjbfjbjkdc84463hd
                                </p>
                                <p className="text-[#F2650D] cursor-pointer  p-3 rounded-lg">
                                    Reset API
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </KitchenLayout>
        </>
    );
}
