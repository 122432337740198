import React, { useEffect, useRef } from "react";
import QRCode from "qrcode";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux-store/slices/user.slice";
import { QRCodeCanvas } from "qrcode.react";

function TagsContainer({ resturantName, tableNumber, id }) {
    const user = useSelector(selectUser);

    return (
        <div className="py-8 flex gap-4 flex-col bg-white rounded-3xl w-full items-center">
            <p className="text-[#F2650D] font-bold text-[34px] leading-[48px] capitalize">
                {resturantName}
            </p>

            {/* <img src="/images/QRCode.png" alt="qr-code" /> */}
            <QRCodeCanvas
                value={`https://menu.foodswiipe.com?businessId=${user.BusinessId}&tableId=${id}`}
            />

            <p className="text-[#F2650D] font-medium text-2xl leading-6">
                {tableNumber}
            </p>
            <p className="text-[#111] font-light text-sm">
                Scan code to place your order
            </p>
        </div>
    );
}

export default TagsContainer;

//
