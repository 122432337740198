import React from "react";
import KitchenLayout from "../../../components/Layout/kitchenLayout";
import Button from "../../../components/Common/Button";
import Head from "../../../components/header/head";
import DownloadTab from "../../../components/Organisms/DownloadTab";
import { Link } from "react-router-dom";

function TableDownload() {
    return (
        <>
            <Head
                pageTitle={"Foodswipe Kitchen | Table Download"}
                metaDescription={"Foodswiipe Kitchen"}
            />
            <KitchenLayout pageIntro={"Table Download"}>
                <div className="flex items-center justify-between">
                    <div className="flex bg-[#FFCDBC] rounded-2xl items-center justify-center w-14 h-14">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <path
                                d="M15 18L9 12L15 6"
                                stroke="#F2650D"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                    <div className="w-[20%]">
                        <Link
                            to={"/kitchen/settings/table-generation"}
                            className=" w-full"
                        >
                            <Button
                                btnType="primary"
                                value={"Generate Tables"}
                                // onClick={() => setModal((prevState) => !prevState)}
                            />
                        </Link>
                    </div>
                </div>

                <section className="mt-8 w-full flex justify-between items-center mb-12">
                    <div className="w-full flex gap-6">
                        <DownloadTab
                            tag={"Latest"}
                            date={"14 June, 2023"}
                            qty={"16 Tables"}
                            range={"FSW001 -FSW016"}
                        />
                    </div>
                </section>
            </KitchenLayout>
        </>
    );
}

export default TableDownload;
