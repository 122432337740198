import React, { useState } from "react";
import Button from "../../../Common/Button";
import { ErrorMessage, Form, Formik } from "formik";
import { ShowErrorMessage, ShowSuccessMessage } from "../../../../utils";
import Input from "../../../Common/Input";
import SelectCategory from "../../SelectCategory";
import { createFood } from "../../../../service/request";
import * as Yup from "yup";
import Select from "../../../Common/Select";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../redux-store/slices/user.slice";
import SpinnerLoader from "../../SpinnerLoader";

function AddMenu({ setModal, setRefresh, categories, setCategories }) {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [file, setFile] = useState();
    const [Image, setImage] = useState();
    const [Imageid, setImageid] = useState();
    const [upload, setUpload] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const user = useSelector(selectUser);

    const MenuManagementSchema = Yup.object().shape({
        name: Yup.string().required("Required"),
        price: Yup.number().required("Required"),
        portion: Yup.string().required("Required"),
        imageUrl: Yup.string().required("Required"),
        imageId: Yup.string().required("Required"),
        categories: Yup.array()
            .of(
                Yup.object().shape({
                    name: Yup.string(),
                    businessId: Yup.string(),
                })
            )
            .required(),
    });

    async function getFile(event, setFieldValue) {
        const file = event;
        try {
            setUpload(true);
            const formData = new FormData();
            formData.append("file", file);
            formData.append(
                "upload_preset",
                process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
            );
            formData.append(
                "api_key",
                process.env.REACT_APP_CLOUDINARY_API_KEY
            );
            formData.append(
                "api_secret",
                process.env.REACT_APP_CLOUDINARY_API_SECRET
            );
            const response = await fetch(
                `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/upload`,
                {
                    method: "POST",
                    body: formData,
                }
            );

            if (response.ok) {
                ShowSuccessMessage("Image Uploaded Successfully");
                const data = await response.json();
                setUpload(false);
                setFieldValue("imageUrl", data.secure_url);
                setFieldValue("imageId", data.asset_id);
                setImageid(data.asset_id);
                setImage(data.secure_url);
                setFile(data.secure_url);
            } else {
                ShowErrorMessage("Image Upload Failed");
                console.log(response);
                setUpload(false);
            }
        } catch (error) {
            ShowErrorMessage("Error While Uploading Image");
            console.log("Error:", error);
            setUpload(false);
        }
    }

    async function submitFood(values, resetForm) {
        try {
            setSubmitting(true);
            const optionselect = selectedOptions.map((item) => {
                return { name: item.label, businessId: user.BusinessId };
            });
            console.log(selectedOptions);
            const fooddata = {
                name: values.name,
                price: values.price,
                portion: values.portion,
                imageUrl: Image,
                imageId: Imageid,
                categories: optionselect,
            };
            await createFood(fooddata);
            ShowSuccessMessage("Food Created successfully");
            setSubmitting(false);
            setModal((prevState) => !prevState);
            setRefresh((prevState) => !prevState);
            resetForm();
            setSelectedOptions([]);
            setFile("");
        } catch (error) {
            console.log(error);
            setSubmitting(false);
            if (error?.response?.data?.message) {
                ShowErrorMessage(error?.response?.data?.message);
            } else {
                ShowErrorMessage("Error Occured Check Internet Connection");
            }
        }
    }
    return (
        <>
            <Formik
                initialValues={{
                    name: "",
                    price: "",
                    portion: "",
                    imageUrl: "",
                    image: "",
                    imageId: "",
                    categories: [],
                }}
                validationSchema={MenuManagementSchema}
                onSubmit={(values, { resetForm }) => {
                    submitFood(values, resetForm);
                }}
            >
                {({
                    errors,
                    touched,
                    values,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                }) => (
                    <Form
                        onSubmit={handleSubmit}
                        className="flex flex-col gap-4 mt-12"
                    >
                        <div>
                            <Input
                                label={"Food Name"}
                                placeholder={"Name your food"}
                                onChange={handleChange}
                                value={values.name}
                                name="name"
                            />
                            <div className="text-red-500">
                                <ErrorMessage name="name" />
                            </div>
                        </div>

                        <div className="flex gap-4">
                            <div>
                                <Input
                                    label={"Price (N)"}
                                    placeholder={"1,000"}
                                    onChange={handleChange}
                                    value={values.price}
                                    name="price"
                                />
                                <div className="text-red-500">
                                    <ErrorMessage name="price" />
                                </div>
                            </div>
                            <div className="w-[210px]">
                                <Select
                                    label={"Per"}
                                    placeholder={"Portion"}
                                    onChange={handleChange}
                                    value={values.portion}
                                    name="portion"
                                >
                                    <option>Select Serving</option>
                                    <option value={"portion"}>Portion</option>
                                    <option value={"Plate"}>Plate</option>
                                </Select>
                                <div className="text-red-500">
                                    <ErrorMessage name="portion" />
                                </div>
                            </div>
                        </div>

                        <div className="mt-2">
                            <p>Item Category</p>
                        </div>

                        <div className="mt-2 relative z-[9999]">
                            <SelectCategory
                                setOptions={setCategories}
                                options={categories}
                                selectedOptions={selectedOptions}
                                setSelectedOptions={setSelectedOptions}
                            />
                        </div>
                        <div
                            onChange={handleChange}
                            className="h-[256px] relative border  border-[#FFCDBC] bg-[#F5F5F5] rounded-2xl"
                        >
                            {file && (
                                <div className="relative">
                                    {upload && (
                                        <div className="absolute z-40 w-full h-[256px]  items-center justify-center flex">
                                            <SpinnerLoader />
                                        </div>
                                    )}

                                    <img
                                        className="absolute z-10 h-[256px] rounded-2xl w-full"
                                        src={file}
                                        alt="menu"
                                    />
                                </div>
                            )}

                            <input
                                className=" absolute z-20 h-full w-full rounded-2xl opacity-0 border-2 "
                                type={"file"}
                                value={values.image}
                                name="image"
                                onChange={(e) => {
                                    getFile(e.target.files[0], setFieldValue);
                                }}
                                accept="image/png, image/gif, image/jpeg"
                            />

                            <p className="absolute text-center w-full top-[100px] text-[#F2650D]">
                                {upload
                                    ? "Uploading Image..."
                                    : "Add Menu Image"}
                            </p>
                        </div>
                        <ErrorMessage name="imageUrl" />

                        <Button
                            btnType="primary"
                            type={"submit"}
                            value={!submitting ? "Upload Food" : "Uploading"}
                        />
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default AddMenu;
