import React from "react";
import { ActivePasswordCheckIco, InActivePasswordCheckIco } from "../../icons";

function PasswordChecker({ title, condition }) {
    return (
        <div className="p-3 flex gap-2 items-center bg-[#FFF4F0] rounded-3xl">
            {condition ? (
                <ActivePasswordCheckIco />
            ) : (
                <InActivePasswordCheckIco />
            )}
            <p className="font-light text-xs">{title}</p>
        </div>
    );
}

export default PasswordChecker;
