import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const getTokenFromStorage = () => {
    return localStorage.getItem("token");
};

export const businessApi = createApi({
    reducerPath: "businessApi",
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_SERVER_URL }),
    endpoints: (builder) => ({
        getBusinessProfile: builder.query({
            query: () => ({
                url: `/business/get-profile`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${getTokenFromStorage()}`,
                },
            }),
        }),
    }),
});

export const { useGetBusinessProfileQuery } = businessApi;
