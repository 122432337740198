import React from "react";
import { twJoin } from "tailwind-merge";

function Button({
    type,
    btnType,
    value,
    onClick,
    disabled,
    loading,
    className,
    ...props
}) {
    const classname = twJoin(
        `       hover:opacity-90  flex 
                justify-center text-center 
                items-center px-4 py-4 
                ${btnType === "primary" && "text-white bg-[#F2650D] "}
                ${btnType === "secondary" && "text-[#F2650D] bg-[#FFECE5] "}
                ${btnType === "ghost" && "text-[#F2650D] bg-[#FFF] "}
                rounded-2xl w-full focus:outline-none 
                focus:ring-2 focus:ring-[#F2650D] 
                focus:ring-opacity-75 cursor-pointer`,
        className
    );
    return (
        <>
            <button
                disabled={disabled}
                // disabled
                //     ? "bg-type-button-disabled hover:bg-type-button-disabled text-white flex justify-center  text-center items-center  my-3 py-3 px-5 rounded-lg w-full shadow-md focus:outline-none focus:ring-2 focus:ring-brand-tint-100 focus:ring-opacity-75 cursor-pointer"
                //     :
                className={classname}
                type={type}
                onClick={onClick}
                {...props}
            >
                <div>
                    {/* {loading && ''} */}
                    <p className="flex items-center justify-center w-full">
                        <span>{value}</span>
                    </p>
                </div>
            </button>
        </>
    );
}
export default Button;
