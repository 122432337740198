import React, { useState } from "react";
import { DownVector, LogOutIco, ProfileIco } from "../../icons";
import { useNavigate } from "react-router-dom";
import { ShowErrorMessage, ShowSuccessMessage } from "../../utils";

import { Link } from "react-router-dom";
import { updateUserProfile } from "../../service/request";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, updateUser } from "../../redux-store/slices/user.slice";

function NavBar({ pageIntro, name }) {
    const [openPopUp, setOpenPopUp] = useState(false);
    const [oldProfileAvatarUrl, setOldProfileAvatarUrl] = useState(null);

    const user = useSelector(selectUser);
    const popName = `${user.firstName} ${user.lastName}`;
    const dispatch = useDispatch();

    async function handleAvatarChange(e) {
        const selectedFile = e.target.files[0];

        if (selectedFile) {
            if (!selectedFile.type.startsWith("image/")) {
                ShowErrorMessage("File should be PNG or JPEG");
                return;
            }
            const fileMaxSize = 8 * 1024 * 1024;
            if (selectedFile.size > fileMaxSize) {
                ShowErrorMessage("File Should not exceed 8MB");
                return;
            }
            const fileUrl = URL.createObjectURL(selectedFile);
            setOldProfileAvatarUrl(user.profileAvatarUrl);
            dispatch(
                updateUser({
                    profileAvatarUrl: fileUrl,
                })
            );
            const formData = new FormData();
            formData.append("image", selectedFile);
            try {
                await updateUserProfile(formData);
                ShowSuccessMessage("Profile image uploaded successfully");
            } catch (error) {
                if (error.response) {
                    ShowErrorMessage(error.response.data.message);
                } else {
                    ShowErrorMessage("Network error prevented upload");
                }
                dispatch(
                    updateUser({
                        profileAvatarUrl: oldProfileAvatarUrl,
                    })
                );
            }
        } else {
            return;
        }
    }

    function handleOnSubmit(e) {
        e.preventDefault();
    }

    return (
        <div className=" flex justify-between items-center bg-[#F7F7F7] w-full">
            <p className=" flex text-[40px] font-normal">
                {pageIntro} &nbsp;
                <span className=" text-[40px] font-medium">{name}</span>
            </p>
            <div className="relative flex justify-center items-center gap-2">
                <form onSubmit={handleOnSubmit}>
                    <div className="w-10 h-10 rounded-full border-2 relative cursor-pointer border-[#F2650D]">
                        {user.profileAvatarUrl ? (
                            <img
                                className="rounded-full w-full object-cover h-full"
                                src={user.profileAvatarUrl}
                                alt="avatar"
                            />
                        ) : (
                            <img
                                src="/images/avatar2.png"
                                className="rounded-full w-full
                        object-cover h-full p-1"
                                alt="avatar"
                            />
                        )}
                        <label
                            className="absolute top-0 w-full p-4 cursor-pointer opacity-1"
                            htmlFor="fileUpload"
                        ></label>
                        <input
                            type="file"
                            id="fileUpload"
                            accept="image/*"
                            onChange={handleAvatarChange}
                            className="absolute hidden w-full top-1"
                        />
                    </div>
                </form>

                <div
                    role="button"
                    onClick={() => setOpenPopUp((prev) => !prev)}
                    className=" bg-[#FFE7DF] p-1 flex justify-center items-center rounded-lg h-8 w-8 cursor-pointer"
                >
                    <DownVector />
                </div>
                {openPopUp && (
                    <div className="absolute top-14 right-0">
                        <NavPopUp name={popName} email={user.email} />
                    </div>
                )}
            </div>
        </div>
    );
}

function NavPopUp({ name, email }) {
    const navigate = useNavigate();
    return (
        <div className="bg-white shadow-custom w-64 p-2 rounded-2xl flex flex-col">
            <div className="px-4 py-2">
                <p className="text-black text-sm font-normal">{name}</p>
                <p className="text-[#808080] text-sm font-light">{email}</p>
            </div>
            <Link to={"/kitchen/settings/profile"}>
                <div className="px-4 py-2 flex gap-2 items-center rounded-lg cursor-pointer hover:bg-slate-100 hover:scale-105">
                    <ProfileIco />
                    <p className="text-black text-base font-light leading-6 ">
                        Edit Profile
                    </p>
                </div>
            </Link>

            <div
                onClick={() => {
                    localStorage.removeItem("token");
                    navigate("/signin");
                }}
                className="px-4 py-2  flex gap-2 items-center rounded-lg cursor-pointer hover:bg-slate-100 hover:scale-105"
            >
                <LogOutIco />
                <p className="text-black text-base font-light leading-6">
                    Sign Out
                </p>
            </div>
        </div>
    );
}

export default NavBar;
