import React, { useState } from "react";
import FoodIcon from "../../icons/FoodIcon";
import Head from "../../components/header/head";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Input from "../../components/Common/Input";
import { signinUser } from "../../service/request";
import { useNavigate } from "react-router-dom";
import { ShowErrorMessage, ShowSuccessMessage } from "../../utils";
import { updateBusiness } from "../../redux-store/slices/business.slice";

function SignIn() {
    const [submitting, setSubmitting] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const SigninSchema = Yup.object().shape({
        password: Yup.string()
            .min(9, "must have mixture of characters!")
            .max(20, "Too Long!")
            .required("Required"),
        email: Yup.string().email("Invalid email").required("Required"),
    });

    async function handleSubmit(values) {
        try {
            setSubmitting(true);
            const data = await signinUser({
                email: values.email,
                password: values.password,
            });
            localStorage.setItem("token", data?.data?.token);
            dispatch(updateBusiness(data?.data?.user?.business));
            console.log(data);
            ShowSuccessMessage(data?.data?.message);
            navigate("/kitchen/dashboard");
            setSubmitting(false);
        } catch (error) {
            console.log(error);
            setSubmitting(false);
            if (error?.response?.data?.message) {
                ShowErrorMessage(error?.response?.data?.message);
            } else {
                ShowErrorMessage("Error Occured Check Internet Connection");
            }
        }
    }
    return (
        <>
            <Head
                pageTitle={"Foodswiipe Kitchen | SignIn"}
                metaDescription={"Foodswiipe Kitchen"}
            />
            <div className="lg:flex w-full">
                <div className="lg:w-1/2 w-full relative">
                    <div className="lg:pt-12 xl:px-32 pt-6 px-8 ">
                        <div className="flex justify-center lg:justify-start">
                            <FoodIcon />
                        </div>
                        <div claassName="flex flex-col">
                            <p className="lg:mt-[124px] mt-[103px] font-semibold lg:flex lg:justify-start text-center text-3xl text-[#F2650D]">
                                Get Back In
                            </p>
                            <Formik
                                initialValues={{
                                    password: "",
                                    email: "",
                                }}
                                validationSchema={SigninSchema}
                                onSubmit={handleSubmit}
                            >
                                {({
                                    errors,
                                    touched,
                                    values,
                                    handleChange,
                                    handleSubmit,
                                }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <div className="mt-6  w-full ">
                                            <Input
                                                label="Email"
                                                type="email"
                                                placeholder="Enter email"
                                                value={values.email}
                                                name="email"
                                                onChange={handleChange}
                                            />

                                            <div className="text-red-500">
                                                <ErrorMessage name="email" />
                                            </div>
                                        </div>
                                        <div className="mt-6 w-full ">
                                            <div className="w-full">
                                                <div className="flex justify-between">
                                                    <label
                                                        htmlFor="password"
                                                        className="font-normal text-base "
                                                    >
                                                        Password
                                                    </label>
                                                    <Link to={"/recovery"}>
                                                        <label
                                                            htmlFor="password"
                                                            className="font-normal text-base cursor-pointer text-[#F5853F]"
                                                        >
                                                            Forgot Password?
                                                        </label>
                                                    </Link>
                                                </div>

                                                <Input
                                                    type="password"
                                                    placeholder="Enter password"
                                                    value={values.password}
                                                    name="password"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="text-red-500">
                                                <ErrorMessage name="password" />
                                            </div>
                                        </div>
                                        <div className="mt-6 w-full">
                                            <button
                                                type="submit"
                                                className="w-full h-[56px] bg-[#F2650D] text-[#ffffff] rounded-2xl"
                                            >
                                                {!submitting
                                                    ? "Sign in"
                                                    : "Signing..."}
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                        <div className="mt-6 lg:flex lg:justify-center text-center">
                            <p>
                                Don’t have a account?&nbsp;
                                <Link to={"/signup"}>
                                    <span className="text-[#F2650D]">
                                        Sign Up
                                    </span>
                                </Link>
                            </p>
                        </div>
                    </div>

                    <div className="absolute bottom-0 bg-[#EBEBEB] h-[70px] w-full hidden lg:block"></div>
                </div>

                <div className="lg:flex lg:w-2/3 hidden">
                    <img
                        src="../../../images/kitchen.png"
                        className="w-full"
                        alt="kitchen"
                    />
                </div>
            </div>
        </>
    );
}

export default SignIn;
