import React, { useState } from "react";
import FoodIcon from "../../icons/FoodIcon";
import Head from "../../components/header/head";
import { Link } from "react-router-dom";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { signupUser } from "../../service/request";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ShowSuccessMessage } from "../../utils";
import { ShowErrorMessage } from "../../utils";
import Input from "../../components/Common/Input";
import Button from "../../components/Common/Button";
import { updateBusiness } from "../../redux-store/slices/business.slice";

function SignUp() {
    const [submitting, setSubmitting] = useState(false);
    const [formSwap, setFormSwap] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const SignupSchema = Yup.object().shape({
        firstname: Yup.string()
            .min(5, "Too Short!")
            .max(50, "Too Long!")
            .required("Required"),
        lastname: Yup.string()
            .min(5, "Too Short!")
            .max(50, "Too Long!")
            .required("Required"),
        businessname: Yup.string()
            .min(5, "Too Short!")
            .max(50, "Too Long!")
            .required("Required"),
        phone: Yup.number()
            .min(10, "Too Short!")
            // .max(12, "Too Long!")
            .required("Required"),
        bvn: Yup.number().required("Required"),
        email: Yup.string().email("Invalid email").required("Required"),
        password: Yup.string()
            .min(9, "must have mixture of characters!")
            .max(20, "Too Long!")
            .required("Required"),
        confirmPassword: Yup.string().oneOf(
            [Yup.ref("password"), null],
            "Passwords must match"
        ),
    });

    async function CreateUser(values) {
        try {
            setSubmitting(true);
            const data = await signupUser({
                firstName: values.firstname,
                lastName: values.lastname,
                businessName: values.businessname,
                bvn: values.bvn,
                phone: values.phone,
                email: values.email,
                password: values.password,
            });
            localStorage.setItem("token", data?.data?.token);
            dispatch(updateBusiness(data?.data?.user?.business));
            navigate("/kitchen/dashboard");
            ShowSuccessMessage(data?.data?.message);
            setSubmitting(false);
        } catch (error) {
            console.log(error);
            setSubmitting(false);
            if (
                error.response.data.message.includes(
                    "fails to match the 8-32characters, at least one uppercase and lowercase, one number and one special character pattern"
                )
            ) {
                ShowErrorMessage(
                    "Password should contain more than 8 character with 1 uppercase, lowercase and special character"
                );
            } else if (error?.response?.data?.message) {
                // toast.error(error.response.data.message);
                ShowErrorMessage(error?.response?.data?.message);
            } else {
                ShowErrorMessage("Error Occured Check Internet Connection");
            }
        }
    }

    function buttonFormswap() {
        setFormSwap(false);
    }

    return (
        <>
            <Head
                pageTitle={"Foodswiipe Kitchen | SignUp"}
                metaDescription={"Foodswiipe Kitchen"}
            />
            <div className="lg:flex w-full">
                <div className="lg:w-1/2 w-full relative">
                    <div className="lg:pt-12 xl:px-32 pt-6 px-8 ">
                        <div className="flex justify-center lg:justify-start">
                            <FoodIcon />
                        </div>
                        <div claassName="flex flex-col">
                            <p className="lg:mt-[124px]  mt-[103px] lg:flex lg:justify-start text-center font-semibold text-3xl text-[#F2650D]">
                                Get Started
                            </p>
                            <Formik
                                initialValues={{
                                    firstname: "",
                                    lastname: "",
                                    businessname: "",
                                    bvn: "",
                                    phone: "",
                                    email: "",
                                    password: "",
                                    confirmPassword: "",
                                }}
                                validationSchema={SignupSchema}
                                onSubmit={(values) => {
                                    // same shape as initial values
                                    CreateUser(values);
                                }}
                            >
                                {({
                                    errors,
                                    touched,
                                    values,
                                    handleChange,
                                    handleSubmit,
                                }) => (
                                    <Form>
                                        {formSwap ? (
                                            <>
                                                <div className="mt-6  w-full ">
                                                    <div className="w-full">
                                                        <Input
                                                            isPasswordShown={
                                                                true
                                                            }
                                                            label={"First Name"}
                                                            placeholder={
                                                                "Enter name"
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.firstname
                                                            }
                                                            name="firstname"
                                                        />

                                                        <div className="text-red-500">
                                                            <ErrorMessage name="firstname" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-6  w-full ">
                                                    <div className="w-full">
                                                        <Input
                                                            label="Last Name"
                                                            type="text"
                                                            placeholder="Enter name"
                                                            value={
                                                                values.lastname
                                                            }
                                                            name="lastname"
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                    </div>
                                                    <div className="text-red-500">
                                                        <ErrorMessage name="lastname" />
                                                    </div>
                                                </div>

                                                <div className="mt-6  w-full ">
                                                    <div className="w-full">
                                                        <Input
                                                            label="Business Name"
                                                            type="text"
                                                            placeholder="Enter business name"
                                                            value={
                                                                values.businessname
                                                            }
                                                            name="businessname"
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                    </div>
                                                    <div className="text-red-500">
                                                        <ErrorMessage name="businessname" />
                                                    </div>
                                                </div>

                                                <div className="mt-6  w-full ">
                                                    <div className="w-full">
                                                        <Input
                                                            label="Bank Verification Number"
                                                            type="text"
                                                            placeholder="Enter BVN"
                                                            value={values.bvn}
                                                            name="bvn"
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                    </div>
                                                    <div className="text-red-500">
                                                        <ErrorMessage name="bvn" />
                                                    </div>
                                                </div>

                                                <div
                                                    className="mt-6 w-full"
                                                    onClick={buttonFormswap}
                                                >
                                                    <div className="w-full h-[56px] bg-[#F2650D] text-[#ffffff] rounded-2xl ">
                                                        <Button
                                                            type={"button"}
                                                            btnType="primary"
                                                            value={"Continue"}
                                                        />
                                                    </div>

                                                    <div className="mt-4 lg:flex text-center">
                                                        <p>
                                                            Already have an
                                                            account? &nbsp;
                                                            <Link
                                                                to={"/signin"}
                                                            >
                                                                <span className="text-[#F2650D]">
                                                                    Sign In
                                                                </span>
                                                            </Link>
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="mt-6  w-full text-[#000]">
                                                    <div className="w-full">
                                                        <Input
                                                            isPasswordShown={
                                                                true
                                                            }
                                                            label={"Email"}
                                                            placeholder={
                                                                "Enter email"
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={values.email}
                                                            name="email"
                                                        />

                                                        <div className="text-red-500">
                                                            <ErrorMessage name="email" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-6  w-full ">
                                                    <div className="w-full">
                                                        <Input
                                                            label="Phone"
                                                            type="tel"
                                                            placeholder="Enter number"
                                                            value={values.phone}
                                                            name="phone"
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                    </div>
                                                    <div className="text-red-500">
                                                        <ErrorMessage name="phone" />
                                                    </div>
                                                </div>
                                                <div className="mt-6  w-full ">
                                                    <div className="w-full">
                                                        <Input
                                                            label="Password"
                                                            type="password"
                                                            placeholder="Enter password"
                                                            value={
                                                                values.password
                                                            }
                                                            name="password"
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                    </div>
                                                    <div className="text-red-500">
                                                        <ErrorMessage name="password" />
                                                    </div>
                                                </div>
                                                <div className="mt-6  w-full ">
                                                    <div className="w-full">
                                                        <Input
                                                            label="Confirm Password"
                                                            type="password"
                                                            placeholder="Enter password"
                                                            value={
                                                                values.confirmPassword
                                                            }
                                                            name="confirmPassword"
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                    </div>
                                                    <div className="text-red-500">
                                                        <ErrorMessage name="confirmPassword" />
                                                    </div>
                                                </div>

                                                <div className="mt-6 w-full flex gap-4">
                                                    <div className="h-[56px] w-full">
                                                        <Button
                                                            type={"button"}
                                                            btnType="secondary"
                                                            value={"Back "}
                                                            onClick={() =>
                                                                setFormSwap(
                                                                    (
                                                                        prevState
                                                                    ) =>
                                                                        !prevState
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className="h-[56px] w-full">
                                                        <Button
                                                            type={"submit"}
                                                            btnType="primary"
                                                            value={
                                                                !submitting
                                                                    ? "Sign Up"
                                                                    : "Signing Up..."
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mt-4 lg:flex text-center">
                                                    <p>
                                                        Already have an account?
                                                        &nbsp;
                                                        <Link to={"/signin"}>
                                                            <span className="text-[#F2650D]">
                                                                Sign In
                                                            </span>
                                                        </Link>
                                                    </p>
                                                </div>
                                            </>
                                        )}
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>

                    <div className="absolute bottom-0 bg-[#EBEBEB] h-[70px] w-full hidden lg:block"></div>
                </div>

                <div className="lg:flex lg:w-2/3 hidden">
                    <img
                        src="../../../images/kitfood.png"
                        className="w-full"
                        alt="kitchen"
                    />
                </div>
            </div>
        </>
    );
}

export default SignUp;
