import React from "react";

function Select({ children, name, label, requiredInput, ...props }) {
    return (
        <div className="w-full flex flex-col gap-1">
            <label className="text-[#000000]">
                {label}
                <span className="text-[#F2650D]">{requiredInput && "*"}</span>
            </label>
            <select {...props} className="w-full" name={name}>
                {children}
            </select>
        </div>
    );
}

export default Select;
