import React from "react";
import { Helmet } from "react-helmet";

export default function Head({ pageTitle, metaDescription, children }) {
  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" href="/favicon.ico" />
      {children}
    </Helmet>
  );
}
