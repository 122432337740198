import React, { useState } from "react";
import FoodIcon from "../../icons/FoodIcon";
import Head from "../../components/header/head";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { passwordReset } from "../../service/request";
import { ShowErrorMessage } from "../../utils";
import { ShowSuccessMessage } from "../../utils";
import Input from "../../components/Common/Input";

function ResetPassword() {
    const [loading, setLoading] = useState("");
    const navigate = useNavigate();

    let { id, token } = useParams();
    console.log(id, token);
    const SignupSchema = Yup.object().shape({
        password: Yup.string().required("Password is required"),
        passwordConfirmation: Yup.string().oneOf(
            [Yup.ref("password"), null],
            "Passwords must match"
        ),
    });
    async function CreateNewpassord(values) {
        try {
            setLoading(true);
            const data = await passwordReset(
                {
                    password: values.password,
                },
                id,
                token
            );
            setLoading(false);
            console.log(data);
            navigate("/signin");
            localStorage.setItem("token", data.data.token);
            ShowSuccessMessage(data.data.message);
        } catch (error) {
            console.log(error);
            setLoading(false);
            if (error.response.data.message) {
                toast.error(error.response.data.message);
            } else if (error?.response?.data?.message) {
                // toast.error(error.response.data.message);
                ShowErrorMessage(error?.response?.data?.message);
            } else {
                ShowErrorMessage("Error Occured Check Internet Connection");
            }
        }
    }
    return (
        <>
            <Head
                pageTitle={"Foodswiipe Kitchen | ResetPassword"}
                metaDescription={"Foodswiipe Kitchen"}
            />
            <div className="lg:flex w-full">
                <div className="lg:w-1/2 w-full relative">
                    <div className="lg:pt-12 xl:px-32 pt-6 px-8 ">
                        <div className="flex justify-center lg:justify-start">
                            <FoodIcon />
                        </div>
                        <div claassName="flex flex-col">
                            <p className="lg:mt-[124px] mt-[103px] lg:flex lg:justify-start text-center text-3xl text-[#F2650D]">
                                Forgot your Pass?
                            </p>
                            <Formik
                                initialValues={{
                                    password: "",
                                    passwordConfirmation: "",
                                }}
                                validationSchema={SignupSchema}
                                onSubmit={(values) => {
                                    // same shape as initial values
                                    CreateNewpassord(values);
                                }}
                            >
                                {({
                                    errors,
                                    touched,
                                    values,
                                    handleChange,
                                    handleSubmit,
                                }) => (
                                    <Form>
                                        <div className="mt-6  w-full ">
                                            <div className="w-full">

                                                <Input
                                                    label="New Password"
                                                    type="password"
                                                    placeholder="New Password"
                                                    value={values.password}
                                                    name="password"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="text-red-500">
                                                <ErrorMessage name="password" />
                                            </div>
                                        </div>
                                        <div className="mt-6  w-full ">
                                            <div className="w-full">

                                                <Input
                                                    label="Confirm Password"
                                                    type="password"
                                                    placeholder="Confirm password"
                                                    value={values.passwordConfirmation}
                                                    name="passwordConfirmation"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="text-red-500">
                                                <ErrorMessage name="password" />
                                            </div>
                                        </div>
                                        <div className="mt-6 w-full">
                                            <button
                                                type="submit"
                                                className="w-full h-[56px] bg-[#F2650D] text-[#ffffff] rounded-2xl "
                                            >
                                                {loading
                                                    ? "Reseting..."
                                                    : "Reset Password"}
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>

                    <div className="absolute bottom-0 bg-[#EBEBEB] h-[70px] w-full hidden lg:block"></div>
                </div>

                <div className="lg:flex lg:w-2/3 hidden">
                    <img
                        src="../../../images/kitfood.png"
                        className="w-full"
                        alt="kitchen"
                    />
                </div>
            </div>
        </>
    );
}

export default ResetPassword;
