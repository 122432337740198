import React from "react";
import { Link } from "react-router-dom";
function SidebarTab({ path, activeIcon, inactiveIcon, title, location }) {
    const splitedPath = path.split("/")[2];
    return (
        <Link
            to={path}
            className={
                location.includes(splitedPath)
                    ? " font-medium bg-[#FEBE9A]  flex items-center pl-5 cursor-pointer h-14 w-full gap-4 border-l-[#F7670D] border-l-4 border-l-solid rounded-l-2xl"
                    : "text-[#B3B3B3] font-light bg-[#ffffff] cursor-pointer flex pl-5 items-center h-14 w-full gap-4"
            }
        >
            {location.includes(splitedPath) ? (
                <img src={activeIcon} alt="page-icon" />
            ) : (
                <>{inactiveIcon}</>
            )}
            <p> {title}</p>
        </Link>
    );
}

export default SidebarTab;
