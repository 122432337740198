import React, { useState, useEffect } from "react";
import KitchenLayout from "../../../components/Layout/kitchenLayout";
import InternalPageNav from "../../../components/Organisms/InternalPageNav";
import Modal from "../../../components/Organisms/Modal";
import Head from "../../../components/header/head";
import Button from "../../../components/Common/Button";
import { CancelIcon, SpAdIco, SpMsgIco, SpPhoneIco } from "../../../icons";
import { Formik, Form, ErrorMessage } from "formik";
import { ShowErrorMessage, ShowSuccessMessage } from "../../../utils";
import * as Yup from "yup";
import SkeletonLoader from "../../../components/Organisms/SkeletonLoader";
import {
    createSupportTicket,
    getSupportTickets,
} from "../../../service/request";

function Support() {
    const [modal, setModal] = useState(false);
    const [popup, setPopup] = useState(false);
    const [loader, setLoader] = useState(true);
    const [popupData, setPopupData] = useState([]);
    const [support, setSupport] = useState([]);
    const [tickets, setTickets] = useState([]);
    const [submit, setSubmit] = useState(false);

    useEffect(() => {
        const ticketList = () => {
            getSupportTickets()
                .then((res) => {
                    setTickets(res.data.tickets);
                    setLoader(false);
                })
                .catch((err) => {
                    console.log(err);
                });
        };
        ticketList();
    }, []);

    const showPopup = () => {
        setPopup((prevState) => !prevState);
    };
    const SupportSchema = Yup.object({
        subject: Yup.string().required("Required"),
        description: Yup.string().required("Required"),
    });
    async function CreateTicket(values) {
        try {
            setSubmit(true);
            const data = await createSupportTicket({
                ticketSubject: values?.subject,
                ticketDescription: values?.description,
            });
            ShowSuccessMessage(data.data.message);
            setModal((prev) => !prev);
            // setSupport(data);
            setSubmit(false);
        } catch (error) {
            setSubmit(false);
            if (error?.response?.data?.message) {
                ShowErrorMessage(error?.response?.data?.message);
            } else {
                ShowErrorMessage("Error Occured Check Internet Connection");
            }
        }
    }
    const options = {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
    };
    return (
        <>
            <Head
                pageTitle={"Foodswipe Kitchen | Support"}
                metaDescription={"Foodswiipe Kitchen"}
            />
            <Modal
                width={"w-[512px]"}
                title={"Raise a Ticket"}
                show={modal}
                text={"text-black"}
                setShow={setModal}
            >
                <Formik
                    initialValues={{
                        subject: "",
                        description: "Write Here",
                    }}
                    validationSchema={SupportSchema}
                    onSubmit={(values) => {
                        CreateTicket(values);
                    }}
                >
                    {({
                        errors,
                        touched,
                        values,
                        handleChange,
                        handleSubmit,
                        handleBlur,
                    }) => (
                        <Form>
                            <div className=" mt-6">
                                <label className="text-[rgb(0,0,0)] mb-2">
                                    Subject
                                </label>
                                <input
                                    placeholder="Subject"
                                    name="subject"
                                    value={values.subject}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    className=" w-full border p-4 border-[#cccccc] bg-[#F5F5F5] rounded-xl"
                                />
                                <div className="text-red-500">
                                    <ErrorMessage name="subject" />
                                </div>
                            </div>

                            <div className=" mt-4 mb-4">
                                <label className="text-[#3c3535] mb-2">
                                    Description
                                </label>
                                <textarea
                                    name="description"
                                    defaultValue={"Write Here"}
                                    rows={4}
                                    cols={40}
                                    value={values.description}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    className="w-full opacity-50 px-4 py-4 border  border-[#cccccc] bg-[#ffffff] rounded-xl"
                                />
                                <div className="text-red-500">
                                    <ErrorMessage name="description" />
                                </div>
                            </div>
                            <Button
                                btnType="primary"
                                type={"submit"}
                                disabled={submit}
                                value={submit ? "Submitting" : "Submit"}
                            />
                        </Form>
                    )}
                </Formik>
            </Modal>
            {popupData?.map((pop) => {
                return (
                    <Popup
                        setShow={setPopup}
                        show={popup}
                        subject={pop.ticketSubject}
                        description={pop.ticketDescription}
                        date={new Date(pop.createdAt).toLocaleString(
                            "en-US",
                            options
                        )}
                        merchant={pop?.sequence}
                        status={
                            pop.status.charAt(0).toUpperCase() +
                            pop.status.slice(1)
                        }
                    />
                );
            })}
            {/* <Popup
                setShow={setPopup}
                show={popup}
                subject={
                    "Lorem ipsum dolor sit amet consectetur. Ultricies a elementum egestas."
                }
                description={
                    "Lorem ipsum dolor sit amet consectetur. Nibh amet turpis in neque feugiat tempor neque. Ut id elit massa tempor egestas. Velit dictum ullamcorper viverra et ornare et ullamcorper in. Turpis accumsan malesuada quisque nisi tristique et ut facilisi. Sollicitudin posuere aliquet nisl facilisi amet. "
                }
                date={"12 Sep 2023"}
                merchant={"MEG-NOK-29383"}
                status={"Pending"}
            /> */}
            <KitchenLayout pageIntro={"Settings"}>
                <>
                    <div className="flex items-center">
                        <div className="w-[90%]">
                            <InternalPageNav
                                data={[
                                    {
                                        link: "table-generation",
                                        title: "Table Generation",
                                    },
                                    {
                                        link: "menu-management",
                                        title: "Menu Management",
                                    },
                                    { link: "account", title: "Account" },
                                    { link: "profile", title: "Profile" },
                                    {
                                        link: "personalisation",
                                        title: "Personalisation",
                                    },
                                    { link: "support", title: "Support" },
                                ]}
                            />
                        </div>
                        <div className="w-[20%]">
                            <Button
                                btnType="primary"
                                value={"Complain"}
                                onClick={() =>
                                    setModal((prevState) => !prevState)
                                }
                            />
                        </div>
                    </div>
                    <section className="mt-8 w-full flex gap-6">
                        <div className=" w-[72.724%] flex flex-col gap-4">
                            {loader ? (
                                <SkeletonLoader width={664} height={144} />
                            ) : null}
                            {tickets?.length >= 0 &&
                                tickets?.map((ticket) => {
                                    return (
                                        <SupportCard
                                            key={ticket.id}
                                            subject={ticket.ticketSubject}
                                            description={ticket.ticketDescription
                                                .substring(0, 150)
                                                .concat("...")}
                                            date={new Date(
                                                ticket.createdAt
                                            ).toLocaleString("en-US", options)}
                                            merchant={ticket?.sequenceCode}
                                            status={
                                                ticket.status
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                ticket.status.slice(1)
                                            }
                                            click={() => {
                                                setPopupData([ticket]);
                                                showPopup();
                                            }}
                                        />
                                    );
                                })}
                        </div>

                        <div className="w-[25.974%] flex h-fit flex-col gap-4 text-[#F2650D] bg-[#FFECE5] p-4 rounded-2xl">
                            <div className=" flex w-full gap-4 ">
                                <SpPhoneIco />

                                <p>0803 456 7894</p>
                            </div>
                            <div className=" flex w-full gap-4 ">
                                <SpMsgIco />
                                <p>support@foodswiipe.com</p>
                            </div>
                            <div className=" flex w-full gap-4">
                                <SpAdIco />
                                <p className=" w-4/5">
                                    7, Bawala Street, Off Pedro Road, Shomolu,
                                    Lagos
                                </p>
                            </div>
                        </div>
                    </section>
                </>
            </KitchenLayout>
        </>
    );
}

export default Support;

// function EditProfile({ setEditProfile }) {
//     return (
//         <>
//             <section className="mt-8 flex justify-between items-center mb-12">
//                 <div className="w-[60%]">
//                     <p className="text-2xl mb-4">Personal Information</p>
//                     <div className="w-full flex flex-col gap-4">
//                         <div className="flex gap-6">
//                             <div className="w-1/2">
//                                 <Input
//                                     value={"John Doe"}
//                                     placeholder={"Fullname"}
//                                     label={"Full name"}
//                                 />
//                             </div>
//                             <div className="w-1/2">
//                                 <Input
//                                     value={"johndoefswp01"}
//                                     placeholder={"Enter Username"}
//                                     label={"Username"}
//                                 />
//                             </div>
//                         </div>
//                         <div className="flex gap-6">
//                             <div className="w-1/2">
//                                 <Input
//                                     value={"0812 345 6789"}
//                                     placeholder={"Enter Phone Number"}
//                                     label={"Phone Number"}
//                                 />
//                             </div>
//                             <div className="w-1/2">
//                                 <Input
//                                     value={"0812 345 6789"}
//                                     placeholder={"Enter Phone Number"}
//                                     label={"Phone Number"}
//                                 />
//                             </div>
//                         </div>

//                         <div className="flex gap-6">
//                             <div className="w-full">
//                                 <Input
//                                     value={
//                                         "147, Broad Street Avenue, Freedom Road, Victoria Garden City, Lagos"
//                                     }
//                                     placeholder={"Enter Address"}
//                                     label={"Address"}
//                                 />
//                             </div>
//                         </div>
//                         <div className="flex gap-6">
//                             <div className="w-1/2">
//                                 <Input
//                                     value={"+1 West African Time"}
//                                     placeholder={"Timezone"}
//                                     label={"Timezone"}
//                                 />
//                             </div>
//                             <div className="w-1/2">
//                                 <Input
//                                     value={"12 Hours"}
//                                     placeholder={"12 Hours"}
//                                     label={"Time Format"}
//                                 />
//                             </div>
//                         </div>
//                         <div className="flex">
//                             <div className="w-full">
//                                 <Input
//                                     value={"Nigeria"}
//                                     placeholder={"Enter Country"}
//                                     label={"Country"}
//                                 />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="w-[20%] rounded-3xl">
//                     <div>
//                         <img
//                             className="w-[172px] h-[196px]"
//                             src="/images/Photo.png"
//                             alt="user"
//                         />
//                     </div>
//                 </div>
//             </section>
//             <section className="mt-8 flex justify-between items-center mb-12">
//                 <div className="w-[60%]">
//                     <p className="text-2xl mb-4">Business Information</p>
//                     <div className="w-full flex flex-col gap-4">
//                         <div className="flex">
//                             <div className="w-1/2">
//                                 <InfoList
//                                     title={"Business Name"}
//                                     caption={"Nancy Kitchen"}
//                                 />
//                             </div>
//                             <div className="w-1/2">
//                                 <InfoList
//                                     title={"Menu URL"}
//                                     caption={
//                                         "https://foodswiipe.com/nancykitchen"
//                                     }
//                                 />
//                             </div>
//                         </div>
//                         <div className="flex">
//                             <div className="w-1/2">
//                                 <InfoList
//                                     title={"Business Phone"}
//                                     caption={"0812 345 6789"}
//                                 />
//                             </div>
//                             <div className="w-1/2">
//                                 <InfoList
//                                     title={"Business Email"}
//                                     caption={"nancykitchen@example.com"}
//                                 />
//                             </div>
//                         </div>

//                         <div className="flex">
//                             <div className="w-full">
//                                 <InfoList
//                                     title={"Business Address"}
//                                     caption={
//                                         "147, Broad Street Avenue, Freedom Road, Victoria Garden City, Lagos"
//                                     }
//                                 />
//                             </div>
//                         </div>
//                         <div className="flex">
//                             <div className="w-1/2">
//                                 <InfoList title={"City"} caption={"Etiosa"} />
//                             </div>
//                             <div className="w-1/2">
//                                 <InfoList
//                                     title={"State"}
//                                     caption={"Lagos State"}
//                                 />
//                             </div>
//                         </div>
//                         <div className="flex">
//                             <div className="w-1/2">
//                                 <InfoList
//                                     title={"Location"}
//                                     caption={"Nigeria"}
//                                 />
//                             </div>
//                             <div className="w-1/2">
//                                 <InfoList
//                                     title={"Currency"}
//                                     caption={"Naira"}
//                                 />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="w-[20%] rounded-3xl">
//                     <div>
//                         <img
//                             className="w-[172px] h-[172px]"
//                             src="/images/biz-Logo.png"
//                             alt="user"
//                         />
//                     </div>
//                 </div>
//             </section>
//             <div className="w-[83px] h-[30px] mt-6">
//                 <Button
//                     btnType="primary"
//                     value={"Update"}
//                     onClick={() => setEditProfile((prevState) => !prevState)}
//                 />
//             </div>
//         </>
//     );
// }
function SupportCard({
    subject,
    description,
    date,
    merchant,
    status,
    click,
    key,
}) {
    return (
        <div className=" w-full p-4 bg-[#FFF] rounded-lg h-[144px] " key={key}>
            <p>{subject}</p>
            <p className=" text-[#666] font-light text-sm w-full h-12 leading-6 mt-2">
                {description}
            </p>
            <div className=" flex w-full justify-between items-center mt-3">
                <div className=" flex gap-6 items-center">
                    <p className=" text-[#666] font-light text-xs">{date}</p>
                    <p className=" text-[#666] font-light text-xs">
                        {merchant}
                    </p>
                    <p
                        className={`py-1 px-2 rounded-xl text-xs ${
                            status === "Resolved"
                                ? "bg-[#C2FFD7] text-[#008C30]"
                                : "bg-[#FFF4E1] text-[#E69602]"
                        }`}
                    >
                        {status}
                    </p>
                </div>
                <p className=" text-[#F2650D] cursor-pointer" onClick={click}>
                    View More
                </p>
            </div>
        </div>
    );
}
function Popup({
    setShow,
    show,
    subject,
    description,
    date,
    merchant,
    status,
}) {
    if (!show) {
        return null;
    }
    return (
        <div className="z-40 fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full">
            <div className="relative flex items-center justify-center top-20 mx-auto w-full">
                <div
                    className={`p-8 bg-white rounded-[32px]
                        min-w-[630px] max-w-[632px] h-[590px]`}
                >
                    <div className="flex justify-between mb-4">
                        <p
                            className={`text-black font-Kanit font-medium text-2xl`}
                        >
                            {subject}
                        </p>
                        <div
                            onClick={() => setShow((prev) => !prev)}
                            role="button"
                            className="cursor-pointer"
                        >
                            <CancelIcon color={"#000"} />
                        </div>
                    </div>
                    <div className=" flex gap-6 items-center mb-4">
                        <p className=" text-[#666] font-light text-xs">
                            {date}
                        </p>
                        <p className=" text-[#666] font-light text-xs">
                            {merchant}
                        </p>
                        <p
                            className={`py-1 px-2 rounded-xl text-xs ${
                                status === "Resolved"
                                    ? "bg-[#C2FFD7] text-[#008C30]"
                                    : "bg-[#FFF4E1] text-[#E69602]"
                            }`}
                        >
                            {status}
                        </p>
                    </div>
                    <p className=" text-[#666] font-light text-sm leading-6 mt-2">
                        {description}
                    </p>
                </div>
            </div>
        </div>
    );
}
