import React, { useState } from "react";
import { ActiveCheckbox, InActiveCheckbox } from "../../icons";

function CheckBox({ name, setFeildValue }) {
    const [activeState, setActiveState] = useState(false);
    return (
        <div
            role="button"
            className="cursor-pointer"
            onClick={() => setActiveState((prevState) => !prevState)}
        >
            {activeState ? <ActiveCheckbox /> : <InActiveCheckbox />}
        </div>
    );
}

export default CheckBox;
